<template>
  <div class="dataCompared">
    <div class="title">关联分析</div>
    <div class="operate">
      <div class="oper">
        <div class="operateItem">
          <el-button @click="sidePointSelectVisible = true" style="position: relative; left: 80px;" type="primary" plain>测点</el-button>
          <el-button @click="pointSelectVisible = true" style="position: relative; left: 100px;" type="primary" plain>关联点</el-button>
        </div>
      </div>
      <div class="oper">
        <div class="operateItem">
          <span>监测因子:</span>
          <div>
            <el-select v-model="selectItemValue" placeholder="请选择">
              <el-option value="X轴" label="x轴"></el-option>
              <el-option value="Y轴" label="y轴"></el-option>
              <el-option value="Z轴" label="z轴"></el-option>
            </el-select>
          </div>
        </div>
        <div class="operateItem">
          <span>数值类型:</span>
          <div>
            <el-select v-model="valueType" placeholder="请选择">
              <el-option value="实时值" label="实时值"></el-option>
              <el-option value="累计值" label="累计值"></el-option>
            </el-select>
          </div>
        </div>
        <div class="operateItem">
          <span>起止时间:</span>
          <div>
            <el-date-picker v-model="timeItemValue" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="→" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
          </div>
        </div>
        <div class="operateItem">
          <el-button type="primary" @click="getAssociationData">查询</el-button>
        </div>
      </div>
    </div>
    <div class="chartWrapper">
      <div class="chartTitle" ref="title">水位/库水位趋势对比图</div>
      <div class="chartComp" ref="chart"></div>
    </div>
    <SidePointSelector :visible.sync="sidePointSelectVisible" @ok="handleSidePointSelect" @close="sidePointSelectVisible = false" />
    <AssociationPointSelector :visible.sync="pointSelectVisible" @ok="handlePointSelect" @close="pointSelectVisible = false" />
  </div>
</template>
<script>
/* eslint-disable */
// 测点
import SidePointSelector from '@/components/SidePointSelector'
// 关联点
import AssociationPointSelector from '@/components/AssociationPointSelector'
import echarts from 'echarts'
import dayjs from 'dayjs'
import { mapState } from 'vuex'
const TIME_FORMATTER = 'YYYY-MM-DD HH:mm:ss'
let option = {
  color: ['#3F51B5', '#FF9C2A', '#FF6363', '#FEC937', 'red', 'green'],
  // color: ['#3F51B5', '#FF6363'],
  animation: false,
  legend: {
    show: true
  },
  toolbox: {
    feature: {
      magicType: {
        show: true
      }
    }
  },
  tooltip: {
    trigger: 'item',
    // confine: true,
    backgroundColor: 'rgba(0,0,0,0.3)'
  },
  grid: {
    top: '30',
    bottom: '10',
    left: '10',
    right: '10',
    containLabel: true
  },
  xAxis: {
    type: 'time',
    boundaryGap: false,
    axisPointer: {
      show: true,
      lineStyle: {
        type: 'dotted'
      },
      label: {
        show: false
      }
    },
    axisLine: {
      onZero: false,
      lineStyle: {
        color: '#000000',
        opacity: 0.25
      },
      symbol: ['none', 'none']
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#000000',
        opacity: 0.25
      }
    },
    axisLabel: {
      color: 'rgba(0,0,0,0.65)',
      fontSize: '12',
      showMinLabel: false,
      showMaxLabel: false
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: {
      show: true,
      lineStyle: {
        type: 'dotted'
      },
      label: {
        show: false
      },
      triggerTooltip: false
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dotted',
        width: '1.5',
        color: '#BFBFBF',
        opacity: '0.2'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: 'rgba(0,0,0,0.45)',
      fontSize: '12',
      showMaxLabel: true
    },
    axisLine: {
      onZero: false,
      lineStyle: {
        color: '#000000',
        opacity: 0.25
      },
      symbol: ['none', 'none']
    }
  },
  series: [
    {
      name: '监测站点',
      type: 'line',
      smooth: false,
      showSymbol: true,
      lineStyle: {
        width: 2
      },
      z: 2,
      data: [
        ['9-1', '11'],
        ['9-2', '11'],
        ['9-3', '12'],
        ['9-4', '13'],
        ['9-5', '14']
      ]
    },
    {
      name: '关联站点B',
      type: 'line',
      smooth: false,
      showSymbol: true,
      lineStyle: {
        width: 2
      },
      z: 2,
      data: [
        ['9-6', '11'],
        ['9-7', '12'],
        ['9-8', '13'],
        ['9-9', '14']
      ]
    },
    {
      name: '关联站点C',
      type: 'line',
      smooth: false,
      showSymbol: true,
      lineStyle: {
        width: 2
      },
      z: 2,
      data: [
        ['9-6', '11'],
        ['9-7', '12'],
        ['9-8', '13'],
        ['9-9', '14']
      ]
    },
    {
      name: '告警值',
      type: 'line',
      smooth: false,
      showSymbol: true,
      lineStyle: {
        type: 'dashed',
        width: 2
      },
      z: 2,
      data: []
    },
    {
      name: '告警值B',
      type: 'line',
      smooth: false,
      showSymbol: true,
      lineStyle: {
        type: 'dashed',
        width: 2
      },
      z: 2,
      data: []
    },
    {
      name: '告警值C',
      type: 'line',
      smooth: false,
      showSymbol: true,
      lineStyle: {
        type: 'dashed',
        width: 2
      },
      z: 2,
      data: []
    }
  ]
}
export default {
  components: { AssociationPointSelector, SidePointSelector },
  data() {
    return {
      selectItemValue: 'X轴',
      timeItemValue: [
        dayjs()
          .subtract(24, 'hour')
          .format(TIME_FORMATTER),
        dayjs().format(TIME_FORMATTER)
      ],
      weekItemValue: '日',
      chartType: 'line',
      // 测点
      sidePointSelectVisible: false,
      // 关联点
      pointSelectVisible: false,
      deviceIds: '',
      selectedPoints: [],
      // 数值类型
      valueType: '实时值',
      // 告警值
      warningValue: 0
    }
  },
  methods: {
    getUnit(val) {
      let unit
      if (val == '坝体内部水平位移') {
        unit = 'mm'
      } else if (val == '降雨量') {
        unit = 'mm'
      } else if (val == '干滩高程') {
        unit = 'm'
      } else if (val == '坝体表面水平位移') {
        unit = 'mm'
      } else if (val == '浸润线埋深') {
        unit = 'mm'
      } else if (val == '库水位') {
        unit = 'm'
      } else if (val == '安全超高') {
        unit = 'm'
      } else if (val == '渗流量') {
        unit = 'mm'
      }
      return unit
    },
    initChart() {
      const chart = echarts.init(this.$refs.chart)
      // 假如没有则不显示提示
      option.series[0].name = this.selectedPoints[0] ? this.valueType + '1' + ' ' + this.selectedPoints[0]?.pointName + ' ' + this.selectedPoints[0]?.itemName + '(' + this.getUnit(this.selectedPoints[0]?.itemName) + ')' : ''
      option.series[1].name = this.selectedPoints[1] ? this.valueType + '2' + ' ' + this.selectedPoints[1]?.pointName + ' ' + this.selectedPoints[1]?.itemName + '(' + this.getUnit(this.selectedPoints[0]?.itemName) + ')' : ''
      option.series[2].name = this.selectedPoints[2] ? this.valueType + '3' + ' ' + this.selectedPoints[2]?.pointName + ' ' + this.selectedPoints[2]?.itemName + '(' + this.getUnit(this.selectedPoints[0]?.itemName) + ')' : ''
      // 后续名称要做修改，因为要通过监测指标判别
      option.series[3].name = this.selectedPoints[0] ? '告警值1 ' + this.selectedPoints[0]?.pointName + ' ' + this.selectedPoints[0]?.itemName + '(' + this.getUnit(this.selectedPoints[0]?.itemName) + ')' : ''
      option.series[4].name = this.selectedPoints[1] ? '告警值2 ' + this.selectedPoints[1]?.pointName + ' ' + this.selectedPoints[1]?.itemName + '(' + this.getUnit(this.selectedPoints[0]?.itemName) + ')' : ''
      option.series[5].name = this.selectedPoints[2] ? '告警值3 ' + this.selectedPoints[2]?.pointName + ' ' + this.selectedPoints[2]?.itemName + '(' + this.getUnit(this.selectedPoints[0]?.itemName) + ')' : ''
      chart.setOption(option)
    },
    changeChartType() {
      this.chartType === 'line' ? (this.chartType = 'bar') : (this.chartType = 'line')
    },
    handleSidePointSelect(points) {
      if (this.selectedPoints.length == 0) {
        this.selectedPoints = points
      } else {
        this.selectedPoints = this.selectedPoints.concat(points)
      }
      this.sidePointSelectVisible = false
    },
    handlePointSelect(points) {
      if (this.selectedPoints.length == 0) {
        this.selectedPoints = points
      } else {
        this.selectedPoints = this.selectedPoints.concat(points)
      }
      this.pointSelectVisible = false
    },
    async getAssociationData() {
      if (this.selectedPoints.length >= 2) {
        this.$refs.title.innerHTML = this.selectedPoints[0].itemName + '/' + this.selectedPoints[1].itemName + '趋势对比图'
      }
      // 第一个测点值
      option.series[0].data = []
      option.series[3].data = []

      // 第二个测点的值
      option.series[1].data = []
      option.series[4].data = []

      // 第三个测点的值
      option.series[2].data = []
      option.series[5].data = []
      if (!this.timeItemValue?.length || !this.timeItemValue) {
        return this.$message.warning('请选择时间')
      }
      // 需要根据我们的测点数组来获取我们的响应数组
      const timeDiff = dayjs(this.timeItemValue[1]).diff(this.timeItemValue[0], 'day')
      if (timeDiff > 2) {
        this.$message({
          message: '时间范围超过2天',
          type: 'warning'
        })
        return
      }
      if (!this.selectedPoints.length) {
        return this.$message.warning('请选择左边的测点值')
      }
      // 对选择的时间进行判断，超过一个月的弹出提示框

      // 查看当前测点值
      const res = await this.$http.get('/colData/getColData', {
        params: {
          beginTime: this.timeItemValue.length ? dayjs(this.timeItemValue[0]).format('YYYY-MM-DD HH:mm:ss') : undefined,
          endTime: this.timeItemValue.length ? dayjs(this.timeItemValue[1]).format('YYYY-MM-DD HH:mm:ss') : undefined,
          // deviceIds: '50535',
          deviceIds: this.selectedPoints[0]?.deviceId,
          // monitorItemName: this.selectItemValue,
          // 将监测指标名称改成左边测点选择中的监测指标
          monitorItemName: this.selectedPoints[0]?.itemName
        }
      })
      const res2 = await this.$http.get('/colData/getColData', {
        params: {
          beginTime: this.timeItemValue.length ? dayjs(this.timeItemValue[0]).format('YYYY-MM-DD HH:mm:ss') : undefined,
          endTime: this.timeItemValue.length ? dayjs(this.timeItemValue[1]).format('YYYY-MM-DD HH:mm:ss') : undefined,
          deviceIds: this.selectedPoints[1]?.deviceId,
          monitorItemName: this.selectedPoints[1]?.itemName
        }
      })

      const res3 = await this.$http.get('/colData/getColData', {
        params: {
          beginTime: this.timeItemValue.length ? dayjs(this.timeItemValue[0]).format('YYYY-MM-DD HH:mm:ss') : undefined,
          endTime: this.timeItemValue.length ? dayjs(this.timeItemValue[1]).format('YYYY-MM-DD HH:mm:ss') : undefined,
          deviceIds: this.selectedPoints[2]?.deviceId,
          monitorItemName: this.selectedPoints[2]?.itemName
        }
      })
      this.response = res
      this.response2 = res2
      this.response3 = res3
      if (this.response.data.data) {
        this.response.data.data[0].dataList.forEach((item, i) => {
          let array = []
          array.push(dayjs(item.time).format('YYYY-MM-DD HH:mm:ss'))
          // 加上一个判断， 如果警告值不存在，那么就是空
          if (!this.response.data.data[0].thresholdData.length) {
            array.push('')
          } else {
            array.push(this.response.data.data[0].thresholdData[0] && this.response.data.data[0].thresholdData[0].controlData)
          }
          // 渲染数据
          option.series[3].data.push(array)
        })
        // 拿到实时值和累积值
        this.response.data.data[0].dataList.forEach((item, i) => {
          let newArray = []
          newArray.push(dayjs(item.time).format('YYYY-MM-DD HH:mm:ss'))
          if (this.valueType == '实时值') {
            // newArray.push(item.values[0]);
            switch (this.selectItemValue) {
              case 'X轴':
                newArray.push(item.values[0] ? this.fixResult(item.values[0]) : '')
                break
              case 'Y轴':
                newArray.push(item.values[1] ? item.values[1] : '')
                break
              case 'Z轴':
                newArray.push(item.values[2] ? item.values[2] : '')
                break
            }
          } else if (this.valueType == '累计值') {
            // newArray.push(item.totalValues[0]);
            switch (this.selectItemValue) {
              case 'X轴':
                newArray.push(item.totalValues[0] ? this.fixResult(item.totalValues[0]) : '')
                break
              case 'Y轴':
                newArray.push(item.totalValues[1] ? item.totalValues[1] : '')
                break
              case 'Z轴':
                newArray.push(item.totalValues[2] ? item.totalValues[2] : '')
                break
            }
          } else if (this.valueType == '单次变化量') {
            switch (this.selectItemValue) {
              case 'X轴':
                newArray.push(item.singleChange[0] ? item.singleChange[0] : '')
                break
              case 'Y轴':
                newArray.push(item.singleChange[1] ? item.singleChange[1] : '')
                break
              case 'Z轴':
                newArray.push(item.singleChange[2] ? item.singleChange[2] : '')
                break
            }
          }
          option.series[0].data.push(newArray)
        })
      }
      if (this.response2.data.data) {
        this.response2.data.data[0].dataList.forEach((item, i) => {
          let array = []
          array.push(dayjs(item.time).format('YYYY-MM-DD HH:mm:ss'))
          if (!this.response2.data.data[0].thresholdData.length) {
            array.push('')
          } else {
            array.push(this.response2.data.data[0].thresholdData[0] && this.response2.data.data[0].thresholdData[0].controlData)
          }
          option.series[4].data.push(array)
        })
        // 拿到实时值和累积值
        this.response2.data.data[0].dataList.forEach((item, i) => {
          let newArray = []
          newArray.push(dayjs(item.time).format('YYYY-MM-DD HH:mm:ss'))
          if (this.valueType == '实时值') {
            // newArray.push(item.values[0]);
            switch (this.selectItemValue) {
              case 'X轴':
                newArray.push(item.values[0] ? this.fixResult(item.values[0]) : '')
                break
              case 'Y轴':
                newArray.push(item.values[1] ? item.values[1] : '')
                break
              case 'Z轴':
                newArray.push(item.values[2] ? item.values[2] : '')
                break
            }
          } else if (this.valueType == '累计值') {
            // newArray.push(item.totalValues[0]);
            switch (this.selectItemValue) {
              case 'X轴':
                newArray.push(item.totalValues[0] ? this.fixResult(item.totalValues[0]) : '')
                break
              case 'Y轴':
                newArray.push(item.totalValues[1] ? item.totalValues[1] : '')
                break
              case 'Z轴':
                newArray.push(item.totalValues[2] ? item.totalValues[2] : '')
                break
            }
          } else if (this.valueType == '单次变化量') {
            switch (this.selectItemValue) {
              case 'X轴':
                newArray.push(item.singleChange[0] ? item.singleChange[0] : '')
                break
              case 'Y轴':
                newArray.push(item.singleChange[1] ? item.singleChange[1] : '')
                break
              case 'Z轴':
                newArray.push(item.singleChange[2] ? item.singleChange[2] : '')
                break
            }
          }
          option.series[1].data.push(newArray)
        })
      }

      if (this.response3.data.data) {
        this.response3.data.data[0].dataList.forEach((item, i) => {
          let array = []
          array.push(dayjs(item.time).format('YYYY-MM-DD HH:mm:ss'))
          if (!this.response3.data.data[0].thresholdData.length) {
            array.push('')
          } else {
            array.push(this.response3.data.data[0].thresholdData[0] && this.response3.data.data[0].thresholdData[0].controlData)
          }
          option.series[5].data.push(array)
        })
        // 拿到实时值和累积值
        this.response3.data.data[0].dataList.forEach((item, i) => {
          let newArray = []
          newArray.push(dayjs(item.time).format('YYYY-MM-DD HH:mm:ss'))
          if (this.valueType == '实时值') {
            // newArray.push(item.values[0]);
            switch (this.selectItemValue) {
              case 'X轴':
                newArray.push(item.values[0] ? this.fixResult(item.values[0]) : '')
                break
              case 'Y轴':
                newArray.push(item.values[1] ? item.values[1] : '')
                break
              case 'Z轴':
                newArray.push(item.values[2] ? item.values[2] : '')
                break
            }
          } else if (this.valueType == '累计值') {
            // newArray.push(item.totalValues[0]);
            switch (this.selectItemValue) {
              case 'X轴':
                newArray.push(item.totalValues[0] ? this.fixResult(item.totalValues[0]) : '')
                break
              case 'Y轴':
                newArray.push(item.totalValues[1] ? item.totalValues[1] : '')
                break
              case 'Z轴':
                newArray.push(item.totalValues[2] ? item.totalValues[2] : '')
                break
            }
          } else if (this.valueType == '单次变化量') {
            switch (this.selectItemValue) {
              case 'X轴':
                newArray.push(item.singleChange[0] ? item.singleChange[0] : '')
                break
              case 'Y轴':
                newArray.push(item.singleChange[1] ? item.singleChange[1] : '')
                break
              case 'Z轴':
                newArray.push(item.singleChange[2] ? item.singleChange[2] : '')
                break
            }
          }
          option.series[2].data.push(newArray)
        })
      }

      // 渲染echarts表格  还需要判断数值类型
      this.initChart()
      this.selectedPoints = []
    },
    fixResult(params) {
      var rainFactor = 1
      const itor = this.unit.filter(v => '降雨量' == v.itemName)
      if (itor && itor[0] && itor[0].itemFactor) {
        rainFactor = this.unit.filter(v => '降雨量' == v.itemName)[0].itemFactor
      }
      debugger
      let len = 0
      if (rainFactor.toString().split('.')[1]) {
        len += rainFactor.toString().split('.')[1].length
      }
      if (params && Number(params)) {
        if (params.toString().split('.')[1]) {
          len += params.toString().split('.')[1].length
        }
        return (Number(rainFactor.toString().replace('.', '')) * Number(params.toString().replace('.', ''))) / Math.pow(10, len)
      }
    }
  },
  mounted() {
    // this.initChart()
  },
  computed: {
    ...mapState({
      monitorType: state => state.monitorType,
      unit: state => state.unit
    })
  },
  watch: {
    monitorType: function(newValue, oldValue) {
      //  .log(newValue)
    },
    weekItemValue(val) {
      switch (val) {
        case '日':
          this.timeItemValue = [
            dayjs()
              .subtract(24, 'hour')
              .format(TIME_FORMATTER),
            dayjs().format(TIME_FORMATTER)
          ]
          break
        case '周':
          this.timeItemValue = [
            dayjs()
              .subtract(7, 'day')
              .format(TIME_FORMATTER),
            dayjs().format(TIME_FORMATTER)
          ]
          break
        case '月':
          this.timeItemValue = [
            dayjs()
              .subtract(30, 'day')
              .format(TIME_FORMATTER),
            dayjs().format(TIME_FORMATTER)
          ]
      }
    }
  }
}
</script>
<style lang="less">
.dataCompared {
  width: 100%;
  height: 100%;
  .title {
    display: block;
    position: relative;
    padding: 0.24rem 0px 20px 24px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    &::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 0.24rem;
      width: 4px;
      height: 24px;
      background: #3f51b5;
      border-radius: 4px;
    }
  }
}
.operate {
  // display: -webkit-flex;
  // display: flex;
  // flex-flow: column;
  // align-items: flex-start;
  // display: flex;
  // justify-content: flex-start;
  // align-items: center;
  .oper {
    width: 100%;
    margin: 0.2rem 0;
    display: -webkit-flex;
    display: flex;
    flex-flow: row;
    align-items: flex-start;
  }
  .operateItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 24px;
    span {
      margin-right: 8px;
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #666;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #666;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #666;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #666;
    }
  }
}
.weekRadio {
  margin: 24px 0px 0px 24px;
}
.chartWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .chartTitle {
    height: 25px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #333333;
  }
  .chartComp {
    margin-top: 16px;
    width: 80%;
    height: calc(100vh - 200px - 2rem);
    // height: 380px;
    // border: 1px solid #eee;
  }
  .chartType {
    position: absolute;
    top: 20px;
    right: 10%;
    padding-left: 32px;
    background-size: 24px;
    background-position: center left;
    background-repeat: no-repeat;
    cursor: pointer;
    span {
      height: 22px;
      line-height: 22px;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
    }
  }
}
.el-button--primary {
  background-color: #3f51b5;
  border-color: #3f51b5;
  width: 116px;
  opacity: 1;
  border-radius: 2px;
}
.el-button--default {
  width: 116px;
  opacity: 1;
  border-radius: 2px;
}
</style>
