<template>
  <el-dialog width="710px" v-bind="$attrs" v-on="$listeners">
    <el-form inline>
      <el-form-item label="监测指标">
        <el-select v-model="selectedType">
          <el-option v-for="item in monitorType" :label="item.includes('干滩高程') ? '干滩长度' : item" :value="item"
            :key="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="设备名称">
        <el-input v-model="keyword"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </el-form-item>
    </el-form>
    <div style="color: #FF4343;">* 每次最多选择3个监测设备</div>
    <el-table :data="filteredData" max-height="400px">
      <el-table-column label="序号">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="监测点名称" prop="pointName" />
      <el-table-column width="150px" label="监测指标111" prop="itemName">
        <template slot-scope="scope">
          <div :class="scope.row.itemName">
            <!-- <span></span> -->
            <span>{{ scope.row.itemName.includes('干滩高程') ? '干滩长度' : scope.row.itemName }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="200px" label="设备名称" prop="deviceId" />
      <el-table-column label="选择">
        <template slot-scope="scope">
          <el-checkbox :value="selectedRows.includes(scope.row)" @change="() => selectedChange(scope.row)">
            <!-- {{scope.row}} -->
          </el-checkbox>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleOk">确 定</el-button>
      <el-button @click="handleClose">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
/* eslint-disable */
  import {
    mapGetters
  } from 'vuex'
  import {
    getMonitorPoints
  } from '@/api'

  export default {
    name: 'PointSelector',
    props: {
      // 测点过滤函数
      pointFilter: Function
    },
    computed: {
      ...mapGetters(['projectId', 'monitorType']),
      filteredData() {
        if (this.pointFilter) {
          return this.visibleData.filter(this.pointFilter)
        }
        return this.visibleData
      }
    },
    watch: {
      projectId() {
        this.loadData()
      }
    },
    mounted() {
      this.loadData()
    },
    data() {
      return {
        data: [],
        visibleData: [],
        selectedType: '',
        keyword: '',
        selectedRows: [],
        selectedType: '坝体表面水平位移'
      }
    },
    methods: {
      async loadData() {
        if (!this.projectId) return
        getMonitorPoints(this.projectId)
          .then(data => {
            this.data = data
            this.visibleData = this.data
            this.visibleData = this.data.filter(item => {
              let isSelectedType = true
              let containKeyword = true
              if (this.selectedType) {
                isSelectedType = item.itemName === this.selectedType
              }
              if (this.keyword) {
                containKeyword = item.pointerName.includes(this.keyword)
              }
              if (item.itemName.includes('干滩长度')) {
                item.itemName = '干滩长度'
              }
              return isSelectedType && containKeyword
            })
          })
          .catch(e => {
            console.log(e)
          })
      },
      handleSearch() {
        // this.loadData()
        this.visibleData = this.data.filter(item => {
          let isSelectedType = true
          let containKeyword = true
          let seleType = this.selectedType
          if (seleType.includes('干滩长度')) {
            seleType = '干滩长度'
          }
          if (this.selectedType) {
            isSelectedType = item.itemName === seleType
          }
          if (this.keyword) {
            containKeyword = item.pointerName.includes(this.keyword)
          }
          return isSelectedType && containKeyword
        })
      },
      handleOk() {
        if (!this.selectedRows.length) {
          this.$message.warning('请选择监测设备')
          return
        }
        let arr = []
        // 遍历选择的测点数组
        this.selectedRows.forEach(function (item) {
          // 如果存在不同的监测指标就不能进行对比
          arr.push(item.itemName)
        })
        let newArr = []
        newArr = Array.from(new Set(arr))
        if (newArr.length !== arr.length) {
          return this.$message.warning('请不要选择相同的监测指标分析')
        }
        // log(this.selectedRows, '测点');
        this.$emit('ok', this.selectedRows)
        this.selectedRows = []
      },
      handleClose() {
        // this.selectedRows = []
        this.$emit('close')
      },
      selectedChange(row) {
        if (this.selectedRows.includes(row)) {
          this.selectedRows = this.selectedRows.filter(item => item !== row)
        } else {
          if (this.selectedRows.length >= 3) {
            // 做最大选中校验
            this.$message.warning('每次最多选中3个监测设备')
            return
          }
          this.selectedRows = [...this.selectedRows, row]
        }
      }
    }
  }

</script>

<style scoped lang="less">
  .dialog-footer {
    text-align: center;
  }

</style>
