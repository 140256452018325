const weatherIconMap = {
  晴: require('./icons/qing.svg'),
  少云: require('./icons/qing.svg'),
  晴间多云: require('./icons/qing.svg'),
  多云: require('./icons/duoyun.svg'),
  阴: require('./icons/yin.svg'),
  有风: require('./icons/yin.svg'),
  平静: require('./icons/yin.svg'),
  微风: require('./icons/yin.svg'),
  和风: require('./icons/yin.svg'),
  清风: require('./icons/yin.svg'),
  '强风/劲风': require('./icons/yin.svg'),
  疾风: require('./icons/yin.svg'),
  大风: require('./icons/yin.svg'),
  烈风: require('./icons/yin.svg'),
  风暴: require('./icons/yin.svg'),
  狂爆风: require('./icons/yin.svg'),
  飓风: require('./icons/yin.svg'),
  热带风暴: require('./icons/yin.svg'),
  霾: require('./icons/mai.svg'),
  中度霾: require('./icons/mai.svg'),
  重度霾: require('./icons/mai.svg'),
  严重霾: require('./icons/mai.svg'),
  阵雨: require('./icons/zhenyu.svg'),
  雷阵雨: require('./icons/leizhenyu.svg'),
  雷阵雨并伴有冰雹: require('./icons/leizhenyubanyoubingbao.svg'),
  小雨: require('./icons/xiaoyu.svg'),
  中雨: require('./icons/zhongyu.svg'),
  大雨: require('./icons/dayu.svg'),
  暴雨: require('./icons/baoyu.svg'),
  大暴雨: require('./icons/dabaoyu.svg'),
  特大暴雨: require('./icons/tedabaoyu.svg'),
  强阵雨: require('./icons/zhenyu.svg'),
  强雷阵雨: require('./icons/leizhenyu.svg'),
  极端降雨: require('./icons/dayu.svg'),
  '毛毛雨/细雨': require('./icons/xiaoyu.svg'),
  雨: require('./icons/xiaoyu.svg'),
  '小雨-中雨': require('./icons/xiaoyu.svg'),
  '中雨-大雨': require('./icons/zhongyu.svg'),
  '大雨-暴雨': require('./icons/dayu.svg'),
  '暴雨-大暴雨': require('./icons/baoyu.svg'),
  '大暴雨-特大暴雨': require('./icons/dabaoyu.svg'),
  雨雪天气: require('./icons/yujiaxue.svg'),
  雨夹雪: require('./icons/yujiaxue.svg'),
  阵雨夹雪: require('./icons/yujiaxue.svg'),
  冻雨: require('./icons/dongyu.svg'),
  雪: require('./icons/xiaoxue.svg'),
  阵雪: require('./icons/zhenxue.svg'),
  小雪: require('./icons/xiaoxue.svg'),
  中雪: require('./icons/zhongxue.svg'),
  大雪: require('./icons/daxue.svg'),
  暴雪: require('./icons/baoxue.svg'),
  '小雪-中雪': require('./icons/xiaoxue.svg'),
  '中雪-大雪': require('./icons/zhongxue.svg'),
  '大雪-暴雪': require('./icons/daxue.svg'),
  浮尘: require('./icons/fuchen.svg'),
  扬沙: require('./icons/yangsha.svg'),
  沙尘暴: require('./icons/shachenbao.svg'),
  强沙尘暴: require('./icons/qiangshachenbao.svg'),
  龙卷风: require('./icons/qiangshachenbao.svg'),
  雾: require('./icons/wu.svg'),
  浓雾: require('./icons/wu.svg'),
  强浓雾: require('./icons/wu.svg'),
  轻雾: require('./icons/wu.svg'),
  大雾: require('./icons/wu.svg'),
  特强浓雾: require('./icons/wu.svg'),
  热: require('./icons/qing.svg'),
  冷: require('./icons/yin.svg'),
  未知: require('./icons/yin.svg')
}

export default weatherIconMap
