/*
 * @Author: your name
 * @Date: 2021-03-04 09:23:38
 * @LastEditTime: 2022-11-14 17:05:25
 * @LastEditors: zhyou zhouyou2041@zdjtcm.com
 * @Description: In User Settings Edit
 * @FilePath: \tailings_pond_client_devd:\Codes\zhongyun\xiling_pond_fe_master\xiling-pond-fe\src\api\index.js
 */
import request from './request'

/**
 * 获取站点测点信息
 * @param siteId 站点ID 或者项目ID
 */
export async function getMonitorPoints(siteId, monitorItemName) {
  return request.get(`/monitorPoint/${siteId}`, { params: { monitorItemName } }).then(({ data }) => data)
}
/**
 * 获取测项下的测点
 * @param siteNo 站点ID
 */
export async function getPointList(siteNo, type) {
  return request.get(`/monitorPoint/type/point/${siteNo}`, { params: { type } }).then(({ data }) => data)
}

/**
 * 获取监测点数据
 * @params reqList
 */
export async function getNewMonitorData(reqList) {
  return request.post('/colData/getColDataOpt', { reqList }).then(({ data }) => data)
}

/**
 * 获取监测点数据
 * @params {beginTime,endTime,deviceIds,monitorItemName}
 */
export async function getMonitorData(params) {
  return request.get('/colData/getColData', { params }).then(({ data }) => data)
}

// 分组管理

/**
 * 获取全部测点分组
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getAllPointGroup() {
  return request.get('/point/group/query').then(({ data }) => data)
}

/**
 * 创建测点分组
 * @param data {groupName,imagePath,itemId,itemName,monitorPointIds}
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function createNewPointGroup(data) {
  return request.post('/point/group/new', data)
}

/**
 * 删除测点分组
 * @param pointGroupId 分组ID
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function deletePointGroup(pointGroupId) {
  return request.delete('/point/group/delete', { params: { pointGroupId } })
}

/**
 * 登陆
 * @param params 登陆参数
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function login(params) {
  return request.post('/UserController/login', null, { params }).then(({ data }) => data)
}
/**
 * 登陆
 * @param params 保存告警处理信息
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function modifyAlarmInfo(params) {
  return request.put('/AlarmController/process', params)
}
/**
 * 获取影响因子
 */
 export async function getChgData(params) {
  return request.get('/colData/getChangeData', { params })
}
