<template>
  <div>
    <!-- 栏目内容 -->
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="监测指标:" class="monitorIndex">
        <el-select v-model="formInline.selectValue">
          <el-option :key="item" :label="item" :value="item" v-for="item in monitorType"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="监测点:" class="monitorPoint">
        <el-select v-model="formInline.monitorPointer" placeholder="请选择">
          <el-option :key="item.deviceId" :value="item.deviceId" :label="`${item.pointName}`" v-for="item in monitorPointData" />
        </el-select>
      </el-form-item>
      <el-form-item label="数据类型:">
        <el-select v-model="formInline.selectItemValue" placeholder="请选择">
          <el-option value="values" label="实时值" />
          <el-option value="totalValues" label="累计值" />
          <!-- <el-option value="singleChange" label="单次变化量" /> -->
        </el-select>
      </el-form-item>
      <el-form-item label="起止时间:">
        <el-date-picker v-model="formInline.timeItemValue" type="datetimerange" :clearable="false" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
      </el-form-item>
    </el-form>
    <div class="chartWrapper">
      <div class="chartTitle">{{ titleName }}趋势图</div>
      <div
        class="chartType"
        :style="{
          backgroundImage: this.chartType === 'line' ? `url(${require('./assets/bar.png')})` : `url(${require('./assets/line.png')})`
        }"
        @click="changeChartType"
      >
        <span>{{ this.chartType === 'line' ? '柱状图' : '折线图' }}</span>
      </div>
      <div class="chart" ref="chart"></div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
const faker = ['1.61','1.6','1.61','1.6','1.61','1.62','1.61','1.60','1.61','1.62']
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import echarts from 'echarts'
import { getMonitorData, getMonitorPoints } from '@/api'
const option = {
  color: ['#3F51B5', '#FF9C2A', '#FF6363'],
  animation: false,
  legend: {
    show: true
  },
  toolbox: {
    feature: {
      magicType: {
        show: true
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    confine: true,
    backgroundColor: 'rgba(0,0,0,0.3)'
  },
  grid: {
    top: '30',
    bottom: '10',
    left: '10',
    right: '10',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    axisPointer: {
      show: true,
      lineStyle: {
        type: 'dotted'
      },
      label: {
        show: false
      }
    },
    axisLine: {
      onZero: false,
      lineStyle: {
        color: '#000000',
        opacity: 0.25
      },
      symbol: ['none', 'none']
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#000000',
        opacity: 0.25
      }
    },
    axisLabel: {
      color: 'rgba(0,0,0,0.65)',
      fontSize: '12',
      showMinLabel: false,
      showMaxLabel: false
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: {
      show: true,
      lineStyle: {
        type: 'dotted'
      },
      label: {
        show: false
      },
      triggerTooltip: false
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dotted',
        width: '1.5',
        color: '#BFBFBF',
        opacity: '0.2'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: 'rgba(0,0,0,0.45)',
      fontSize: '12',
      showMaxLabel: true
    },
    axisLine: {
      onZero: false,
      lineStyle: {
        color: '#000000',
        opacity: 0.25
      },
      symbol: ['none', 'none']
    }
  },
  series: []
}
export default {
  props: ['params', 'activeName'],
  data() {
    return {
      titleName:'',
      rainFactor: 1,
      formInline: {
        selectValue: undefined,
        monitorPointer: undefined,
        selectItemValue: 'values',
        timeItemValue: [
          dayjs()
            .subtract(24, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          dayjs().format('YYYY-MM-DD HH:mm:ss')
        ]
      },
      weekItemValue: '日',
      chartType: 'line',
      chart: null,
      monitorPointData: [],
      data: []
    }
  },
  methods: {
    changeChartType() {
      this.chartType === 'line' ? (this.chartType = 'bar') : (this.chartType = 'line')
    },
    initChart() {
      this.titleName = this.formInline.selectValue
      var chart = echarts.init(this.$refs.chart)
      chart.setOption(option)
      const canvasWidth = document.getElementsByClassName("content")[0].offsetWidth - 100;
      chart.resize({width:canvasWidth});
      this.chart = chart
    },
    async loadData() {
      getMonitorPoints(this.projectId, this.formInline.selectValue)
        .then(data => {
          this.monitorPointData = data
          // 设置初始值
          this.formInline.monitorPointer = data[0]?.deviceId
        })
        .catch(e => {
          console.log(e)
        })
    },
    async search() {
      try {
        const timeDiff = dayjs(this.formInline.timeItemValue[1]).diff(this.formInline.timeItemValue[0], 'day')
        if (timeDiff > 30 && this.activeName === 'second') {
          this.$message({
            message: '时间范围超过30天',
            type: 'warning'
          })
          return
        }
        const params = {
          deviceIds: this.formInline.monitorPointer,
          monitorItemName: this.formInline.selectValue,
          beginTime: this.formInline.timeItemValue[0],
          endTime: this.formInline.timeItemValue[1]
        }
        this.data = await getMonitorData(params)
        this.updateChart()
      } catch (e) {
        // .log(e)
      }
    },
    updateChart() {
      this.titleName = this.formInline.selectValue
      if (!this.chart) this.initChart()
      const series = []
      this.data.forEach(({ dataList, thresholdData, pointName }, index) => {
        if (dataList.length === 0) {
          this.chart.setOption(
            {
              ...option,
              series: []
            },
            true
          )
          return
        }
        const seriesData = []
        const threshold = []
        dataList.forEach(data => {
          const key = dayjs(data.time).format('MM-DD HH:mm')
          seriesData.push([key, data[this.formInline.selectItemValue] ? data[this.formInline.selectItemValue] : []])
          if (thresholdData[index]) {
            threshold.push([key, thresholdData[index].controlData])
          }
        })
        var rainFactor = 1
        const itor = this.unit.filter(v => '降雨量' == v.itemName)
        if (itor && itor[0] && itor[0].itemFactor) {
          rainFactor = this.unit.filter(v => '降雨量' == v.itemName)[0].itemFactor
        }
        seriesData[0][1].forEach((item, index) => {
          series.push({
            name: ['x', 'y', 'z'][index] + '方向位移',
            type: this.chartType,
            lineStyle: { width: 2 },
            // data: seriesData.map((v) => [v[0], v[1][index]]),
            data: seriesData.map(v => {
              // let numValue = Number(v[1][index].toString().replace('.', ''))
              let numValue = Number(v[1][index].toString())
              if('Y1' == pointName){
                let len = 0
                if (rainFactor.toString().split('.')[1]) {
                  len += rainFactor.toString().split('.')[1].length
                }
                if (v[1][index].toString().split('.')[1]) {
                  len += v[1][index].toString().split('.')[1].length
                }
                numValue = (Number(rainFactor.toString().replace('.', '')) * Number(v[1][index].toString().replace('.', ''))) / Math.pow(10, len)
              }else if('W1' == pointName){
                numValue  = faker[dayjs().format('YYYY-MM-DD').charAt(dayjs().format('YYYY-MM-DD').length - 1)]
              }
              return [v[0], numValue]
            })
          })
        })
        if (threshold.length) {
          series.push({
            name: '告警值',
            type: 'line',
            itemStyle: {
              color: '#ff6363'
            },
            lineStyle: {
              width: 2,
              color: '#ff6363'
            },
            data: threshold
          })
        }
      })
      this.chart.clear()
      this.chart.setOption(
        {
          ...option,
          series
        },
        true
      )
      this.chart.resize();
    }
  },
  computed: {
    ...mapState({
      projectId: state => state.projectId,
      monitorType: state => state.monitorType,
      unit: state => state.unit
    })
  },
  watch: {
    projectId: function(newValue, oldValue) {
      // this.loadData()
    },
    monitorType: {
      handler: function(newValue, oldValue) {
        this.formInline.selectValue = newValue[0]
      },
      immediate: false
    },
    weekItemValue(val) {
      switch (val) {
        case '日':
          this.formInline.timeItemValue = [
            dayjs()
              .subtract(24, 'hour')
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs().format('YYYY-MM-DD HH:mm:ss')
          ]
          break
        case '周':
          this.formInline.timeItemValue = [
            dayjs()
              .subtract(7, 'day')
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs().format('YYYY-MM-DD HH:mm:ss')
          ]
          break
        case '月':
          this.formInline.timeItemValue = [
            dayjs()
              .subtract(30, 'day')
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs().format('YYYY-MM-DD HH:mm:ss')
          ]
      }
    },
    chartType() {
      this.updateChart()
    },
    'formInline.selectItemValue'() {
      this.updateChart()
    },
    'formInline.selectValue': {
      handler: 'loadData',
      immediate: true
    },
    params: {
      handler() {
        this.formInline.selectValue = this.params.selectValue
        this.formInline.monitorPointer = this.params.monitorPointer
        this.formInline.timeItemValue = this.params.timeItemValue
        this.$nextTick(() => {
          this.search()
        })
      },
      deep: true
    }
  }
}
</script>
