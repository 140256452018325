/*
 * @Author: your name
 * @Date: 2021-07-10 07:57:41
 * @LastEditTime: 2021-07-20 08:51:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \tailings_pond_client_devd:\Codes\zhongyun\xiling_pond_fe_master\xiling-pond-template\src\env.js
 */
export default {
  // API_BASE_URL: 'http://106.55.43.6:8302', // 西岭沟接口
  API_BASE_URL: 'http://42.194.204.23:8302',
  VIDEO_BASEURL: 'http://120.52.31.17:18080/api/play/start', // 视频地址
  updateTime: 10 * 60 * 1000
}
