<template>
  <div class="home-container">
    <div class="header">
      <div class="header-top">
        <div class="logo" @click="toIndex()"></div>
        <div class="title" @click="toIndex()">冬茅冲尾矿库在线监测平台</div>
        <div class="component">
          <!-- 综合分析 -->
          <router-link to="/home/real-time-monitor" class="comprehensive-analysis">
            <div class="icon"></div>
            <div class="text">实时监测</div>
          </router-link>
          <!-- 告警管理 -->
          <router-link to="/home/alarm-manage" class="alarm-management">
            <div class="icon"></div>
            <div class="text">监测告警</div>
            <div class="bottomSelect">
              <router-link to="/home/alarm-manage" class="comprehensive-analysis">告警详情</router-link>
              <router-link to="/home/video-monitor" class="video"> 视频监测</router-link>
            </div>
          </router-link>
          <!-- 数据查询 -->
          <router-link to="/home/complex-analyse" class="data-query">
            <div class="icon"></div>
            <div class="text">数据查询</div>
            <div class="bottomSelect" v-if="this.flag">
            <!-- <div class="bottomSelect"> -->
              <router-link to="/home/complex-analyse" class="comprehensive-analysis">实时数据</router-link>
              <router-link to="/home/data-query" class="his-data"> 历史数据</router-link>
              <router-link to="/home/data-reset" class="comprehensive-analysis"> 数据处理</router-link>
            </div>
            <div class="bottomSelect" v-else>
              <router-link to="/home/complex-analyse" class="comprehensive-analysis">实时数据</router-link>
              <router-link to="/home/data-query" class="his-data"> 历史数据</router-link>
            </div>
          </router-link>
          <!-- 数据分析 -->
          <router-link to="/home/data-compared" class="data-analysis">
            <div class="icon"></div>
            <div class="text">数据分析</div>
            <div class="bottomSelect">
              <!-- 放三个router-link -->
              <router-link to="/home/data-compared" class="data-compared">数据对比</router-link>
              <router-link to="/home/multi-point" class="multiplepoint-analysis">多测点分析</router-link>
              <router-link to="/home/association-analysis" class="association-analysis">关联分析</router-link>
              <router-link to="/home/point-map" class="point-map">布点图</router-link>
            </div>
          </router-link>
        </div>
        <!-- 用户头像 -->
        <div class="user-icon">
          <span></span>
        </div>
        <!-- 用户名 -->
        <div class="username">管理员</div>
        <!-- 设置区域 -->
        <div class="shezhi">
          <span></span>
          <div class="logout-container" @click="logout">
            <div class="logout-icon"></div>
            <div class="logout-text">退出登录</div>
          </div>
        </div>
      </div>
      <!-- 头部天气区域 -->
      <div class="weather-container">
        <Weather />
      </div>
    </div>
    <!-- 内容部分， 显示不同功能模块组件的内容区域 -->
    <div class="content-container">
      <!-- 路由站位符号， 存放不同组件内容 -->
      <div class="routerView">
        <router-view />
      </div>
      <!-- 内容底部区域 -->
      <div class="content-footer">
        <!-- 版权 -->
        <div class="copyright">湖南蓬源鸿达矿业有限公司</div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Weather from '@/components/Weather/index'

export default {
  components: {
    Weather
  },
  data() {
    return {
      flag: false
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        const newVal = window.screen.height
        if (700 > newVal) {
          document.documentElement.style.fontSize = '30px'
        } else if (700 >= newVal && newVal > 600) {
          document.documentElement.style.fontSize = '50px'
        } else if (800 >= newVal && newVal > 700) {
          document.documentElement.style.fontSize = '75px'
        } else if (1000 >= newVal && newVal > 900) {
          document.documentElement.style.fontSize = '100px'
        } else if (newVal >= 1000) {
          document.documentElement.style.fontSize = '150px'
        }
      })()
    }
  },
  created() {
    const height = window.screen.height
    if (900 >= height) {
      document.documentElement.style.fontSize = '50px'
    } else if (1000 >= height && height > 900) {
      document.documentElement.style.fontSize = '75px'
    } else if (1100 >= height && height > 1000) {
      document.documentElement.style.fontSize = '100px'
    } else if (height > 1100) {
      document.documentElement.style.fontSize = '150px'
    }
    if (!this.$route.fullPath.includes('real-time-monitor')) {
      this.$nextTick(() => {
        this.styleChange()
      })
    }
    this.$nextTick(()=>{
      this.flag = window.sessionStorage.getItem("username") != "zhuxiaojiang"? false : true
    })
  },
  watch: {
    $route(to, from) {
      if (to.path.includes('real-time-monitor')) {
        window.location.reload()
      } else {
        this.styleChange()
      }
    }
  },
  methods: {
    logout() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    toIndex() {
      this.$router.push('/home/real-time-monitor')
    },
    styleChange() {
      // document.documentElement.style.fontSize = '100px'
      var homeContainer = document.getElementsByClassName('home-container')
      var contentContainer = document.getElementsByClassName('content-container')
      var headerTop = document.getElementsByClassName('header-top')
      var contentFooter = document.getElementsByClassName('content-footer')
      var weatherContainer = document.getElementsByClassName('weather-container')
      for (let i = 0; i < homeContainer.length; i++) {
        homeContainer[i].style.background = '#edf0f5'
      }
      for (let i = 0; i < contentContainer.length; i++) {
        contentContainer[i].style.background = '#fff'
        contentContainer[i].style.margin = '0.24rem 0 0 0'
      }
      for (let i = 0; i < headerTop.length; i++) {
        headerTop[i].style.background = '#3F51B5'
      }
      for (let i = 0; i < contentFooter.length; i++) {
        contentFooter[i].style.borderTop = '1px solid #e9e9e9'
      }
      for (let i = 0; i < weatherContainer.length; i++) {
        weatherContainer[i].style.background = '#ffffff'
        weatherContainer[i].style.color = '#333333'
        weatherContainer[i].style.opacity = '1'
      }
    }
  }
}
</script>
<style lang="less" scoped>
// 整个首页包裹容器
.home-container {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  background: #271055;
  background-image: radial-gradient(farthest-side ellipse at 400px 40px, #409eff, #271055);
  // background-image: radial-gradient(ellipse, #409eff, #271055);
  opacity: 1;

  // 首页顶部header
  .header {
    width: 100%;
    height: 70px;

    // 顶部头
    .header-top {
      width: 100%;
      height: 54px;
      // display: -webkit-flex;
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // justify-content: center;
      background: transparent;
      border-bottom: 1px solid #4759b8;
      opacity: 1;

      .logo {
        position: absolute;
        left: 20px;
        width: 40px;
        top: 10px;
        height: 40px;
        cursor: pointer;
        background: url(../assets/logo.png) no-repeat;
        background-size: 100% 100%;
      }

      .title {
        position: absolute;
        left: 70px;
        top: 10px;
        height: 37px;
        cursor: pointer;
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 37px;
        color: #ffffff;
        opacity: 1;
      }

      .component {
        position: absolute;
        top: 0px;
        right: 5rem;
        width: 656px;
        height: 54px;
        display: flex;

        // 选中的router-link
        .router-link-active {
          background: #303f9f;
          width: 328px;
          opacity: 1;
        }

        .comprehensive-analysis {
          position: relative;
          flex: 1;

          .icon {
            position: absolute;
            left: 32px;
            top: 18px;
            width: 24px;
            height: 24px;
            background: url(../assets/comprehensiveAnalysis.png) no-repeat;
            background-size: 100% 100%;
          }

          .text {
            position: absolute;
            top: 19px;
            right: 32px;
            width: 64px;
            height: 22px;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #ffffff;
            opacity: 0.8;
          }
        }

        .alarm-management {
          position: relative;
          flex: 1;

          .icon {
            position: absolute;
            left: 32px;
            top: 18px;
            width: 24px;
            height: 24px;
            background: url(../assets/alarmManage.png) no-repeat;
            background-size: 100% 100%;
          }

          .text {
            position: absolute;
            top: 19px;
            right: 32px;
            width: 64px;
            height: 22px;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #ffffff;
            opacity: 0.8;
          }

          .bottomSelect {
            display: none;
            position: absolute;
            left: 0;
            top: 54px;
            width: 164px;
            // height: 120px;
            z-index: 999;
            background: #fff;

            .video {
              display: block;
              width: 164px;
              height: 40px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 40px;
              color: #666666;
              opacity: 1;
              text-align: center;
            }

            .router-link-active {
              background: #eef3ff;
              color: #3f51b5;
            }

            .comprehensive-analysis {
              display: block;
              width: 164px;
              height: 40px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 40px;
              color: #666666;
              opacity: 1;
              text-align: center;
            }
          }
        }
        .alarm-management:hover {
          background: #303f9f;

          .bottomSelect {
            display: block;

            .router-link-active {
              background: #eef3ff;
              color: #3f51b5;
            }
          }
        }
        .data-query {
          position: relative;
          flex: 1;

          .icon {
            position: absolute;
            left: 32px;
            top: 18px;
            width: 24px;
            height: 24px;
            background: url(../assets/dataQuery.png) no-repeat;
            background-size: 100% 100%;
          }

          .text {
            position: absolute;
            top: 19px;
            right: 32px;
            width: 64px;
            height: 22px;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #ffffff;
            opacity: 0.8;
          }

          .bottomSelect {
            display: none;
            position: absolute;
            left: 0;
            top: 54px;
            width: 164px;
            // height: 120px;
            z-index: 999;
            background: #fff;

            .his-data {
              display: block;
              width: 164px;
              height: 40px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 40px;
              color: #666666;
              opacity: 1;
              text-align: center;
            }

            .router-link-active {
              background: #eef3ff;
              color: #3f51b5;
            }

            .comprehensive-analysis {
              display: block;
              width: 164px;
              height: 40px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 40px;
              color: #666666;
              opacity: 1;
              text-align: center;
            }
          }
        }

        .data-query:hover {
          background: #303f9f;

          .bottomSelect {
            display: block;

            .router-link-active {
              background: #eef3ff;
              color: #3f51b5;
            }
          }
        }

        .data-analysis {
          position: relative;
          flex: 1;

          .icon {
            position: absolute;
            left: 32px;
            top: 18px;
            width: 24px;
            height: 24px;
            background: url(../assets/dataAnalysis.png) no-repeat;
            background-size: 100% 100%;
          }

          .text {
            position: absolute;
            top: 19px;
            right: 32px;
            width: 64px;
            height: 22px;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #ffffff;
            opacity: 0.8;
          }

          .bottomSelect {
            display: none;
            position: absolute;
            left: 0;
            top: 54px;
            width: 164px;
            // height: 120px;
            z-index: 999;
            background: #fff;

            .data-compared {
              display: block;
              width: 164px;
              height: 40px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 40px;
              color: #666666;
              opacity: 1;
              text-align: center;
            }

            .router-link-active {
              background: #eef3ff;
              color: #3f51b5;
            }

            .multiplepoint-analysis {
              display: block;
              width: 164px;
              height: 40px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 40px;
              color: #666666;
              opacity: 1;
              text-align: center;
            }

            .point-map {
              display: block;
              width: 164px;
              height: 40px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 40px;
              color: #666666;
              opacity: 1;
              text-align: center;
            }

            .association-analysis {
              display: block;
              width: 164px;
              height: 40px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 40px;
              color: #666666;
              opacity: 1;
              text-align: center;
            }
          }
        }

        .data-analysis:hover {
          background: #303f9f;

          .bottomSelect {
            display: block;

            .router-link-active {
              background: #eef3ff;
              color: #3f51b5;
            }
          }
        }
      }

      .user-icon {
        position: absolute;
        top: 16px;
        right: 170px;
        width: 32px;
        height: 32px;
        background: #f6f6f6;
        border-radius: 50%;

        span {
          position: absolute;
          width: 32px;
          height: 32px;
          background: url(../assets/user-icon.png) no-repeat;
          background-size: 100% 100%;
        }
      }

      .username {
        position: absolute;
        right: 120px;
        top: 22px;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #ffffff;
        opacity: 1;
      }

      .shezhi {
        position: absolute;
        top: 0;
        right: 41px;
        width: 64px;
        height: 54px;
        background: #303f9f;
        opacity: 1;
        cursor: pointer;

        span {
          position: absolute;
          top: 20px;
          left: 20px;
          width: 24px;
          height: 24px;
          background: url(../assets/shezhi.png) no-repeat;
          background-size: 100% 100%;
        }

        // 退出登录容器
        .logout-container {
          display: none;
          position: absolute;
          top: 54px;
          right: 0px;
          width: 168px;
          height: 49px;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0);
          box-shadow: 0px 0.2rem 0.08rem rgba(0, 0, 0, 0.15);
          opacity: 1;
          cursor: pointer;
          z-index: 999;

          .logout-icon {
            position: absolute;
            left: 22px;
            top: 14px;
            width: 20px;
            height: 20px;
            background: url(../assets/logout-icon.png) no-repeat;
            background-size: 100% 100%;
          }

          .logout-text {
            position: absolute;
            top: 14px;
            right: 50px;
            height: 20px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 20px;
            color: #666666;
            opacity: 1;
          }
        }
      }

      .shezhi:hover .logout-container {
        display: block;
      }
    }

    // 顶部天气区域
    .weather-container {
      width: 100%;
      height: 16px;
      padding: 0 13px;
      font-size: 0.12rem;
      background: transparent;
      opacity: 0.32;
      color: #ffffff;
    }
  }

  // 内容区域容器
  .content-container {
    width: 95%;
    height: calc(100vh - 70px);
    background: transparent;
    box-shadow: 0px 0.03rem 0.06rem rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 0.02rem;

    .routerView {
      width: 100%;
    }

    .content-footer {
      position: absolute;
      margin: 0px;
      border-bottom: 0px;
      width: 95%;
      bottom: 0px;
      background: transparent;
      border-top: 1px solid #4759b8;

      .copyright {
        width: 100%;
        text-align: center;
        font-size: 0.14rem;
        font-family: PingFang SC;
        color: #999999;
        opacity: 1;
      }
    }
  }
}
</style>
