<template>
  <div class="multiPoint">
    <div class="title">多测点分析</div>
    <div class="container">
      <div class="section">
        <div class="section-title">新增分组</div>
        <div class="section-body form-body">
          <el-form ref="form" inline :model="form" :rules="rules">
            <el-form-item label="分析指标：" prop="selectedType">
              <el-select clearable v-model="form.selectedType">
                <el-option
                  v-for="item in monitorType"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="分组名称：" prop="groupName">
              <el-input v-model="form.groupName" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button plain type="primary" @click="pointSelectVisible = true"
                >测点选择</el-button
              >
              <PointSelector
                :visible.sync="pointSelectVisible"
                @ok="handlePointsSelect"
                @close="pointSelectVisible = false"
              />
            </el-form-item>
          </el-form>
          <div class="form-footer">
            <el-button type="primary" @click="createNew">保存</el-button>
            <router-link to="/home/multi-point">
              <el-button style="margin-left: 100px">返回</el-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section-title">分组管理</div>
        <div class="section-body table-body">
          <el-table
            :header-row-style="{ backgroundColor: '#FAFAFA' }"
            :data="groups"
          >
            <el-table-column label="序号" type="index" />
            <el-table-column label="分析类型" prop="itemName" />
            <el-table-column label="分组名称" prop="groupName" />
            <el-table-column label="测点">
              <template slot-scope="scope">
                {{
                  scope.row.monitorPoints &&
                  scope.row.monitorPoints
                    .map(({ pointName }) => pointName)
                    .join(',')
                }}
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="danger" @click="() => deleteGroup(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { createNewPointGroup, deletePointGroup, getAllPointGroup } from '@/api'
import PointSelector from '@/components/PointSelector'

export default {
  components: { PointSelector },
  mounted() {
    this.loadGroups()
  },
  data() {
    return {
      monitorType: ['浸润线', '内部位移线'],
      form: {
        selectedType: '',
        groupName: '',
      },
      rules: {
        selectedType: [{ required: true, message: '请选择分析指标' }],
        groupName: [{ required: true, message: '请输入分组名称' }],
      },
      pointSelectVisible: false,
      selectedPoints: [],
      groups: [],
    }
  },
  methods: {
    async loadGroups() {
      try {
        this.groups = await getAllPointGroup()
      } catch (e) {
        console.log(e)
      }
    },
    handlePointsSelect(points) {
      this.selectedPoints = points
      this.pointSelectVisible = false
    },
    async createNew() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return false
        if (!this.selectedPoints.length) {
          this.$message.error('请选择测点')
          return false
        }
        const data = {
          itemName: this.form.selectedType,
          groupName: this.form.groupName,
          monitorPointIds: this.selectedPoints.map(
            ({ monitorPointId }) => monitorPointId
          ),
        }
        try {
          await createNewPointGroup(data)
          this.$message.success('保存成功')
          this.loadGroups().catch()
        } catch (e) {
          this.$message.error('保存失败')
          console.log(e)
        }
      })
    },
    async deleteGroup({ pointGroupId }) {
      try {
        await deletePointGroup(pointGroupId)
        this.$message.success('删除成功')
        this.loadGroups().catch()
      } catch (e) {
        this.$message.error('删除失败')
        console.log(e)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.multiPoint {
  width: 100%;
  height: 100%;

  .title {
    display: block;
    position: relative;
    padding: 24px 0 20px 24px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 24px;
      width: 4px;
      height: 24px;
      background: #3f51b5;
      border-radius: 4px;
    }
  }

  .container {
    max-width: 900px;
    margin: 0 auto;
  }
}

.section {
  margin-bottom: 48px;
}

.section-title {
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  margin-bottom: 24px;
}

.form-body {
  border: 1px solid #e9e9e9;
  padding: 24px;
  min-height: 190px;
}

.form-footer {
  text-align: center;
  margin-top: 80px;
}

.table-header {
  background-color: #fafafa;
}

.table-body {
  border: 1px solid #e9e9e9;
  border-bottom: 0;
}
</style>
