<template>
  <div class="alarmManagement">
    <div class="header">
      <div class="bar"></div>
      <div class="title">告警管理</div>
    </div>
    <div class="content">
      <!-- <el-form :inline="true" :model="formInline" id="selectForm" size="mini"> -->
      <el-form :inline="true" :model="formInline" id="selectForm">
        <el-row>
          <el-form-item label="监测指标:" class="monitorIndex">
            <el-select v-model="formInline.selectValue">
              <el-option label="全部" :value="undefined"></el-option>
              <el-option :key="item" :label="item.includes('干滩高程') ? '干滩长度' : item" :value="item" v-for="item in monitorType"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="监测点:" class="monitorPoint">
            <el-select v-model="formInline.monitorPointer" placeholder="请选择">
              <el-option label="全部" :value="undefined" v-if="!formInline.selectValue"></el-option>
              <div v-if="formInline.selectValue">
                <el-option :key="item.deviceId" :value="item.pointName" :label="item.pointName" v-for="item in monitorPointData" />
              </div>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="告警状态:" class="monitorIndex">
            <el-select v-model="formInline.alarmStatus">
              <el-option label="全部" :value="undefined"></el-option>
              <el-option label="已确认" :value="1"></el-option>
              <el-option label="未确认" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="起止时间:">
            <el-date-picker v-model="formInline.timeItemValue" type="datetimerange" :clearable="false" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item></el-row
        >
      </el-form>
      <!-- 告警管理数据列表 -->
      <el-table :data="alarmList" :row-style="{ height: '0.1rem' }" height="calc(100vh - 350px)" border :header-cell-style="{ color: '#333333' }">
        <el-table-column type="index" label="序号" width="60"> </el-table-column>
        <el-table-column prop="monitorItemName" label="监测指标" min-width="120">
          <template slot-scope="scope">
            <div :class="scope.row.monitorItemName">
              <!-- <span></span> -->
              <span>{{ scope.row.monitorItemName.includes('干滩高程') ? '干滩长度' : scope.row.monitorItemName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="monitorPointName" label="监测点" min-width="100"> </el-table-column>
        <el-table-column prop="alarmLevel" label="告警等级" min-width="80">
          <template slot-scope="scope">
            <div :class="scope.row.alarmLevel">{{ scope.row.alarmLevel }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="alarmContent" label="告警内容" min-width="500" :show-overflow-tooltip="true"> </el-table-column>
        <el-table-column prop="createdTime" label="告警时间" min-width="150"> </el-table-column>
        <el-table-column prop="alarmStatus" label="告警状态">
          <template slot-scope="scope">
            <div :class="scope.row.alarmStatus">
              <!-- <span></span> -->
              <span>{{ scope.row.alarmStatus === 0 ? '未处理' : '已处理' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="monitorPoint" label="操作" min-width="80">
          <template slot-scope="scope">
            <span v-if="!scope.row.alarmStatus"  style="color: #409EFF; cursor: pointer " @click="changeAlarmDrawer(scope.row)">处理</span>
            <span v-else  style="color: #409EFF; cursor: pointer " @click="showAlarmDrawer(scope.row)">查看</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagination.pagenum" :page-sizes="[10, 50, 100, 200, 500]" :page-size="pagination.pagesize" layout="total, prev, pager, next, sizes, jumper" :total="pagination.total" background> </el-pagination>
      </div>
    </div>
    <el-drawer :title="alarmState ? '查看结果' : '告警处理'" @close="handleClose" :visible.sync="alarmDialog" ref="drawer">
      <div class="demo-drawer__content">
        <el-form :model="alarmFrom">
          <el-form-item label="监测点名称：" label-width="100px">
            {{ alarmFrom.monitorPointName }}
          </el-form-item>
          <el-form-item label="告警等级：" label-width="100px">
            {{ alarmFrom.alarmLevel }}
          </el-form-item>
          <el-form-item label="告警时间：" label-width="100px">
            {{ alarmFrom.createdTime }}
          </el-form-item>
          <el-form-item label="告警内容：" label-width="100px">
            {{ alarmFrom.alarmContent }}
          </el-form-item>
          <el-form-item label="处理结果：" label-width="100px">
            <el-input v-model="alarmFrom.processResult" :disabled="alarmState"></el-input>
          </el-form-item>
        </el-form>
        <div v-if="!alarmState" style="padding-left:20px">
          <el-button type="primary" @click="handleAlarm" :loading="loading">保存</el-button>
          <el-button @click="alarmDialog = false">关闭</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
/* eslint-disable */
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import { getMonitorPoints, modifyAlarmInfo } from '@/api'
export default {
  data() {
    return {
      formInline: {
        monitorPointer: undefined,
        selectValue: undefined,
        alarmStatus: undefined,
        timeItemValue: [
          dayjs()
            .subtract(24, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          dayjs().format('YYYY-MM-DD HH:mm:ss')
        ]
      },
      alarmFrom: {
        monitorIndex: '',
        monitorPoint: '',
        alarmLevel: '',
        alarmContent: '',
        alarmTime: '',
        result: ''
      },
      monitorPointData: [],
      // 获取综合分析列表的参数对象
      pagination: {
        total: 0,
        // 当前的页数
        pagenum: 1,
        // 当前每页显示多少条数据
        pagesize: 10
      },
      loading: false,
      // 列表数据
      alarmList: [],
      alarmState: true,
      alarmDialog: false
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getAlarmList()
    }, this.updateTime)
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    async loadData() {
      getMonitorPoints(this.projectId, this.formInline.selectValue)
        .then(data => {
          this.monitorPointData = data
          // 设置初始值
          this.formInline.monitorPointer = data[0]?.pointName
        })
        .catch(e => {})
    },
    onSubmit() {
      this.pagination.pagenum = 1
      this.getAlarmList()
    },
    getAlarmList() {
      if (!this.projectId) return
      // 用于获取列表数据
      this.$http
        .get(`/AlarmController/select-alarm-ifno/${this.projectId}`, {
          // .get(`/AlarmController/select-alarm-ifno/PR2020073000000`, {
          params: {
            page: this.pagination.pagenum,
            pageSize: this.pagination.pagesize,
            siteNo: this.projectId,
            monitorIndex: this.formInline.selectValue,
            monitorPoint: this.formInline.monitorPointer,
            alarmStatus: this.formInline.alarmStatus,
            beginTime: this.formInline.timeItemValue[0],
            endTime: this.formInline.timeItemValue[1]
          }
        })
        .then(res => {
          const { result, data } = res.data
          // if (result === 1) {
          //   this.pagination.total = data.total
          //   console.log('data.items：',dayjs(data.items[1].createdTime).valueOf(),dayjs(data.items[1].createdTime).valueOf() - dayjs(data.items[0].createdTime).valueOf())
          //   this.alarmList = data.items.sort(function(a,b){
              
          //   console.log('data.items11：',dayjs(a.createdTime).valueOf() - dayjs(b.createdTime).valueOf())
          //     return dayjs(a.createdTime).valueOf() - dayjs(b.createdTime).valueOf()
          //   })
          // } else {
            this.alarmList.length = 0
          // }
        })
    },
    // 监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      this.pagination.pagesize = newSize
      this.pagination.pagenum = 1
      this.getAlarmList()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newNum) {
      this.pagination.pagenum = newNum
      this.getAlarmList()
    },
    changeAlarmDrawer(row) {
      this.alarmFrom = row
      this.alarmState = false
      this.alarmDialog = true
    },
    showAlarmDrawer(row) {
      this.alarmFrom = row
      this.alarmState = true
      this.alarmDialog = true
    },
    handleAlarm(row) {
      let processor = sessionStorage.getItem('username') ? sessionStorage.getItem('username') : ''
      const data = {
        alarmId: this.alarmFrom.alarmId,
        processResult: this.alarmFrom.processResult,
        processor: processor
      }
      if (!this.alarmFrom.processResult || (this.alarmFrom.processResult && !this.alarmFrom.processResult.trim())) {
        return this.$message.error('处理结果不能为空')
      }
      modifyAlarmInfo(data).then(res => {
        if (res.result === 1) {
          this.$message.success('处理成功')
          this.alarmDialog = false
          this.getAlarmList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleClose() {
      this.alarmFrom.processResult = ''
    }
  },
  computed: {
    ...mapState({
      projectId: state => state.projectId,
      monitorType: state => state.monitorType
    })
  },
  watch: {
    projectId: {
      handler: 'getAlarmList',
      immediate: true
    },
    'formInline.selectValue'() {
      if (!this.formInline.selectValue) {
        this.formInline.monitorPointer = undefined
      } else {
        this.loadData()
      }
    },
    'formInline.monitorPointer'() {
      this.pagination.pagenum = 1
      this.getAlarmList()
    }
  }
}
</script>
<style lang="less" scoped>
.alarmManagement {
  width: 100%;
  height: 100%;
  .header {
    position: relative;
    margin-top: 0.24rem;
    width: 100%;
    height: 28px;
    .bar {
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 28px;
      background: #3f51b5;
      opacity: 1;
      border-radius: 4px;
    }
    .title {
      position: absolute;
      left: 24px;
      top: 0;
      height: 28px;
      line-height: 28px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      opacity: 1;
    }
  }
  .content {
    width: 95%;
    margin: 20px auto 0;
    .pagination {
      margin-bottom: 5px;
      text-align: right;

      .el-form-item__label {
        color: #333 !important;
        font-size: 14px;
      }
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #666;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #666;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #666;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #666;
    }
  }
  .el-form-item {
    margin-right: 30px;
  }
  .el-form-item__label {
    color: #333 !important;
    font-size: 14px;
  }
  .el-button--primary {
    background-color: #3f51b5;
    border-color: #3f51b5;
    width: 116px;
    opacity: 1;
    border-radius: 2px;
  }
  .el-table__header .cell {
    color: #000000;
  }
  .el-table td,
  .el-table th {
    padding: 0.4rem !important;
  }
  .el-table__row .cell {
    height: 0.1rem;
    position: relative;
    color: #000000;
    .一级告警 {
      color: #ff4343;
    }
    .二级告警 {
      color: #ff7043;
    }
    .三级告警 {
      color: #ffba43;
    }
    .已处理 {
      span:first-child {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #5d75ff;
        border-radius: 50%;
        opacity: 1;
        margin-right: 10px;
        transform: translateY(-2px);
      }
    }
    .已解除 {
      span:first-child {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #52c41a;
        border-radius: 50%;
        opacity: 1;
        margin-right: 10px;
        transform: translateY(-2px);
      }
    }
  }
  .el-input__inner .el-range-separator {
    color: #ccc;
  }
  // 分页样式
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #3f51b5;
    color: #fff;
  }
  .el-pagination.is-background .el-pager .number:hover {
    color: #3f51b5;
  }
}
</style>
