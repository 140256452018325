<!--
 * @Author: your name
 * @Date: 2021-07-10 07:57:41
 * @LastEditTime: 2021-08-10 15:34:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \tailings_pond_client_devd:\Codes\zhongyun\xiling_pond_fe_master\xiling-pond-template\src\components\Weather\index.vue
-->
<template>
  <span class="weather">
    <span>{{ city }}</span>
    <span v-if="weatherIcon"><img :src="weatherIcon" alt="" class="weather-icon"></span>
    <span>{{ weather }}</span>
    <span>{{ temperature }}℃</span>
    <span>{{ winddirection }}风{{ windpower }}级</span>
  </span>
</template>

<script>
/* eslint-disable */
import { getWeather } from '@/api/weather'
import weatherIconMap from './icon-map'

export default {
  name: 'Weather',
  data() {
    return {
      adcode: '430400', // 衡阳市
      city: '-',
      weather: '-',
      temperature: '-',
      winddirection: '-',
      windpower: '-'
    }
  },
  computed: {
    weatherIcon: function() {
      return weatherIconMap[this.weather]
    }
  },
  created() {
    getWeather(this.adcode).then((weatherData) => {
      if (!weatherData) return
      const { city, weather, temperature, winddirection, windpower } = weatherData
      this.city = city
      this.weather = weather
      this.temperature = temperature
      this.winddirection = winddirection
      this.windpower = windpower
    }).catch(e => {
      console.log('获取天气失败')
    })
  }
}
</script>

<style scoped lang="less">
.weather {
  font-size: 0.12rem;

  & > span {
    font-size: 0.12rem;
    margin-right: 0.16rem;
  }

  .weather-icon {
    width: 0.18rem;
  }
}
</style>
