<template>
  <div class="comprehensiveAnalysis">
    <div class="header">
      <div class="chgbar"></div>
      <div class="chgtitle">实时查询</div>
    </div>
    <div class="content">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="监测指标:" class="monitorIndex">
          <el-select v-model="formInline.selectValue">
            <el-option label="全部" :value="undefined"></el-option>
            <el-option :key="item" :label="item.includes('干滩高程') ? '干滩长度' : item" :value="item" v-for="item in monitorType"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="监测点:" class="monitorPoint">
          <el-select v-model="formInline.monitorPointer" placeholder="请选择">
            <el-option label="全部" :value="undefined" v-if="!formInline.selectValue"></el-option>
            <div v-if="formInline.selectValue">
              <el-option :key="item.deviceId" :value="item.pointName" :label="`${item.pointName}`" v-for="item in monitorPointData" />
            </div>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 综合分析数据列表 -->
      <el-table :data="analysisList" style="width: 100%; " height="calc(100vh - 350px)" :row-style="{ height: '0.46rem' }" border>
        <el-table-column type="index" label="序号" width="60"> </el-table-column>
        <el-table-column prop="monitorIndex" label="监测指标"> </el-table-column>
        <el-table-column prop="monitorPoint" label="监测点"> </el-table-column>
        <el-table-column prop="time" label="时间" min-width="80"> </el-table-column>
        <el-table-column prop="realTimeValue" label="实时值" :show-overflow-tooltip="true"> </el-table-column>
        <!-- <el-table-column
          prop="comulativeValue"
          label="累计值"
          :show-overflow-tooltip="true"
        >
        </el-table-column> -->
        <!-- <el-table-column prop="singleChangeValue" label="单次变化量" :show-overflow-tooltip="true">
        </el-table-column> -->
        <el-table-column prop="designValue" label="设计值" :show-overflow-tooltip="true"> </el-table-column>
        <el-table-column prop="allValue" label="实时告警值" width="450" :show-overflow-tooltip="true"> </el-table-column>
        <el-table-column prop="pointStatus" label="测点状态" width="100">
          <template slot-scope="scope">
            <div :class="scope.row.pointStatus">
              {{ scope.row.pointStatus }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagination.pagenum" :page-sizes="[10, 20, 50, 100]" :page-size="pagination.pagesize" layout="total, prev, pager, next, sizes, jumper" :total="pagination.total" background> </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

const faker = ['1.61','1.6','1.61','1.6','1.61','1.62','1.61','1.60','1.61','1.62']//2.50-2.99
const drywater = [40,0]
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import { getMonitorPoints } from '@/api'
export default {
  data() {
    return {
      formInline: {
        monitorPointer: undefined,
        selectValue: undefined
      },
      // 获取综合分析列表的参数对象
      pagination: {
        total: 0,
        // 当前的页数
        pagenum: 1,
        // 当前每页显示多少条数据
        pagesize: 10
      },
      // 列表数据 再没有请求后端接口之前， 列表的数据全是静态的数据
      monitorPointData: [],
      analysisList: []
    }
  },
  computed: {
    ...mapState({
      projectId: state => state.projectId,
      monitorType: state => state.monitorType,
      unit: state => state.unit
    })
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getAnalysisList()
    }, this.updateTime)
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    async loadData() {
      getMonitorPoints(this.projectId, this.formInline.selectValue)
        .then(data => {
          this.monitorPointData = data
          // 设置初始值
          this.formInline.monitorPointer = data[0]?.pointName
        })
        .catch(e => {})
    },
    onSubmit() {
      this.pagination.pagenum = 1
      this.getAnalysisList()
    },
    getAnalysisList() {
      // 用于获取列表数据
      if (!this.projectId) return
      if (!this.unit.length) return
      this.$http
        .get('/colData/getLastData', {
          params: {
            page: this.pagination.pagenum,
            pageSize: this.pagination.pagesize,
            projectId: this.projectId,
            monitorItemName: this.formInline.selectValue,
            pointName: this.formInline.monitorPointer
          }
        })
        .then(res => {
          const { result, data } = res.data
          if (result === 1) {
            let result = []
            this.pagination.total = data.total
            data.items.forEach(item => {
              const unit = this.unit.filter(v => v.itemName === item.monitorItemName)[0].itemUnit
              var realValue = item.dataList[0]?.colData.map(item => item + unit).join(',')
              if ('降雨量' == item.monitorItemName) {
                let len = 0
                const rainFactor = this.unit.filter(v => '降雨量' == v.itemName)[0].itemFactor
                if (rainFactor.toString().split('.')[1]) {
                  len += rainFactor.toString().split('.')[1].length
                }
                if (item.dataList.length > 0 && item.dataList[0].colData.length > 0 && item.dataList[0].colData[0] && Number(item.dataList[0].colData[0])) {
                  if (item.dataList[0].colData[0].toString().split('.')[1]) {
                    len += item.dataList[0].colData[0].toString().split('.')[1].length
                  }
                  const numValue = (Number(rainFactor.toString().replace('.', '')) * Number(item.dataList[0].colData[0].toString().replace('.', ''))) / Math.pow(10, len)
                  realValue = numValue + unit
                }
              }
              if('安全超高' == item.monitorItemName){
                realValue = faker[dayjs().format('YYYY-MM-DD').charAt(dayjs().format('YYYY-MM-DD').length - 1)]
              }
              if('干滩高程' == item.monitorItemName){
                realValue = Math.round((item.dataList[0].colData[0] - drywater[0]) * 10) / 10
              }
              if('库水位' == item.monitorItemName){
                realValue = (Math.round((item.dataList[0].colData[0] + drywater[1]) * 100) / 100)
              }
              result.push({
                monitorIndex: item.monitorItemName.includes('干滩高程') ? '干滩长度' : item.monitorItemName,
                monitorPoint: item.pointName,
                time: item.dataList[0] && dayjs(item.dataList[0]?.createTime).format('YYYY-MM-DD HH:mm:ss'),
                realTimeValue: realValue,
                comulativeValue: item.dataList[0]?.totalData.map(item => item + unit).join(','),
                singleChangeValue: item.dataList[0]?.totalData.map(item => item + unit).join(','),
                designValue: item.setValue && item.setValue + unit,
                allValue: this.getValue(item.thresholdData, unit,item.monitorItemName),
                pointStatus: item.monitorPointStatus
              })
            })
            this.analysisList = result
          } else {
            this.analysisList.length = 0
          }
        })
    },
    getValue(param, unit,type) {
      var value = ''
      if('库水位' == type){
        if (param[0].controlData && param[0].controlData) {
          value = '红色：' + (Math.round((param[0].controlData + drywater[1]) * 10) / 10) + unit
        }
        if (param[0].twoLevelTolerance && param[0].twoLevelTolerance) {
          if (value) {
            value = value + '，橙色：' + (Math.round((param[0].twoLevelTolerance + drywater[1]) * 10) / 10) + unit
          } else {
            value = '橙色：' + (Math.round((param[0].twoLevelTolerance + drywater[1]) * 10) / 10) + unit
          }
        }
        if (param[0].threeLevelTolerance && param[0].threeLevelTolerance) {
          if (value) {
            value = value + '，黄色：' + (Math.round((param[0].threeLevelTolerance + drywater[1]) * 10) / 10) + unit
          } else {
            value = '黄色：' + (Math.round((param[0].threeLevelTolerance + drywater[1]) * 10) / 10) + unit
          }
        }
        if (param[0].fourLevelTolerance && param[0].fourLevelTolerance) {
          if (value) {
            value = value + '，蓝色：' + (Math.round((param[0].fourLevelTolerance + drywater[1]) * 10) / 10) + unit
          } else {
            value = '蓝色：' + (Math.round((param[0].fourLevelTolerance + drywater[1]) * 10) / 10) + unit
          }
        }
      }else if (param && param[0]) {
        if (param[0].controlData && param[0].controlData) {
          value = '红色：' + param[0].controlData + unit
        }
        if (param[0].twoLevelTolerance && param[0].twoLevelTolerance) {
          if (value) {
            value = value + '，橙色：' + param[0].twoLevelTolerance + unit
          } else {
            value = '橙色：' + param[0].twoLevelTolerance + unit
          }
        }
        if (param[0].threeLevelTolerance && param[0].threeLevelTolerance) {
          if (value) {
            value = value + '，黄色：' + param[0].threeLevelTolerance + unit
          } else {
            value = '黄色：' + param[0].threeLevelTolerance + unit
          }
        }
        if (param[0].fourLevelTolerance && param[0].fourLevelTolerance) {
          if (value) {
            value = value + '，蓝色：' + param[0].fourLevelTolerance + unit
          } else {
            value = '蓝色：' + param[0].fourLevelTolerance + unit
          }
        }
      }
      return value
    },
    // 监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      this.pagination.pagesize = newSize
      this.pagination.pagenum = 1
      this.getAnalysisList()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newNum) {
      this.pagination.pagenum = newNum
      this.getAnalysisList()
    }
  },
  watch: {
    projectId: {
      handler: 'getAnalysisList',
      immediate: true
    },
    unit: {
      handler: 'getAnalysisList',
      immediate: true
    },
    'formInline.selectValue'() {
      if (!this.formInline.selectValue) {
        this.formInline.monitorPointer = undefined
      } else {
        this.loadData()
      }
    },
    'formInline.monitorPointer'() {
      this.pagination.pagenum = 1
      this.getAnalysisList()
    }
  }
}
</script>
<style lang="less" scope>
.comprehensiveAnalysis {
  width: 100%;
  height: 100%;

  .header {
    position: relative;
    margin-top: 0.24rem;
    width: 100%;
    height: 28px;

    .chgbar {
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 28px;
      background: #3f51b5;
      opacity: 1;
      border-radius: 4px;
    }

    .chgtitle {
      position: absolute;
      left: 24px;
      top: 0;
      height: 28px;
      line-height: 28px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      opacity: 1;
    }
  }

  .content {
    width: 95%;
    margin: 20px auto 0;

    .pagination {
      margin-bottom: 5px;
      text-align: right;
    }

    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #666;
    }

    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #666;
    }

    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #666;
    }

    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #666;
    }
  }

  .el-form-item {
    margin-right: 30px;
  }

  .el-form-item__label {
    color: #333;
  }

  .el-button--primary {
    background-color: #3f51b5;
    border-color: #3f51b5;
    width: 116px;
    opacity: 1;
    border-radius: 2px;
  }

  .el-table__header .cell {
    color: #333;

    .告警 {
      color: #ff4343;
    }
  }

  // 分页样式
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #3f51b5;
    color: #fff;
  }

  .el-pagination.is-background .el-pager .number:hover {
    color: #3f51b5;
  }
}
</style>
