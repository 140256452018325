<template>
  <div class="dataQuery">
    <div class="header">
      <div class="bar"></div>
      <div class="title">历史查询</div>
    </div>
    <div class="content">
      <!-- tab栏目 切换为列表或者 图表  -->
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="列表" name="first">
          <!-- 栏目内容 -->
          <!-- 查询头 -->
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="监测指标:" class="monitorIndex">
              <el-select v-model="formInline.selectValue">
                <el-option :key="item" :label="item.includes('干滩高程') ? '干滩长度' : item" :value="item" v-for="item in monitorType"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="监测点:" class="monitorPoint">
              <el-select v-model="formInline.monitorPointer" placeholder="请选择">
                <div v-if="formInline.selectValue">
                  <el-option :key="item.deviceId" :value="item.deviceId" :label="`${item.pointName}`" v-for="item in monitorPointData" />
                </div>
              </el-select>
            </el-form-item>
            <el-form-item label="起止时间:">
              <el-date-picker v-model="formInline.timeItemValue" type="datetimerange" :clearable="false" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
          </el-form>
          <!-- 信息列表 -->
          <el-table :data="alarmList" style="width: 100%; " height="calc(100vh - 350px)" border :header-cell-style="{ color: '#333333' }" :row-style="{ height: '0.46rem' }">
            <el-table-column type="index" label="序号" width="60"> </el-table-column>
            <el-table-column label="监测指标">
              <template slot-scope="scope"
                ><span>{{ scope.row.monitorIndex.includes('干滩长度') ? '干滩长度' : scope.row.monitorIndex }}</span></template
              >
            </el-table-column>
            <el-table-column prop="monitorPoint" label="监测点"> </el-table-column>
            <el-table-column prop="time" label="时间" min-width="100"> </el-table-column>
            <el-table-column prop="realTimeValue" label="实时值" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="comulativeValue" label="累计值" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="designValue" label="设计值" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="allValue" label="实时/累计告警值" width="450" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="pointStatus" label="测点状态" width="100">
              <template slot-scope="scope">
                <div :class="scope.row.pointStatus">
                  {{ scope.row.pointStatus }}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页区域 -->
          <div class="pagination">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagination.pagenum" :page-sizes="[10, 20, 50, 100]" :page-size="pagination.pagesize" layout="total, prev, pager, next, sizes, jumper" :total="pagination.total" background> </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="图表" name="second">
          <Chart :params="formInline" :activeName="activeName" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

const faker = ['1.61','1.6','1.61','1.6','1.61','1.62','1.61','1.60','1.61','1.62']
const drywater = [40,0]
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import Chart from './chart.vue'
import { getMonitorPoints } from '@/api'
export default {
  data() {
    return {
      faker:['1.61','1.6','1.61','1.6','1.61','1.62','1.61','1.60','1.61','1.62'],
      fakerIndex:dayjs().format('YYYY-MM-DD').charAt(dayjs().format('YYYY-MM-DD').length - 1),
      activeName: 'first',
      monitorPointData: [],
      formInline: {
        monitorPointer: undefined,
        selectValue: undefined,
        timeItemValue: [
          dayjs()
            .subtract(24, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          dayjs().format('YYYY-MM-DD HH:mm:ss')
        ],
        dataType: '实时值'
      },
      pagination: {
        total: 0,
        // 当前的页数
        pagenum: 1,
        // 当前每页显示多少条数据
        pagesize: 10
      },
      // 列表数据
      alarmList: []
      // dataList: [
      //   {
      //     monitorIndex: '内部位移',
      //     monitorPoint: '干滩长度',
      //     time: '2020-6-8 15:16',
      //     realTimeValue: '9.7mm',
      //     comulativeValue: '9.7mm',
      //     singleChangeValue: '0.12mm',
      //     designValue: '3.5',
      //     allValue: 'X:4.0 Y:4.0 / X:4.0 Y:4.0 / X:4.0 Y:4.0',
      //     pointStatus: '正常',
      //   },
      // ],
    }
  },
  mounted() {},
  methods: {
    async loadData() {
      getMonitorPoints(this.projectId, this.formInline.selectValue)
        .then(data => {
          this.monitorPointData = data
          // 设置初始值
          this.formInline.monitorPointer = data[0]?.deviceId
        })
        .catch(e => {})
    },
    onSubmit() {
      this.pagination.pagenum = 1
      this.getAlarmList()
    },
    getAlarmList() {
      if (!this.projectId) return
      if (!this.unit.length) return
      // 用于获取列表数据
      this.$http
        .get('/colData/getColDataPage', {
          params: {
            page: this.pagination.pagenum,
            pageSize: this.pagination.pagesize,
            // projectId: this.projectId,
            monitorItemName: this.formInline.selectValue,
            deviceIds: this.formInline.monitorPointer,
            beginTime: this.formInline.timeItemValue[0],
            endTime: this.formInline.timeItemValue[1]
          }
        })
        .then(res => {
          const { result, data } = res.data
          if (result === 1) {
            let result = []
            this.pagination.total = data.total
            data.items.forEach(item => {
              const unit = this.unit.filter(v => v.itemName === item.monitorItemName)[0].itemUnit
              var realValue = item.dataList[0]?.colData.map(item => item + unit).join(',')
              if ('降雨量' == item.monitorItemName) {
                const rainFactor = this.unit.filter(v => '降雨量' == v.itemName)[0].itemFactor
                let len = 0
                if (rainFactor.toString().split('.')[1]) {
                  len += rainFactor.toString().split('.')[1].length
                }
                if (item.dataList.length > 0 && item.dataList[0].colData.length > 0 && item.dataList[0].colData[0] && Number(item.dataList[0].colData[0])) {
                  if (item.dataList[0].colData[0].toString().split('.')[1]) {
                    len += item.dataList[0].colData[0].toString().split('.')[1].length
                  }
                  const numValue = (Number(rainFactor.toString().replace('.', '')) * Number(item.dataList[0].colData[0].toString().replace('.', ''))) / Math.pow(10, len)
                  realValue = numValue + unit
                }
              }
              if('安全超高' == item.monitorItemName){
                realValue = faker[dayjs().format('YYYY-MM-DD').charAt(dayjs().format('YYYY-MM-DD').length - 1)]
              }
              if('干滩高程' == item.monitorItemName){
                realValue = Math.round((item.dataList[0].colData[0] - drywater[0]) * 10) / 10
              }
              if('库水位' == item.monitorItemName){
                realValue = (Math.round((item.dataList[0].colData[0] + drywater[1]) * 100) / 100)
              }
              result.push({
                monitorIndex: item.monitorItemName.includes('干滩高程') ? '干滩长度' : item.monitorItemName,
                monitorPoint: item.pointName,
                time: item.dataList[0] && dayjs(item.dataList[0]?.createTime).format('YYYY-MM-DD HH:mm:ss'),
                realTimeValue: realValue,
                comulativeValue: item.dataList[0] && item.dataList[0]?.totalData.map(item => item + unit).join(','),
                designValue: item.setValue,
                allValue: this.getValue(item.thresholdData, unit,item.monitorItemName),
                pointStatus: item.monitorPointStatus
              })
            })
            this.alarmList = result
          } else {
            this.alarmList.length = 0
          }
        })
    },
    getValue(param, unit,type) {
      var value = ''
      if('库水位' == type){
        if (param[0].controlData && param[0].controlData) {
          value = '红色：' + (Math.round((param[0].controlData + drywater[1]) * 10) / 10) + unit
        }
        if (param[0].twoLevelTolerance && param[0].twoLevelTolerance) {
          if (value) {
            value = value + '，橙色：' + (Math.round((param[0].twoLevelTolerance + drywater[1]) * 10) / 10) + unit
          } else {
            value = '橙色：' + (Math.round((param[0].twoLevelTolerance + drywater[1]) * 10) / 10) + unit
          }
        }
        if (param[0].threeLevelTolerance && param[0].threeLevelTolerance) {
          if (value) {
            value = value + '，黄色：' + (Math.round((param[0].threeLevelTolerance + drywater[1]) * 10) / 10) + unit
          } else {
            value = '黄色：' + (Math.round((param[0].threeLevelTolerance + drywater[1]) * 10) / 10) + unit
          }
        }
        if (param[0].fourLevelTolerance && param[0].fourLevelTolerance) {
          if (value) {
            value = value + '，蓝色：' + (Math.round((param[0].fourLevelTolerance + drywater[1]) * 10) / 10) + unit
          } else {
            value = '蓝色：' + (Math.round((param[0].fourLevelTolerance + drywater[1]) * 10) / 10) + unit
          }
        }
      }else if (param && param[0]) {
        if (param[0].controlData && param[0].controlData) {
          value = '红色：' + param[0].controlData + unit
        }
        if (param[0].twoLevelTolerance && param[0].twoLevelTolerance) {
          if (value) {
            value = value + '，橙色：' + param[0].twoLevelTolerance + unit
          } else {
            value = '橙色：' + param[0].twoLevelTolerance + unit
          }
        }
        if (param[0].threeLevelTolerance && param[0].threeLevelTolerance) {
          if (value) {
            value = value + '，黄色：' + param[0].threeLevelTolerance + unit
          } else {
            value = '黄色：' + param[0].threeLevelTolerance + unit
          }
        }
        if (param[0].fourLevelTolerance && param[0].fourLevelTolerance) {
          if (value) {
            value = value + '，蓝色：' + param[0].fourLevelTolerance + unit
          } else {
            value = '蓝色：' + param[0].fourLevelTolerance + unit
          }
        }
      }
      return value
    },
    // 监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      this.pagination.pagesize = newSize
      this.pagination.pagenum = 1
      this.getAlarmList()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newNum) {
      this.pagination.pagenum = newNum
      this.getAlarmList()
    }
  },
  computed: {
    ...mapState({
      projectId: state => state.projectId,
      monitorType: state => state.monitorType,
      unit: state => state.unit
    })
  },
  watch: {
    monitorType: {
      handler: function(newValue, oldValue) {
        this.formInline.selectValue = newValue[0]
      },
      immediate: true
    },
    'formInline.selectValue': {
      handler: 'loadData',
      immediate: true
    },
    'formInline.monitorPointer'() {
      this.pagination.pagenum = 1
      this.getAlarmList()
    }
  },
  components: {
    Chart: Chart
  }
}
</script>
<style lang="less">
.dataQuery {
  width: 100%;
  height: 100%;
  // 列表样式
  .header {
    position: relative;
    margin-top: 0.24rem;
    width: 100%;
    height: 0.28rem;
    .bar {
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 28px;
      background: #3f51b5;
      opacity: 1;
      border-radius: 4px;
    }
    .title {
      position: absolute;
      left: 24px;
      top: 0;
      height: 28px;
      line-height: 28px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      opacity: 1;
    }
  }
  .content {
    width: 95%;
    margin: 20px auto 0;
    // tab栏选中的样式
    .el-tabs__nav .is-active {
      background: #fff;
      color: #3f51b5;
    }
    // tab栏未选中的样式
    .el-tabs__nav div {
      background: rgba(0, 0, 0, 0.04);
      color: #666;
    }
    .pagination {
      margin-bottom: 5px;
      text-align: right;
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #666;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #666;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #666;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #666;
    }
    // 列表样式
    // 图表样式
    .weekRadio {
      margin: 24px 0px 0px 24px;
    }
    .chartWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .chartTitle {
        width: 100%;
        height: 25px;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        color: #333333;
      }
      .chartType {
        width: 100%;
        top: 20px;
        right: 10%;
        background-size: 24px;
        background-position: center left;
        background-repeat: no-repeat;
        cursor: pointer;
        span {
          height: 22px;
          line-height: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
        }
      }
      .chart {
        margin-top: 16px;
        height: calc(100vh - 400px);
      }
    }
  }

  .el-form-item {
    margin-right: 30px;
  }

  .el-form-item__label {
    color: #333;
  }

  .el-button--primary {
    background-color: #3f51b5;
    border-color: #3f51b5;
    width: 116px;
    opacity: 1;
    border-radius: 2px;
  }

  .el-table__header .cell {
    color: #333;
  }
  .el-table__row .cell {
    color: #333;
    .告警 {
      color: #ff4343;
    }
  }
  .el-input__inner .el-range-separator {
    color: #ccc;
  }
  // 分页样式
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #3f51b5;
    color: #fff;
  }

  .el-pagination.is-background .el-pager .number:hover {
    color: #3f51b5;
  }
}
</style>
