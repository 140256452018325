<template>
  <div class="multiPoint">
    <div class="title">多测点分析</div>
    <div class="operate">
      <div class="operateItem">
        <span>分析指标:</span>
        <div>
          <el-select v-model="selectItemValue1" placeholder="请选择">
            <el-option v-for="item in selectItemOption" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </div>
      </div>
      <div class="operateItem">
        <span>分组选择:</span>
        <div>
          <el-select v-model="selectedGroup" placeholder="请选择">
            <el-option v-for="item in visibleGroupOption" :key="item.pointGroupId" :label="item.groupName" :value="item"> </el-option>
          </el-select>
        </div>
      </div>
      <div class="operateItem">
        <span>时间:</span>
        <div>
          <el-date-picker v-model="timeItemValue" type="date" :clearable="false"> </el-date-picker>
        </div>
      </div>
      <div class="operateItem">
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </div>
      <div class="operateItem" v-if="isSuperKey">
        <router-link to="/home/point-group-manage">
          <el-button type="primary">管理</el-button>
        </router-link>
      </div>
    </div>
    <div class="wettingLineWrapper" v-if="selectItemValue1 === '浸润线'">
      <WettingLine :image-path="this.selectedGroup ? this.selectedGroup.imagePath : ''" :data="data" />
    </div>
    <div class="displaceLineWrapper" v-if="selectItemValue1 === '内部位移线'">
      <DisplaceLine :data="data" />
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import dayjs from 'dayjs'
import { getAllPointGroup, getMonitorData } from '@/api'
import WettingLine from './wetting-line'
import DisplaceLine from './displace-line'

export default {
  data() {
    return {
      selectItemOption: ['浸润线'],
      selectItemValue1: '浸润线',
      groupOption: [],
      selectedGroup: null,
      timeItemValue: dayjs(),
      data: [],
      isSuperKey: false
    }
  },
  computed: {
    visibleGroupOption() {
      return this.groupOption.filter(g => {
        return g.itemName === this.selectItemValue1
      })
    }
  },
  methods: {
    async loadGroups() {
      try {
        this.groupOption = []
        this.groupOption = await getAllPointGroup()
      } catch (e) {
        console.log(e)
      }
    },
    handleSearch() {
      if (!this.selectedGroup) {
        this.$message.error('请先选择分组')
        return
      }
      const params = {
        beginTime: dayjs(this.timeItemValue).format('YYYY-MM-DD') + ' 00:00:00',
        endTime: dayjs(this.timeItemValue).format('YYYY-MM-DD') + ' 23:59:59',
        deviceIds: this.selectedGroup.monitorPoints.map(({ deviceId }) => deviceId).join(',')
      }
      getMonitorData(params)
        .then(data => {
          this.data = data
        })
        .catch(e => {
          this.$message.error('查询失败')
          console.log(e)
        })
    }
  },
  mounted() {
    this.loadGroups()
    this.timer = setInterval(() => {
      this.handleSearch()
    }, this.updateTime)
    window.document.addEventListener('keydown', event => {
      if (event.isComposing || event.keyCode === 229) {
        return
      } else if (event.keyCode === 77) {
        this.isSuperKey = true
        setTimeout(() => {
          this.isSuperKey = false
        }, 1000)
      }
    })
  },
  destroyed() {
    clearInterval(this.timer)
  },
  watch: {
    selectItemValue1: function() {
      this.selectedGroup = undefined
    }
  },
  components: {
    WettingLine,
    DisplaceLine
  }
}
</script>
<style lang="less" scoped>
.multiPoint {
  width: 100%;
  height: 100%;

  .title {
    display: block;
    position: relative;
    padding: 0.24rem 0px 20px 24px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;

    &::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 0.24rem;
      width: 4px;
      height: 24px;
      background: #3f51b5;
      border-radius: 4px;
    }
  }
}

.operate {
  display: flex;
  justify-content: flex-start;

  .operateItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 24px;

    span {
      margin-right: 8px;
    }
  }
}

.wettingLineWrapper {
  box-sizing: border-box;
  padding: 50px 30px 0px;
  width: 100%;
}

.displaceLineWrapper {
  box-sizing: border-box;
  padding: 50px 30px 0px;
  width: 100%;
}
</style>
