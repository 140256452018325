<template>
  <div class="wettingLineBox">
    <div class="chart" ref="chart"></div>
  </div>
</template>
<script>
/* eslint-disable */
import dayjs from 'dayjs'
import echarts from 'echarts'
const option = {
  color: ['#52C41A', '#FF9C2A', '#FF6363'],
  animation: false,
  legend: {
    show: true,
    textStyle: {
      color: '#FFFFFF'
    }
  },
  toolbox: {
    feature: {
      magicType: {
        show: true
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    confine: true,
    backgroundColor: 'rgba(0,0,0,0.3)',
    formatter: function(param) {
      // var str = param[0].value[0] + '<br />' + param[0].marker + param[0].seriesName + '：' + param[0].value[1] + 'mm' + '<br />' + param[1].marker + param[1].seriesName + '：' + param[1].value[1] + 'mm' + '<br />' + param[2].marker + param[2].seriesName + '：' + param[2].value[1] + 'mm'
      
      var str = param[0].value[0]+ '<br />' + param[0].marker + param[0].seriesName + '：' + param[0].value[1] + 'mm'
      if (param.length == 2) {
        str = param[0].value[0]+ '<br />' + param[0].marker + param[0].seriesName + '：' + param[0].value[1] + 'mm' + '<br />' + param[1].marker + param[1].seriesName + '：' + param[1].value[1] + 'mm'
      }
      if (param.length == 3) {
        str = param[0].value[0]+ '<br />' + param[0].marker + param[0].seriesName + '：' + param[0].value[1] + 'mm' + '<br />' + param[1].marker + param[1].seriesName + '：' + param[1].value[1] + 'mm' + '<br />' + param[2].marker + param[2].seriesName + '：' + param[2].value[1] + 'mm'
      }
      if (param.length == 4) {
        str = param[0].value[0]+ '<br />' + param[0].marker + param[0].seriesName + '：' + param[0].value[1] + 'mm' + '<br />' + param[1].marker + param[1].seriesName + '：' + param[1].value[1] + 'mm' + '<br />' + param[2].marker + param[2].seriesName + '：' + param[2].value[1] + 'mm' + '<br />' + param[3].marker + param[3].seriesName + '：' + param[3].value[1] + 'mm'
      }
      return str
    }
  },
  grid: {
    top: '30',
    bottom: '10',
    left: '10',
    right: '10',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    axisPointer: {
      show: true,
      lineStyle: {
        type: 'dotted'
      },
      label: {
        show: false
      }
    },
    axisLine: {
      onZero: false,
      lineStyle: {
        color: '#4759B8',
        opacity: 1
      },
      symbol: ['none', 'none']
    },
    axisTick: {
      // show: true,
      interval: 'auto',
      lineStyle: {
        color: '#000000',
        opacity: 0.25
      }
    },
    axisLabel: {
      color: '#C9C9C9',
      fontSize: '12',
      showMinLabel: false,
      showMaxLabel: false
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: {
      show: true,
      lineStyle: {
        type: 'dotted'
      },
      label: {
        show: false
      },
      triggerTooltip: false
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dotted',
        width: '1.5',
        color: '#4759B8',
        opacity: '1'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: '#C9C9C9',
      fontSize: '12',
      showMaxLabel: true,
      formatter: '{value} mm'
    },
    axisLine: {
      onZero: false,
      lineStyle: {
        color: '#4759B8',
        opacity: 1
      },
      symbol: ['none', 'none']
    }
  },
  series: []
}
export default {
  props: {
    data: Array
  },
  data() {
    return {
      formInline: {
        selectValue: undefined,
        monitorPointer: undefined,
        selectItemValue: 'values',
        timeItemValue: [
          dayjs()
            .subtract(24, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          dayjs().format('YYYY-MM-DD HH:mm:ss')
        ]
      },
      chart: null,
      monitorPointData: []
    }
  },
  mounted() {
    this.initChart()
    this.updateChart()
  },
  methods: {
    initChart() {
      const chart = echarts.init(this.$refs.chart)
      chart.setOption(option)
      this.chart = chart
    },
    updateChart() {
      if (!this.chart) this.initChart()
      const series = []
      this.data.forEach(({ dataList, thresholdData, pointName }, index) => {
        if (!dataList || dataList.length === 0) {
          this.chart.showLoading({
            text: '暂无数据',
            fontSize: 50,
            color: '#20236A',
            textColor: '#C9C9C9',
            maskColor: 'radial-gradient(#409EFF,#271055)'
          })
          return
        }
        this.chart.hideLoading()
        const seriesData = []
        dataList.forEach(data => {
          const key = dayjs(data.time).format('HH:mm')
          seriesData.push([key, data[this.formInline.selectItemValue] ? data[this.formInline.selectItemValue] : []])
        })
        seriesData[0][1].forEach((item, index) => {
          series.push({
            name: ['x', 'y', 'z'][index] + '方向位移',
            type: 'line',
            symbolSize: 6,
            smooth: false,
            lineStyle: {
              width: 2
            },
            data: seriesData.map(v => [v[0], v[1][index]])
          })
        })
      })
      this.chart.setOption(
        {
          ...option,
          series
        },
        true
      )
    }
  },
  watch: {
    data() {
      this.updateChart()
    }
  }
}
</script>

<style lang="less" scoped>
.wettingLineBox {
  width: 100%;
  height: calc((100vh - 155px - 2.9rem) / 2);
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  align-items: center;
  flex: 1;
  opacity: 1;

  .chart {
    width: 95%;
    height: 100%;
    z-index: 1;
  }
}
</style>
