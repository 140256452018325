import axios from 'axios'
import env from '@/env'

const instance = axios.create({
  baseURL: env.API_BASE_URL
})

instance.interceptors.response.use(resp => {
  const { result, message } = resp.data
  if (result === 1) return resp.data
  const dataError = new Error(message)
  dataError.data = resp.data
  dataError.result = result
  throw dataError
}, error => {
  const rejectError = new Error(error.message)
  if (error.response && error.response.data) {
    const { result } = error.response.data
    rejectError.result = result
    rejectError.data = error.response.data
  }
  return Promise.reject(rejectError)
})
export default instance
