/*
 * @Author: your name
 * @Date: 2021-07-10 07:57:42
 * @LastEditTime: 2022-05-12 10:33:58
 * @LastEditors: zhyou zhouyou2041@zdjtcm.com
 * @Description: In User Settings Edit
 * @FilePath: \tailings_pond_client_devd:\Codes\zhongyun\xiling_pond_fe_master\xiling-pond-template\src\store\index.js
 */
/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    projectId: null,
    monitorType: [],
    unit: [],
  },
  getters: {
    projectId: state => state.projectId,
    monitorType: state => state.monitorType,
    unit: state => state.unit,
  },
  mutations: {
    UPDATE_PROJECT_ID(state, { projectId }) {
      state.projectId = projectId
    },
    UPDATE_MONITOR_TYPE(state, { monitorType }) {
      state.monitorType = monitorType
    },
    UPDATE_UNIT(state, { unit }) {
      state.unit = unit
    },
  },
  actions: {
    async GET_PROJECT_INFO({ commit, dispatch }) {
      axios.get('/SiteController/getAll').then(res => {
        console.log('dmc GET_PROJECT_INFO：',res)
        const { dataList } = res.data
        commit('UPDATE_PROJECT_ID', {
          projectId: dataList[0].siteId
        })
        dispatch('GET_MONITOR_TYPE', { id: dataList[0].siteId })
        dispatch('GET_UNIT', { id: dataList[0].siteId })
      })
    },
    async GET_MONITOR_TYPE({ commit }, { id }) {
      axios.get(`/monitorPoint/monitorType/${id}`).then(res => {
        console.log('dmc GET_MONITOR_TYPE：',res)
        const { data } = res.data
        commit('UPDATE_MONITOR_TYPE', {
          monitorType: data
        })
      })
    },
    async GET_UNIT({ commit }, { id }) {
      axios.post(`/zy/monitor?projectId=${id}`).then(res => {
        console.log('dmc GET_UNIT',res)
        const { data } = res.data
        commit('UPDATE_UNIT', {
          unit: data
        })
      })
    }
  }
})
