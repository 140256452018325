<!--
 * @Author: your name
 * @Date: 2021-07-10 07:57:42
 * @LastEditTime: 2021-07-15 17:27:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \tailings_pond_client_devd:\Codes\zhongyun\xiling_pond_fe_master\xiling-pond-template\src\pages\point-map\index.vue
-->
<template>
  <div class="pointMap">
    <div class="header">
      <div class="bar"></div>
      <div class="title">布点图</div>
    </div>
    <div class="content">
        <el-image style="height: calc(100vh - 3.5rem)" :src="imgSrc" :fit='fit' :preview-src-list="srcList">
      </el-image>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

  
export default {
  data() {
    return {
      fit: 'contain',
      imgSrc: require(`./1.jpg`),
      srcList:[require(`./1.jpg`)]
    }
  },
}
</script>
<style lang="less" scoped>
.pointMap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-items: center;

  .header {
    position: relative;
    margin-top: 0.24rem;
    width: 100%;
    height: 28px;

    .bar {
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 28px;
      background: #3f51b5;
      opacity: 1;
      border-radius: 4px;
    }

    .title {
      position: absolute;
      left: 24px;
      top: 0;
      height: 28px;
      line-height: 28px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      opacity: 1;
    }
  }

  .content {
    width: 95%;
    height: calc(100vh - 3.2rem);
    margin: 0.24rem auto 0;
    display:table-cell;
    text-align:center;
    vertical-align：middle
    .el-image{
      height:calc(100vh - 3.5rem)
    }
  }
}
</style>
