/*
 * @Author: your name
 * @Date: 2021-03-04 09:23:38
 * @LastEditTime: 2022-11-14 10:42:55
 * @LastEditors: zhyou zhouyou2041@zdjtcm.com
 * @Description: In User Settings Edit
 * @FilePath: \tailings-pond-clientd:\Codes\zhongyun\xiling_pond_fe_master\xiling-pond-fe\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import RealTimeMonitor from '../pages/real-time-monitor'
import ComplexAnalyse from '../pages/complex-analyse'
import DataReset from '../pages/data-reset'
import AlarmManage from '../pages/alarm-manage'
import videoMonitor from '../pages/video-monitor'
import DataQuery from '../pages/data-query'
// 数据分析下的三个子组件功能  数据对比 多测点分析 关联分析
import DataCompared from '../pages/data-compared'
import MultiPoint from '../pages/multi-point'
import AssociationAnalysis from '../pages/association-analysis'
import PointMap from '../pages/point-map'
import GroupManage from '@/pages/multi-point/GroupManage'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
    {
      path: '/home',
      component: Home,
      redirect: '/home/real-time-monitor',
      children: [
        { path: 'real-time-monitor', component: RealTimeMonitor },
        { path: 'data-reset', component: DataReset },
        { path: 'complex-analyse', component: ComplexAnalyse },
        { path: 'alarm-manage', component: AlarmManage },
        { path: 'video-monitor', component: videoMonitor },
        { path: 'data-query', component: DataQuery },
        { path: 'data-compared', component: DataCompared },
        { path: 'multi-point', component: MultiPoint },
        { path: 'point-group-manage', component: GroupManage },
        { path: 'association-analysis', component: AssociationAnalysis },
        { path: 'point-map', component: PointMap }
      ]
    }
  ]
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // 如果用户访问的是登录页， 直接放行
  if (to.path === '/login') return next()
  // 从 sessionStorage 中获取到保存的token值
  const tokenStr = window.sessionStorage.getItem('token')
  // 没有token, 强制跳转到登录页
  if (!tokenStr) return next('/login')
  next()
})

export default router
