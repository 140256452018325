/*
 * @Author: your name
 * @Date: 2021-07-10 07:57:41
 * @LastEditTime: 2021-07-11 18:49:21
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \tailings_pond_client_devd:\Codes\zhongyun\xiling_pond_fe_master\xiling-pond-template\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import env from './env'
// import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/theme/index.css'
// 导入全局样式表
import './assets/css/global.css'

import axios from 'axios'
// 配置请求的根路径
axios.defaults.baseURL = env.API_BASE_URL
Vue.prototype.$http = axios
Vue.prototype.updateTime = env.updateTime

Vue.config.productionTip = false

Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
