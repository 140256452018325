<template>
  <div class="wettingLineBox">
    <div class="chart" ref="chart"></div>
  </div>
</template>
<script>
/* eslint-disable */
import dayjs from 'dayjs'
import echarts from 'echarts'

const faker = ['1.61','1.6','1.61','1.6','1.61','1.62','1.61','1.60','1.61','1.62']
const option = {
  color: ['#A4B1FF', '#FF9C2A', '#FF6363'],
  animation: false,
  legend: {
    show: true,
    selected: {
      实时值: true,
      四级告警: true,
      三级告警: false,
      二级告警: false,
      一级告警: false
    },
    textStyle: {
      color: '#FFFFFF'
    }
  },
  // legend: {
  //   show: true,
  //   selected: {
  //     实时值: true,
  //     三级告警: true,
  //     二级告警: false,
  //     一级告警: false
  //   },
  //   textStyle: {
  //     color: '#FFFFFF'
  //   }
  // },
  toolbox: {
    feature: {
      magicType: {
        show: true
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    confine: true,
    backgroundColor: 'rgba(0,0,0,0.3)',
    formatter: function(param) {
      var str = dayjs(param[0].value[0]).format('HH:ss') + '<br />' + param[0].marker + param[0].seriesName + '：' + param[0].value[1] + 'm'
      if (param.length == 2) {
        str = dayjs(param[0].value[0]).format('HH:ss') + '<br />' + param[0].marker + param[0].seriesName + '：' + param[0].value[1] + 'm' + '<br />' + param[1].marker + param[1].seriesName + '：' + param[1].value[1] + 'm'
      }
      if (param.length == 3) {
        str = dayjs(param[0].value[0]).format('HH:ss') + '<br />' + param[0].marker + param[0].seriesName + '：' + param[0].value[1] + 'm' + '<br />' + param[1].marker + param[1].seriesName + '：' + param[1].value[1] + 'm' + '<br />' + param[2].marker + param[2].seriesName + '：' + param[2].value[1] + 'm'
      }
      if (param.length == 4) {
        str = dayjs(param[0].value[0]).format('HH:ss') + '<br />' + param[0].marker + param[0].seriesName + '：' + param[0].value[1] + 'm' + '<br />' + param[1].marker + param[1].seriesName + '：' + param[1].value[1] + 'm' + '<br />' + param[2].marker + param[2].seriesName + '：' + param[2].value[1] + 'm' + '<br />' + param[3].marker + param[3].seriesName + '：' + param[3].value[1] + 'm'
      }
      if (param.length == 5) {
        str = dayjs(param[0].value[0]).format('HH:ss') + '<br />' + param[0].marker + param[0].seriesName + '：' + param[0].value[1] + 'm' + '<br />' + param[1].marker + param[1].seriesName + '：' + param[1].value[1] + 'm' + '<br />' + param[2].marker + param[2].seriesName + '：' + param[2].value[1] + 'm' + '<br />' + param[3].marker + param[3].seriesName + '：' + param[3].value[1] + 'm' + '<br />' + param[4].marker + param[4].seriesName + '：' + param[4].value[1] + 'm'
      }
      return str
    }
  },
  grid: {
    top: '30',
    bottom: '10',
    left: '10',
    right: '10',
    containLabel: true
  },
  xAxis: {
    type: 'time',
    boundaryGap: true,
    splitLine: {
      show: false
    },
    axisPointer: {
      show: true,
      lineStyle: {
        type: 'dotted'
      },
      label: {
        show: false
      }
    },
    axisLine: {
      onZero: false,
      lineStyle: {
        color: '#4759B8',
        opacity: 1
      },
      symbol: ['none', 'none']
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#000000',
        opacity: 0.25
      }
    },
    axisLabel: {
      color: '#C9C9C9',
      fontSize: '12',
      showMinLabel: false,
      showMaxLabel: false,
      formatter: function(value) {
        return dayjs(value).format('HH:00')
      }
    },
    interval: 3*60*60*1000
  },
  yAxis: {
    type: 'value',
    axisPointer: {
      show: true,
      lineStyle: {
        type: 'dotted'
      },
      label: {
        show: false
      },
      triggerTooltip: false
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dotted',
        width: '1.5',
        color: '#4759B8',
        opacity: '1'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: '#C9C9C9',
      fontSize: '12',
      showMaxLabel: true,
      formatter: '{value} m'
    },
    axisLine: {
      onZero: false,
      lineStyle: {
        color: '#4759B8',
        opacity: 1
      },
      symbol: ['none', 'none']
    }
  },
  series: []
}
export default {
  props: {
    data: Array
  },
  data() {
    return {
      formInline: {
        selectValue: undefined,
        monitorPointer: undefined,
        selectItemValue: 'values',
        timeItemValue: [
          dayjs()
            .subtract(24, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          dayjs().format('YYYY-MM-DD HH:mm:ss')
        ]
      },
      chart: null,
      monitorPointData: []
    }
  },
  mounted() {
    this.initChart()
    this.updateChart()
  },
  methods: {
    initChart() {
      const chart = echarts.init(this.$refs.chart)
      chart.setOption(option)
      this.chart = chart
    },
    updateChart() {
      if (!this.chart) this.initChart()
      const series = []
      this.data.forEach(({ dataList, thresholdData, pointName }, index) => {
        if (!dataList || dataList.length === 0) {
          this.chart.showLoading({
            text: '暂无数据',
            fontSize: 50,
            color: '#20236A',
            textColor: '#C9C9C9',
            maskColor: 'radial-gradient(#409EFF,#271055)'
          })
          return
        }
        this.chart.hideLoading()
        const seriesData = []
        let value  = faker[dayjs().format('YYYY-MM-DD').charAt(dayjs().format('YYYY-MM-DD').length - 1)]
        dataList.forEach(data => {
          const key = dayjs(data.time).format('YYYY-MM-DD HH:mm:ss')
          // seriesData.push([key, data[this.formInline.selectItemValue] ? data[this.formInline.selectItemValue] : []])

          seriesData.push([key, data[this.formInline.selectItemValue] ? [value] : []])
        })
        seriesData[0][1].forEach((item, index) => {
          series.push(
            {
              type: 'line',
              name: '实时值',
              color: '#A4B1FF',
              symbolSize: 0,
              smooth: false,
              lineStyle: {
                width: 2
              },
              data: seriesData.map(v => [v[0], v[1][index]])
            },
            {
              type: 'line',
              visible: true,
              name: '四级告警',
              color: 'blue',
              symbolSize: 0,
              smooth: false,
              lineStyle: {
                width: 2
              },
              data: seriesData.map(v => [v[0], this.data[0].fourLevel])
            },
            {
              type: 'line',
              visible: true,
              name: '三级告警',
              color: '#FFFF00',
              symbolSize: 0,
              smooth: false,
              lineStyle: {
                width: 2
              },
              data: seriesData.map(v => [v[0], this.data[0].threeLevel])
            },
            {
              type: 'line',
              visible: false,
              name: '二级告警',
              color: '#FF7C03',
              symbolSize: 0,
              smooth: false,
              lineStyle: {
                width: 2
              },
              data: seriesData.map(v => [v[0], this.data[0].twoLevel])
            },
            {
              type: 'line',
              visible: false,
              name: '一级告警',
              color: 'red',
              symbolSize: 0,
              smooth: false,
              lineStyle: {
                width: 2
              },
              data: seriesData.map(v => [v[0], this.data[0].oneLevel])
            }
          )
        })
      })
      this.chart.setOption(
        {
          ...option,
          series
        },
        true
      )
    }
  },
  watch: {
    data() {
      this.updateChart()
    }
  }
}
</script>

<style lang="less" scoped>
.wettingLineBox {
  width: 100%;
  height: calc((100vh - 155px - 2.9rem) / 2);
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  align-items:center;
  flex: 1;
  opacity: 1;

  .chart {
    width: 95%;
    height: 100%;
    z-index: 1;
  }
}
</style>
