<template>
  <div class="dataCompared">
    <div class="title">数据对比</div>
    <div class="operate">
      <div class="operateItem">
        <el-button @click="pointSelectVisible = true" type="primary" plain>测点选择</el-button>
      </div>
      <div class="operateItem">
        <span>监测因子:</span>
        <div>
          <el-select v-model="selectAxis" placeholder="请选择">
            <el-option v-for="item in axiosOptions" :key="item.label" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </div>
      </div>
      <div class="operateItem">
        <span>数值类型:</span>
        <div>
          <el-select v-model="selectItemValue" placeholder="请选择">
            <el-option value="values" label="实时值" />
            <el-option value="totalValues" label="累计值" />
          </el-select>
        </div>
      </div>
      <div class="operateItem">
        <span>起止时间:</span>
        <div>
          <el-date-picker v-model="timeItemValue" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="→" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
        </div>
      </div>
      <div class="operateItem">
        <el-button type="primary" @click="search">查询</el-button>
      </div>
    </div>
    <div class="chartWrapper">
      <div class="chartTitle">{{ chartTitle }}</div>
      <div class="chart" ref="chart"></div>
      <div class="chartType" :style="{ backgroundImage: this.chartType === 'line' ? `url(${require('./assets/bar.png')})` : `url(${require('./assets/line.png')})` }" @click="changeChartType">
        <span>{{ this.chartType === 'bar' ? '折线图' : '柱状图' }}</span>
      </div>
    </div>

    <PointSelector :visible.sync="pointSelectVisible" @ok="handlePointSelect" @close="pointSelectVisible = false" />
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import echarts from 'echarts'
import dayjs from 'dayjs'
import PointSelector from '@/components/PointSelector'
import { getMonitorData } from '@/api'

const TIME_FORMATTER = 'YYYY-MM-DD HH:mm:ss'

let defaultOption = {
  color: ['#3F51B5', '#FF9C2A', 'red', 'green', 'blue'],
  animation: false,
  legend: {
    show: true
  },
  toolbox: {
    feature: {
      magicType: {
        show: true
      }
    }
  },
  tooltip: {
    show: true,
    trigger: 'axis',
    confine: true,
    backgroundColor: 'rgba(0,0,0,0.3)',
    formatter: function(val) {
      return `<div>
        <span>${val[0].axisValueLabel}</span><br/>
        ${val[0].marker}
        <span>${val[0].seriesName}: ${val[0].value[1]}</span>
      </div>`
    }
  },
  grid: {
    top: '30',
    bottom: '10',
    left: '10',
    right: '10',
    containLabel: true
  },
  xAxis: {
    type: 'time',
    boundaryGap: true,
    axisPointer: {
      show: true,
      lineStyle: {
        type: 'dotted'
      },
      label: {
        show: false
      }
    },
    axisLine: {
      onZero: false,
      lineStyle: {
        color: '#000000',
        opacity: 0.25
      },
      symbol: ['none', 'none']
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#000000',
        opacity: 0.25
      }
    },
    axisLabel: {
      color: 'rgba(0,0,0,0.65)',
      fontSize: '12',
      showMinLabel: false,
      showMaxLabel: false
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: {
      show: true,
      lineStyle: {
        type: 'dotted'
      },
      label: {
        show: false
      },
      triggerTooltip: false
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dotted',
        width: '1.5',
        color: '#BFBFBF',
        opacity: '0.2'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: 'rgba(0,0,0,0.45)',
      fontSize: '12',
      showMaxLabel: true
    },
    axisLine: {
      onZero: false,
      lineStyle: {
        color: '#000000',
        opacity: 0.25
      },
      symbol: ['none', 'none']
    }
  },
  series: [
    {
      name: '',
      type: 'line',
      smooth: false,
      showSymbol: true,
      lineStyle: { width: 2 },
      z: 2,
      data: [],
      barWidth: '25%'
    }
  ]
}
export default {
  components: { PointSelector },
  watch: {
    weekItemValue(val) {
      switch (val) {
        case '日':
          this.timeItemValue = [
            dayjs()
              .subtract(24, 'hour')
              .format(TIME_FORMATTER),
            dayjs().format(TIME_FORMATTER)
          ]
          break
        case '周':
          this.timeItemValue = [
            dayjs()
              .subtract(7, 'day')
              .format(TIME_FORMATTER),
            dayjs().format(TIME_FORMATTER)
          ]
          break
        case '月':
          this.timeItemValue = [
            dayjs()
              .subtract(30, 'day')
              .format(TIME_FORMATTER),
            dayjs().format(TIME_FORMATTER)
          ]
      }
    },
    chartType() {
      this.updateChart()
    }
  },
  computed: {
    ...mapGetters(['monitorType', 'projectId', 'unit'])
  },
  data() {
    return {
      rainFactor: 1,
      selectAxis: 0,
      selectItemValue: 'values',
      axiosOptions: [
        { label: 'X轴', value: 0 },
        { label: 'Y轴', value: 1 },
        { label: 'Z轴', value: 2 }
      ],
      timeItemValue: [
        dayjs()
          .subtract(24, 'hour')
          .format(TIME_FORMATTER),
        dayjs().format(TIME_FORMATTER)
      ],
      weekItemValue: '日',
      chartType: 'line',
      pointSelectVisible: false,
      selectedPoints: [],
      data: [],
      chartTitle: '测点数据趋势图'
    }
  },
  methods: {
    initChart() {
      const chart = echarts.init(this.$refs.chart)
      chart.setOption(defaultOption)
      this.chart = chart
    },
    changeChartType() {
      this.chartType === 'line' ? (this.chartType = 'bar') : (this.chartType = 'line')
    },
    handlePointSelect(points) {
      this.selectedPoints = points
      this.pointSelectVisible = false
    },
    async search() {
      // 需要根据我们的测点数组来获取我们的响应数组
      const timeDiff = dayjs(this.timeItemValue[1]).diff(this.timeItemValue[0], 'day')
      if (timeDiff > 2) {
        this.$message({
          message: '时间范围超过2天',
          type: 'warning'
        })
        return
      }
      // 必须选择左边的测点
      if (this.selectedPoints.length === 0) {
        return this.$message.warning('请选择左边的测点')
      }
      try {
        // 请求的参数对象
        const params = {
          deviceIds: this.selectedPoints.map(({ deviceId }) => deviceId).join(','),
          beginTime: this.timeItemValue[0],
          endTime: this.timeItemValue[1],
          siteNo: this.projectId
        }
        // 发送请求获取数据
        this.data = await getMonitorData(params)
        // 得到数据后更新echarts表格展示数据
        this.updateChart()
      } catch (e) {
        console.log(e)
      }
    },
    updateChart() {
      // 进行echart初始化, 渲染之前必须初始化
      if (!this.chart) this.initChart()
      let unit
      const series = []
      this.data?.forEach(({ dataList, thresholdData, pointName, monitorItemName }, index) => {
        const seriesData = []
        // 存放告警值的数组
        const threshold = []
        dataList.forEach(data => {
          // 将日期进行格式化处理   日期格式不对的话， time展示不出来
          const key = dayjs(data.time).format('YYYY-MM-DD HH:mm:ss')
          var realValue = data[this.selectItemValue] ? data[this.selectItemValue][this.selectAxis] : null
          if ('降雨量' == this.data[0].monitorItemName) {
            const unit = this.unit.filter(v => '降雨量' == v.itemName)[0].itemUnit
            let len = 0
            const rainFactor = this.unit.filter(v => '降雨量' == v.itemName)[0].itemFactor
            if (rainFactor.toString().split('.')[1]) {
              len += rainFactor.toString().split('.')[1].length
            }
            if (data[this.selectItemValue][this.selectAxis]) {
              if (data[this.selectItemValue][this.selectAxis].toString().split('.')[1]) {
                len += data[this.selectItemValue][this.selectAxis].toString().split('.')[1].length
              }
              const numValue = (Number(rainFactor.toString().replace('.', '')) * Number(data[this.selectItemValue][this.selectAxis].toString().replace('.', ''))) / Math.pow(10, len)
              realValue = numValue
            }
          }
          // 将echart中series中要得到的数据放进去  selectItemValue代表不同的数值类型   selectAxis代表不同的监测因子
          seriesData.push([key, realValue])
          if (thresholdData[index]) {
            threshold.push([key, thresholdData[index].controlData])
          }
        })
        this.chartTitle = monitorItemName + '趋势对比'
        // 根据monitorItemName来判断单位
        if (monitorItemName == '坝体内部水平位移') {
          unit = 'mm'
        } else if (monitorItemName == '降雨量') {
          unit = 'mm'
        } else if (monitorItemName == '干滩高程') {
          unit = 'm'
        } else if (monitorItemName == '坝体表面水平位移') {
          unit = 'mm'
        } else if (monitorItemName == '浸润线埋深') {
          unit = 'mm'
        } else if (monitorItemName == '库水位') {
          unit = 'm'
        } else if (monitorItemName == '安全超高') {
          unit = 'm'
        } else if (monitorItemName == '渗流量') {
          unit = 'mm'
        }
        series.push({
          name: pointName,
          type: this.chartType,
          lineStyle: { width: 2 },
          data: seriesData,
          barWidth: '25%'
        })
        if (threshold.length) {
          series.push({
            name: pointName + ' ' + '告警值',
            type: 'line',
            itemStyle: {
              color: '#ff6363'
            },
            lineStyle: {
              width: 2,
              color: '#ff6363'
            },
            data: threshold,
            barWidth: '25%'
          })
        }
      })
      // 第二个参数true表示不合并， 覆盖原来的series
      this.chart.setOption(
        {
          ...defaultOption,
          yAxis: {
            name: unit,
            type: 'value',
            axisPointer: {
              show: true,
              lineStyle: {
                type: 'dotted'
              },
              label: {
                show: false
              },
              triggerTooltip: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dotted',
                width: '1.5',
                color: '#BFBFBF',
                opacity: '0.2'
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: 'rgba(0,0,0,0.45)',
              fontSize: '12',
              showMaxLabel: true
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: '#000000',
                opacity: 0.25
              },
              symbol: ['none', 'none']
            }
          },
          tooltip: {
            show: true,
            trigger: 'axis',
            confine: true,
            backgroundColor: 'rgba(0,0,0,0.3)',
            formatter: function(val) {
              return `<div>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${val[0].axisValueLabel}</span><br/>
              ${val[0].marker}
              <span>${val[0].seriesName}: ${val[0].value[1]}${unit}</span>
            </div>`
            }
          },
          series
        },
        true
      )
    }
  },
  mounted() {
    this.initChart()
  }
}
</script>
<style lang="less" scoped>
.dataCompared {
  width: 100%;
  height: 100%;

  .title {
    display: block;
    position: relative;
    padding: 0.24rem 0px 20px 24px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;

    &::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 0.24rem;
      width: 4px;
      height: 24px;
      background: #3f51b5;
      border-radius: 4px;
    }
  }
}

.operate {
  display: flex;
  justify-content: flex-start;

  .operateItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 24px;

    span {
      margin-right: 8px;
    }
  }
}

.weekRadio {
  margin: 24px 0px 0px 24px;
}

.chartWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .chartTitle {
    height: 25px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #333333;
  }

  .chart {
    margin-top: 16px;
    width: 90%;
    height: calc(100vh - 155px - 1.7rem);
    // border: 1px solid #eee;
  }

  .chartType {
    position: absolute;
    top: 20px;
    right: 10%;
    padding-left: 32px;
    background-size: 24px;
    background-position: center left;
    background-repeat: no-repeat;
    cursor: pointer;

    span {
      height: 22px;
      line-height: 22px;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>
