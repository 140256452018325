<!--
 * @Author: your name
 * @Date: 2021-07-19 11:06:19
 * @LastEditTime: 2021-07-19 11:51:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \tailings_pond_client_devd:\Codes\zhongyun\xiling_pond_fe_master\xiling-pond-template\src\components\player.vue
-->
<template>
    <div class="videocontent">
        <div v-show="src" style="max-height: 780px " :id="id"></div>
        <div v-show="!src" class="videoErr">
            <span>视频信号已断开</span>
        </div>
    </div>
</template>

<script>
import WasmPlayer from '@easydarwin/easywasmplayer'
export default {
  data () {
    return {
      easyPlayer: null,
      videoUrl: this.src,
      id: 'easyPlayer' + Math.random()
    }
  },
  props: {
    src: String
  },
  mounted () {
    // http://120.52.31.17:18080/api/play/start/43010120002000000000/43010120001320014734
    // http://120.52.31.17:18080/api/play/start/43010120002000000000/43010120001320014735
    // http://120.52.31.17:18080/api/play/start/43010120002000000000/43010120001320014736
    // http://120.52.31.17:18080/api/play/start/43010120002000000000/43010120001320014737
    // http://120.52.31.17:18080/api/play/start/43010120002000000000/43010120001320014738
    // http://120.52.31.17:18080/api/play/start/43010120002000000000/43010120001320014739
    this.$nextTick(() => {
      this.easyPlayer = new WasmPlayer(null, this.id, this.eventcallbacK, {
        openAudio: false,
        Height: true
      })
      this.play(this.videoUrl)
    })
  },
  watch: {
    src (val) {
      this.easyPlayer.destroy()
      this.easyPlayer = new WasmPlayer(null, this.id, this.eventcallbacK, {
        openAudio: false,
        Height: true
      })
      this.play(val)
    },
    immediate: true
  },
  methods: {
    play (url) {
      this.easyPlayer.play(url, 1)
    },
    pause () {
      this.easyPlayer.destroy()
    },
    eventcallbacK (e) {
    }
  },
  beforeDestroy () {
    this.easyPlayer.destroy()
  }
}
</script>

<style>
    .LodingTitle {
        min-width: 70px;
    }
    /* 隐藏logo */
    /* .iconqingxiLOGO {
        display: none !important;
    } */
    .videocontent {
        width: 100%;
        height: 100%;
    }
    .player-content {
        max-height: 780px;
        height: 100%;
    }
    .videoErr {
        height: 100%;
        background-color: #000;
        color: red;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
