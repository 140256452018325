<template>
  <div class="wettingLineBox">
    <div class="chart" ref="chart"></div>
    <div class="bg" :style="{ backgroundImage: `url(${imagePath})` }"></div>
  </div>
</template>
<script>
/* eslint-disable */
import echarts from 'echarts'
const DIFF_DIS = 0
// const HOLED_EPTH = [0, 18, 18, 20]
const DIFF_VALUE = v => v
let option = {
  // backgroundColor: 'rgba(128, 128, 128, 0.1)',
  color: ['#3F51B5', '#FF6363', '#FFFF00'],
  animation: false,
  // legend: {
  //   data: ['水位', '警戒线']
  // },
  tooltip: {
    show: true,
    trigger: 'axis',
    confine: true,
    backgroundColor: 'rgba(0,0,0,0.3)',
    formatter: '孔口坐标: ({c0})'
  },
  grid: {
    top: '30',
    bottom: '10',
    left: '10',
    right: '20',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    boundaryGap: false,
    min: 0,
    max: 136,
    // interval: 20,
    axisPointer: {
      show: true,
      lineStyle: {
        type: 'dotted'
      },
      label: {
        show: false
      }
    },
    axisLine: {
      onZero: false,
      lineStyle: {
        color: '#000000',
        opacity: 0.25
      },
      symbol: ['none', 'none']
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#000000',
        opacity: 0.25
      }
    },
    axisLabel: {
      color: 'rgba(0,0,0,0.65)',
      fontSize: '12',
      formatter: '{value} m'
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dotted',
        width: '1.5',
        color: '#BFBFBF',
        opacity: '0.2'
      }
    }
  },
  yAxis: {
    type: 'value',
    scale: true,
    // name: '水位(mm)',
    // min: -25,
    // max: 100,
    // interval: 25,
    nameTextStyle: {
      color: '#999'
    },
    axisPointer: {
      show: true,
      lineStyle: {
        type: 'dotted'
      },
      label: {
        show: false
      },
      triggerTooltip: false
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dotted',
        width: '1.5',
        color: '#BFBFBF',
        opacity: '0.2'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: 'rgba(0,0,0,0.45)',
      fontSize: '12',
      showMaxLabel: true,
      formatter: '{value} m'
    },
    axisLine: {
      onZero: false,
      lineStyle: {
        color: '#000000',
        opacity: 0.25
      },
      symbol: ['none', 'none']
    }
  },
  series: [
    {
      name: '孔口标高',
      type: 'line',
      smooth: false,
      showSymbol: true,
      tooltip: {
        show: true
      },
      lineStyle: {
        width: 2
      },
      areaStyle: {
        color: '#B1B7BE'
      },
      z: 2,
      data: [
        [0, 125],
        [1.5, 125],
        [31.5, 140],
        [35.5, 140],
        [55.5, 145],
        [75.5, 150],
        [95.5, 155],
        [115.5, 160],
        [135.5, 165]
      ].map(item => [item[0], item[1] + DIFF_DIS])
    },
    {
      name: '水位',
      type: 'line',
      smooth: true,
      showSymbol: true,
      lineStyle: {
        width: 2
      },
      z: 2,
      data: []
    }
    // {
    //   name: '警戒线',
    //   type: 'line',
    //   smooth: true,
    //   showSymbol: true,
    //   lineStyle: {
    //     width: 2
    //   },
    //   tooltip: {
    //     show: false
    //   },
    //   z: 2
    // }
  ]
}
export default {
  props: {
    data: Array,
    imagePath: String
  },
  data() {
    return {
      chart: null
    }
  },
  methods: {
    initChart() {
      const chart = echarts.init(this.$refs.chart)
      chart.setOption(option)
      this.chart = chart
    },
    updateChart() {
      if (!this.chart) this.initChart()
      let value = [0]
      let alarmValue = [0, 0, 0]
      let thresholdData = []
      this.data.forEach((item, index) => {
        value[index + 1] = DIFF_VALUE(item.dataList[0]?.values[0])
        if (item.thresholdData.lenght > 0) {
          thresholdData[index] = item.thresholdData
        }
        if (item.oneLevel) {
          alarmValue[0] = item.oneLevel
        }
        if (item.twoLevel) {
          alarmValue[1] = item.twoLevel
        }
        if (item.threeLevel) {
          alarmValue[2] = item.threeLevel
        }
      })
      //调试代码
      this.data.forEach((item, index) => {
        if (2 == index) {
          value[index + 2] = DIFF_VALUE(item.dataList[0]?.values[0])
        }
      })
      // const [a, b, c, d] = [...value]
      // value = [a, d, c, b]
      let option = this.chart.getOption()
      option.series[2] = {
        name: '水位',
        type: 'line',
        smooth: true,
        showSymbol: true,
        lineStyle: {
          width: 2
        },
        z: 2,
        data: [
          [1.5,125],
          [55.5, 145 - value[3]],
          [75.5, 150 - value[2]],
          [95.5, 155 - value[1]],
          [160, 155]
        ],
        markLine: {
          silent: true,
          symbol: ['arrow', 'arrow'],
          lineStyle: {
            normal: {
              type: 'solid',
              color: '#000'
            }
          },
          label: {
            normal: {
              show: true,
              position: 'middle'
            }
          },
          data: [
            [
              {
                name: value[4] + 'm',
                coord: [55.5, 145 - value[3]]
              },
              {
                coord: [55.5, 145]
              }
            ],
            [
              {
                name: value[3] + 'm',
                coord: [75.5, 150 - value[2]]
              },
              {
                coord: [75.5, 150]
              }
            ],
            [
              {
                name: value[2] + 'm',
                coord: [95.5, 155 - value[1]]
              },
              {
                coord: [95.5, 155]
              }
            ]
          ]
        }
      }
      // option.series[3] = {
      //   name: '警戒线',
      //   type: 'line',
      //   color: '#FFFF00',
      //   symbolSize: 0,
      //   smooth: true,
      //   showSymbol: true,
      //   lineStyle: {
      //     width: 2
      //   },
      //   z: 2,
      //   data: [
      //     [20, 0 + DIFF_DIS],
      //     [83.48, 17.21 + DIFF_DIS - alarmValue[2]],//待后台开发接口，并提供三级告警值12.5
      //     [115.48 + 12, 32.21 + 5.6 + DIFF_DIS - alarmValue[2]],//待后台开发接口，并提供三级告警值12.5
      //     [145.48, 47.21 + DIFF_DIS - alarmValue[2]],//待后台开发接口，并提供三级告警值12.5
      //     [409.48, 68 + DIFF_DIS]
      //   ]
      // }
      // if (thresholdData.length > 0) {
      //   option.series[1].data = [
      //     [20, 0],
      //     [83.48, thresholdData[1]],
      //     [145.48, thresholdData[2]],
      //     [334.48, thresholdData[3]],
      //     [409.48, 68 + 5],
      //   ]
      // }
      this.chart.setOption(option)
    }
  },
  mounted() {
    this.initChart()
  },
  watch: {
    data() {
      this.updateChart()
    }
  }
}
</script>
<style lang="less" scoped>
.wettingLineBox {
  position: relative;
  width: 100%;
  height: 100%;

  .chart {
    width: 100%;
    height: calc(100vh - 155px - 1.8rem);
    z-index: 1;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 35px;
    z-index: 0;
    width: calc(100% - 35px);
    height: calc(100% - 30px);
  }
}
</style>
