<template>
  <div class="loginContainer">
    <!-- 顶部logo和标题部分 -->
    <div class="zd-logo"></div>
    <div class="title">冬茅冲尾矿库在线监测平台</div>
    <div class="container">
      <!-- 左边背景图片 -->
      <div class="left-bg"></div>
      <!-- 右边登录框容器 -->
      <div class="loginForm-container">
        <div class="header">登录</div>
        <div class="bottomLine"></div>
        <form action="" class="loginForm" method="post">
          <div class="username">
            <span></span>
            <input type="text" v-model="loginForm.username" placeholder="请输入账号" id="username" autocomplete="off" ref="input" />
          </div>
          <div class="password">
            <span></span>
            <input type="password" v-model="loginForm.password" placeholder="请输入密码" id="password" />
          </div>
          <input type="button" value="登录" class="btn" @click="btnClick" />
        </form>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import crypto from 'crypto'
import { login } from '@/api'

export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    keyDown(e) {
      // 回车执行登录方法 enter键的ASSII是13
      if (e.keyCode === 13) {
        this.btnClick()
      }
    },
    btnClick() {
      const { username, password } = this.loginForm
      if (username === '' || password === '') {
        // alert('用户名和密码不能为空!')
        this.$message({
          duration: 2000,
          message: '用户名和密码不能为空!',
          type: 'error',
          center: true,
          showClose: true
        })
        this.loginForm.username = ''
        this.loginForm.password = ''
        return false
      }
      const md5 = crypto.createHash('md5')
      md5.update(password)
      const md5password = md5.digest('hex').toUpperCase()
      const params = {
        loginName: username,
        password: md5password,
        loginType: 1
      }
      login(params)
        .then(({ token }) => {
          this.$message({
            duration: 2000,
            message: '登录成功!',
            type: 'success',
            center: true,
            showClose: true
          })
          window.sessionStorage.setItem('username', username)
          window.sessionStorage.setItem('token', token)
          this.$router.push('/home')
        })
        .catch(e => {
          this.$message.error(e.message || '登录失败')
        })
    }
  },
  mounted() {
    this.$refs.input.focus()
    // 绑定监听事件
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener('keydown', this.keyDown, false)
  }
}
</script>
<style lang="less" scoped>
/* 整个登录包裹容器 */
.loginContainer {
  position: relative;
  width: 100%;
  height: 100%;
  // height: 1080px;
  background: url(../assets/bgLogin.png) no-repeat;
  background-size: 100% 100%;

  /* 登录页面的logo */
  .zd-logo {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 46px;
    left: 42px;
    background: url(../assets/logo.png) no-repeat;
    background-size: 100% 100%;
  }

  /* 登录页面的标题 */
  .title {
    position: absolute;
    height: 45px;
    left: 96px;
    top: 47px;
    line-height: 45px;
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
    opacity: 1;
  }
  .container {

    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    flex-flow:row;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    opacity: 1;

    /* 登录页面的左边背景图 */
    .left-bg {
      // position: absolute;
      width: 961px;
      height: 100%;
      // left: 1.69rem;
      top: 150px;
      // background-size: 9.61rem 8.14rem;
      background: url(../assets/left-bg.png) no-repeat;
      background-size: 100% 100%;
      // background-position: 200px 00px;
    }

    /* 登录页面的登录表格框 */
    .loginForm-container {
      // position: absolute;
      width: 458px;
      height: 394px;
      top: 343px;
      // right: 3.2rem;
      background: url(../assets/loginForm.png) no-repeat;
      background-size: 100% 100%;

      /* 登录框的头部名称 */
      .header {
        position: relative;
        width: 48px;
        height: 33px;
        left: 201px;
        top: 26px;
        font-size: 24px;
        font-weight: 500;
        line-height: 33px;
        color: #ffffff;
        opacity: 0.88;
      }

      /* 登录框中的横线 */
      .bottomLine {
        position: relative;
        left: 33px;
        top: 82px;
        width: 392px;
        height: 0px;
        border: 1px solid #b8c2ff;
        opacity: 0.47;
      }

      /* 登录表格 */
      .loginForm {
        position: relative;
        width: 100%;
        height: 100%;

        /* 用户名包裹框 */
        .username {
          position: absolute;
          width: 392px;
          height: 56px;
          top: 114px;
          left: 33px;
          border-width: 1px;
          border-style: solid;
          border-color: rgba(50, 65, 107, 0.5);
          opacity: 1;
          border-radius: 4px;
        }

        .username span {
          position: absolute;
          width: 24px;
          height: 24px;
          top: 16px;
          left: 17px;
          background: url(../assets/username.png) no-repeat;
          background-size: 100% 100%;
        }

        #username {
          position: absolute;
          width: 85%;
          height: 22px;
          line-height: 22px;
          left: 52px;
          top: 17px;
          background: transparent;
          border: none;
          font-size: 16px;
          color: #ccc;
          font-weight: 400;
          opacity: 1;
        }

        /* 密码包裹框 */
        .password {
          position: absolute;
          width: 392px;
          height: 56px;
          top: 202px;
          left: 33px;
          border-width: 1px;
          border-style: solid;
          border-color: rgba(50, 65, 107, 0.5);
          opacity: 1;
          border-radius: 4px;
        }

        .password span {
          position: absolute;
          width: 24px;
          height: 24px;
          top: 16px;
          left: 17px;
          background: url(../assets/password.png) no-repeat;
          background-size: 100% 100%;
        }

        #password {
          position: absolute;
          width: 85%;
          height: 22px;
          line-height: 22px;
          left: 52px;
          top: 17px;
          background: transparent;
          border: none;
          font-size: 16px;
          color: #ccc;
          font-weight: 400;
          opacity: 1;
        }

        /* 登录按钮 */
        .btn {
          position: absolute;
          width: 392px;
          height: 56px;
          top: 290px;
          left: 33px;
          font-size: 18px;
          font-weight: 400;
          line-height: 56px;
          color: #ffffff;
          opacity: 1;
          background: #3f51b5;
          border-radius: 4px;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
