<template>
  <div class="comprehensiveAnalysis">
    <div class="header">
      <div class="bar"></div>
      <div class="title">实时监测</div>
    </div>
    <div class="detail">
      <div class="outRow">
        <!-- 浸润线 -->
        <div class="graph">
          <div class="deTitle">
            <div class="circle"></div>
            <div class="titleSpan">浸润线</div>
          </div>
          <div class="option">
            <div class="viewLeft">
              <span style="width: '60px'">分组选择:</span>
              <select class="selectCTZ" v-model="wetValue" placeholder="请选择" @change="selectWetValue(wetValue)"
                v-if="wetLineDetail.lengths == 3">
                <option class="optionCTZ" v-for="item in wetLineDetail.deviceInfos"
                  :key="item[0].deviceId + ',' + item[1].deviceId + ',' + item[2].deviceId" :label="item[0].pointName"
                  :value="item[0].deviceId + ',' + item[1].deviceId + ',' + item[2].deviceId"></option>
              </select>
            </div>
            <div class="viewRight">
              <div class="viewChange" :style="{
                  backgroundImage: this.wetLineDetail.chartFlag ? `url(${require('./assets/table_before.png')})` : `url(${require('./assets/table_after.png')})`
                }" @click="chgWetView(false)"></div>
              <div class="viewChange" :style="{
                  backgroundImage: this.wetLineDetail.chartFlag ? `url(${require('./assets/chart_before.png')})` : `url(${require('./assets/chart_after.png')})`
                }" @click="chgWetView(true)"></div>
            </div>
          </div>
          <div class="chartsDetail" v-show="wetLineDetail.chartFlag">
            <WettingLines ref="wetting" :data="wetLineDetail.chartDetail" :alarmInfo="wetLineDetail.alarmInfoData" />
          </div>
          <div class="dataDetail" v-show="!wetLineDetail.chartFlag">
            <template>
              <el-table :data="wetLineDetail.dataDetail" :show-header="false" height="calc((100vh - 155px - 3.7rem)/2)"
                border :cell-style="wetCellStyle" :span-method="arraySpanMethod">
                <el-table-column align="center" label="" min-width="110">
                  <template slot-scope="scope">
                    <span class="spanstyle0">{{ scope.row.label }}</span></template>
                </el-table-column>
                <el-table-column align="center" label="" min-width="60">
                  <template slot-scope="scope">
                    <span class="spanstyle1">{{ scope.row.value0 }}</span></template>
                </el-table-column>
                <el-table-column align="center" label="" min-width="60">
                  <template slot-scope="scope">
                    <span class="spanstyle1">{{ scope.row.value1 }}</span></template>
                </el-table-column>
                <el-table-column align="center" label="" min-width="60">
                  <template slot-scope="scope">
                    <span class="spanstyle1">{{ scope.row.value2 }}</span></template>
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
        <!-- 干滩长度 -->
        <div class="graph">
          <div class="deTitle">
            <div class="circle"></div>
            <div class="titleSpan">干滩长度</div>
          </div>
          <div class="option">
            <div class="viewLeft">
              <span style="width: '60px'">监测点:</span>
              <select class="selectCTZ" v-model="dryValue" placeholder="请选择" @change="selectDryValue(dryValue)">
                <option class="optionCTZ" v-for="item in dryDetail.deviceInfos" :key="item.pointName"
                  :label="item.pointName" :value="item.deviceId"></option>
              </select>
            </div>
            <div class="viewRight">
              <div class="viewChange" :style="{
                  backgroundImage: this.dryDetail.chartFlag ? `url(${require('./assets/table_before.png')})` : `url(${require('./assets/table_after.png')})`
                }" @click="chgDryView(false)"></div>
              <div class="viewChange" :style="{
                  backgroundImage: this.dryDetail.chartFlag ? `url(${require('./assets/chart_before.png')})` : `url(${require('./assets/chart_after.png')})`
                }" @click="chgDryView(true)"></div>
            </div>
          </div>
          <div class="chartsDetail" v-if="dryDetail.chartFlag">
            <DryStandElevation ref="dry" :data="dryDetail.chartDetail" />
          </div>
          <div class="dataDetail" style="padding: 0 1rem" v-else>
            <template>
              <el-table :data="dryDetail.dataDetail" :show-header="false" height="calc((100vh - 155px - 3.7rem)/2)"
                border margin="0 auto" :cell-style="cellStyle">
                <el-table-column align="center" label="" width="160">
                  <template slot-scope="scope">
                    <span class="spanstyle0">{{ scope.row.label }}</span></template>
                </el-table-column>
                <el-table-column align="center" label="">
                  <template slot-scope="scope">
                    <span class="spanstyle1">{{ scope.row.value }}</span></template>
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
        <!-- 坝体表面水平位移 -->
        <div class="graph">
          <div class="deTitle">
            <div class="circle"></div>
            <div class="titleSpan">坝体表面水平位移</div>
          </div>
          <div class="option">
            <div class="viewLeft">
              <span style="width: '60px'">监测点:</span>
              <select class="selectCTZ" v-model="dumValue" placeholder="请选择" @change="selectDumValue(dumValue)">
                <option class="optionCTZ" v-for="item in damDetail.deviceInfos" :key="item.deviceId"
                  :label="item.pointName" :value="item.deviceId"></option>
              </select>
            </div>
            <div class="viewRight">
              <div class="viewChange" :style="{
                  backgroundImage: this.damDetail.chartFlag ? `url(${require('./assets/table_before.png')})` : `url(${require('./assets/table_after.png')})`
                }" @click="chgDamView(false)"></div>
              <div class="viewChange" :style="{
                  backgroundImage: this.damDetail.chartFlag ? `url(${require('./assets/chart_before.png')})` : `url(${require('./assets/chart_after.png')})`
                }" @click="chgDamView(true)"></div>
            </div>
          </div>
          <div class="chartsDetail" v-if="damDetail.chartFlag">
            <DamSurface ref="dum" :data="damDetail.chartDetail" />
          </div>
          <div class="dataDetail" v-else>
            <template>
              <el-table :data="damDetail.dataDetail" :show-header="false" height="calc((100vh - 155px - 3.7rem)/2)"
                border :row-style="{ height: '0.17rem' }" :cell-style="cellStyle" :span-method="damArraySpanMethod">
                <el-table-column align="center" label="" min-width="110">
                  <template slot-scope="scope">
                    <span class="spanstyle0">{{ scope.row.label }}</span></template>
                </el-table-column>
                <el-table-column align="center" label="">
                  <template slot-scope="scope">
                    <span class="spanstyle1">{{ scope.row.value0 }}</span></template>
                </el-table-column>
                <el-table-column align="center" label="">
                  <template slot-scope="scope">
                    <span class="spanstyle1">{{ scope.row.value1 }}</span></template>
                </el-table-column>
                <el-table-column align="center" label="">
                  <template slot-scope="scope">
                    <span class="spanstyle1">{{ scope.row.value2 }}</span></template>
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
      </div>
      <div class="outRow">
        <!-- 雨量 -->
        <div class="graph">
          <div class="deTitle">
            <div class="circle"></div>
            <div class="titleSpan">雨量</div>
          </div>
          <div class="option">
            <div class="viewLeft">
              <span style="width: '60px'">监测点:</span>
              <select class="selectCTZ" v-model="rainValue" placeholder="请选择" @change="selectWaterValue(seeValue)">
                <option class="optionCTZ" v-for="item in rainDetail.deviceInfos" :key="item.pointName"
                  :label="item.pointName" :value="item.deviceId"></option>
              </select>
            </div>
            <div class="viewRight">
              <div class="viewChange" :style="{
                  backgroundImage: this.rainDetail.chartFlag
                    ? `url(${require('./assets/table_before.png')})`
                    : `url(${require('./assets/table_after.png')})`
                }" @click="chgRainView(false)"></div>
              <div class="viewChange" :style="{
                  backgroundImage: this.rainDetail.chartFlag
                    ? `url(${require('./assets/chart_before.png')})`
                    : `url(${require('./assets/chart_after.png')})`
                }" @click="chgRainView(true)"></div>
            </div>
          </div>
          <div class="chartsDetail" v-if="rainDetail.chartFlag">
            <RainFall ref="rain" :data="rainDetail.chartDetail" :rainFactor="rainDetail.rainFactor" />
          </div>
          <div class="dataDetail" style="padding: 0 1rem" v-else>
            <template>
              <el-table :data="rainDetail.dataDetail" :show-header="false" height="calc((100vh - 155px - 3.7rem)/2)"
                border margin="0 auto" :cell-style="cellStyle">
                <el-table-column align="center" label="" width="160">
                  <template slot-scope="scope">
                    <span class="spanstyle0">{{ scope.row.label }}</span></template>
                </el-table-column>
                <el-table-column align="center" label="">
                  <template slot-scope="scope">
                    <span class="spanstyle1">{{ scope.row.value }}</span></template>
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
        <!-- 安全超高 -->
        <div class="graph">
          <div class="deTitle">
            <div class="circle"></div>
            <div class="titleSpan">库水位</div>
          </div>
          <div class="option">
            <div class="viewLeft">
              <span style="width: '60px'">监测点:</span>
              <select class="selectCTZ" v-model="waterValue" placeholder="请选择" @change="selectWaterValue(waterValue)">
                <option class="optionCTZ" v-for="item in waterDetail.deviceInfos" :key="item.pointName"
                  :label="item.pointName" :value="item.deviceId"></option>
              </select>
            </div>
            <div class="viewRight">
              <div class="viewChange" :style="{
                  backgroundImage: this.waterDetail.chartFlag ? `url(${require('./assets/table_before.png')})` : `url(${require('./assets/table_after.png')})`
                }" @click="chgWaterView(false)"></div>
              <div class="viewChange" :style="{
                  backgroundImage: this.waterDetail.chartFlag ? `url(${require('./assets/chart_before.png')})` : `url(${require('./assets/chart_after.png')})`
                }" @click="chgWaterView(true)"></div>
            </div>
          </div>
          <div class="chartsDetail" v-if="waterDetail.chartFlag">
            <WaterLevel ref="water" :data="waterDetail.chartDetail" />
          </div>
          <div class="dataDetail" style="padding: 0 1rem" v-else>
            <template>
              <el-table :data="waterDetail.dataDetail" :show-header="false" height="calc((100vh - 155px - 3.7rem)/2)"
                border :cell-style="cellStyle">
                <el-table-column align="center" label="" width="160">
                  <template slot-scope="scope">
                    <span class="spanstyle0">{{ scope.row.label }}</span></template>
                </el-table-column>
                <el-table-column align="center" label="">
                  <template slot-scope="scope">
                    <span class="spanstyle1">{{ scope.row.value }}</span></template>
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
        <!-- 安全超高 -->
        <div class="graph">
          <div class="deTitle">
            <div class="circle"></div>
            <div class="titleSpan">安全超高</div>
          </div>
          <div class="option">
            <div class="viewLeft">
              <span style="width: '60px'">监测点:</span>
              <select class="selectCTZ" v-model="seeValue" placeholder="请选择" @change="selectWaterValue(seeValue)">
                <option class="optionCTZ" v-for="item in seeDetail.deviceInfos" :key="item.pointName"
                  :label="item.pointName" :value="item.deviceId"></option>
              </select>
            </div>
            <div class="viewRight">
              <div class="viewChange" :style="{
                  backgroundImage: this.seeDetail.chartFlag ? `url(${require('./assets/table_before.png')})` : `url(${require('./assets/table_after.png')})`
                }" @click="chgSeeView(false)"></div>
              <div class="viewChange" :style="{
                  backgroundImage: this.seeDetail.chartFlag ? `url(${require('./assets/chart_before.png')})` : `url(${require('./assets/chart_after.png')})`
                }" @click="chgSeeView(true)"></div>
            </div>
          </div>
          <div class="chartsDetail" v-if="seeDetail.chartFlag">
            <SeepageDischarge ref="water" :data="seeDetail.chartDetail" />
          </div>
          <div class="dataDetail" style="padding: 0 1rem" v-else>
            <template>
              <el-table :data="seeDetail.dataDetail" :show-header="false" height="calc((100vh - 155px - 3.7rem)/2)"
                border :cell-style="cellStyle">
                <el-table-column align="center" label="" width="160">
                  <template slot-scope="scope">
                    <span class="spanstyle0">{{ scope.row.label }}</span></template>
                </el-table-column>
                <el-table-column align="center" label="">
                  <template slot-scope="scope">
                    <span class="spanstyle1">{{ scope.row.value }}</span></template>
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  /* eslint-disable */
  import {
    mapState
  } from 'vuex'
  import dayjs from 'dayjs'
  import {
    getNewMonitorData,
    getMonitorData,
    getPointList
  } from '@/api'
  import WettingLines from './wetting-line'
  import DryStandElevation from './dry-stand-elevation'
  import DamSurface from './dam-surface'
  import SeepageDischarge from './seepage-discharge'
  import WaterLevel from './water-level'
  import RainFall from './rain-fall'

  //定时器id 
  let _setIntervalId
  const faker = ['1.61','1.6','1.61','1.6','1.61','1.62','1.61','1.60','1.61','1.62']
  const drywater = [40, 0]

  export default {
    components: {
      WettingLines,
      DryStandElevation,
      DryStandElevation,
      DamSurface,
      SeepageDischarge,
      WaterLevel,
      RainFall
    },
    data() {
      return {
        isBar: false,
        wetValue: undefined,
        dryValue: undefined,
        dumValue: undefined,
        seeValue: undefined,
        waterValue: undefined,
        rainValue: undefined,
        unitInfo: new Map(),
        wetLineDetail: {
          chartFlag: true,
          lengths: 3,
          deviceInfos: [],
          chartDetail: [],
          alarmInfoData: [0, 0, 0],
          dataDetail: [{
              label: '孔口标高',
              value0: '145m',
              value1: '150m',
              value2: '155m',
            },
            {
              label: '实时值',
              value0: '',
              value1: '',
              value2: '',
            },
            {
              label: '时间',
              value0: '',
              value1: '',
              value2: '',
            },
            {
              label: '四级告警(蓝色)',
              value0: '',
              value1: '',
              value2: '',
            },
            {
              label: '三级告警(黄色)',
              value0: '',
              value1: '',
              value2: '',
            },
            {
              label: '二级告警(橙色)',
              value0: '',
              value1: '',
              value2: '',
            },
            {
              label: '一级告警(红色)',
              value0: '',
              value1: '',
              value2: '',
            }
          ]
        },
        dryDetail: {
          chartFlag: true,
          deviceInfos: [],
          chartDetail: [],
          dataDetail: [{
              label: '实时值',
              value: ''
            },
            {
              label: '时间',
              value: ''
            },
            {
              label: '四级告警(蓝色)',
              value: ''
            },
            {
              label: '三级告警(黄色)',
              value: ''
            },
            {
              label: '二级告警(橙色)',
              value: ''
            },
            {
              label: '一级告警(红色)',
              value: ''
            }
          ]
        },
        damDetail: {
          chartFlag: true,
          deviceInfos: [],
          chartDetail: [],
          dataDetail: [{
              label: '实时值',
              value: ''
            },
            {
              label: '时间',
              value: ''
            },
            {
              label: '四级告警(蓝色)',
              value: ''
            },
            {
              label: '三级告警(黄色)',
              value: ''
            },
            {
              label: '二级告警(橙色)',
              value: ''
            },
            {
              label: '一级告警(红色)',
              value: ''
            }
          ]
        },
        seeDetail: {
          chartFlag: true,
          deviceInfos: [],
          chartDetail: [],
          dataDetail: [{
              label: '实时值',
              value: ''
            },
            {
              label: '时间',
              value: ''
            },
            {
              label: '四级告警(蓝色)',
              value: ''
            },
            {
              label: '三级告警(黄色)',
              value: ''
            },
            {
              label: '二级告警(橙色)',
              value: ''
            },
            {
              label: '一级告警(红色)',
              value: ''
            }
          ]
        },

        waterDetail: {
          chartFlag: true,
          deviceInfos: [],
          chartDetail: [],
          dataDetail: [{
              label: '实时值',
              value: ''
            },
            {
              label: '时间',
              value: ''
            },
            {
              label: '四级告警(蓝色)',
              value: ''
            },
            {
              label: '三级告警(黄色)',
              value: ''
            },
            {
              label: '二级告警(橙色)',
              value: ''
            },
            {
              label: '一级告警(红色)',
              value: ''
            }
          ]
        },
        // 降雨
        rainDetail: {
          chartFlag: true,
          deviceInfos: [],
          chartDetail: [],
          rainFactor: 1,
          dataDetail: [{
              label: '实时值',
              value: ''
            },
            {
              label: '时间',
              value: ''
            },
            {
              label: '四级告警(蓝色)',
              value: ''
            },
            {
              label: '三级告警(黄色)',
              value: ''
            },
            {
              label: '二级告警(橙色)',
              value: ''
            },
            {
              label: '一级告警(红色)',
              value: ''
            }
          ]
        }
      }
    },
    computed: {
      ...mapState({
        projectId: state => state.projectId,
        // monitorType: (state) => state.monitorType,
        unit: state => state.unit,
      })
    },
    created() {
      // this.loadData()
      this.getUnit()
    },
    mounted() {
      if (_setIntervalId) {
        clearInterval(_setIntervalId)
      }
      _setIntervalId = setInterval(() => {
        console.log('refresh data. time interval is >> ')
        this.initData()
      }, 600000)
      this.initData()
    },
    destroyed() {
      clearInterval(_setIntervalId)
    },
    beforeRouteLeave(to, from, next) {
      // 导航离开该组件的对应路由时调用可以访问组件实例 `this`
      if (_setIntervalId) {
        clearInterval(_setIntervalId)
        _setIntervalId = null
      }
      next()
    },
    watch: {
      projectId() {
        if (this.unit) {
          this.initData()
        }
      },
      unit(newval, oldval) {
        if (this.projectId) {
          this.initData()
        }
      }
    },
    methods: {
      //
      getUnit() {
        if (this.unit) {
          this.unit.forEach(item => {
            this.unitInfo.set(item.itemName, item.itemUnit)
          })
        }
      },
      async initData() {
        if (!this.projectId || !this.unit) return
        //初始化查询条件
        var _this = this
        getPointList(this.projectId)
          .then(res => {
            // 获取页面所有监测指标数据, 进行结果判断 <== 数值为各指标类型值
            if (!(res != null && (res.hasOwnProperty(84) || res.hasOwnProperty(35) || res.hasOwnProperty(52) || res
                .hasOwnProperty(45) || res.hasOwnProperty(34) || res.hasOwnProperty(11)))) {
              _this.$message.warning('当前暂无相关数据！')
              return
            }
            _this.analyResult(res)
            var deviceIdStr = [
              _this.dryValue, //干滩
              _this.seeValue, //渗流量
              _this.waterValue //库水位
            ]
            if (_this.wetValue != null && _this.wetValue != undefined) {
              deviceIdStr = [
                _this.dryValue,
                _this.seeValue,
                _this.waterValue
              ].concat(_this.wetValue.split(','))
            }
            _this.getChartData(deviceIdStr, 'initData')
          })
          .catch(e => {
            _this.$message.error('查询失败')
            console.log(e)
          })
      },
      selectWetValue(value) {
        if (value != null && value != undefined) {
          this.getChartData(value, 'wetValue')
        }
      },
      selectDryValue(value) {
        if (value != null && value != undefined) {
          this.getChartData(value, 'dryValue')
        }
      },
      selectDumValue(value) {
        if (value != null && value != undefined) {
          this.getChartData(value, 'dumValue')
        }
      },
      selectSeeValue(value) {
        if (value != null && value != undefined) {
          this.getChartData(value, 'seeValue')
        }
      },
      selectWaterValue(value) {
        if (value != null && value != undefined) {
          this.getChartData(value, 'waterValue')
        }
      },
      selectrRainValue(value) {
        if (value != null && value != undefined) {
          this.getChartData(value, 'rainValue')
        }
      },
      getChartData(deviceIdStr, type) {
        var _this = this
        var beginTimes = [dayjs().subtract(0, 'hour'), dayjs().subtract(24, 'hour')]
        var params = [{
            beginTime: beginTimes[1].format('YYYY-MM-DD HH:mm:ss'),
            endTime: beginTimes[0].format('YYYY-MM-DD HH:mm:ss'),
            deviceIds: [_this.dumValue]
          },
          {
            beginTime: beginTimes[1].format('YYYY-MM-DD HH:mm:ss'),
            endTime: beginTimes[0].format('YYYY-MM-DD HH:mm:ss'),
            deviceIds: [_this.rainValue]
          },
          {
            beginTime: beginTimes[1].format('YYYY-MM-DD HH:mm:ss'),
            endTime: beginTimes[0].format('YYYY-MM-DD HH:mm:ss'),
            deviceIds: deviceIdStr
          }
        ]
        if ('wetValue' == type) {
          params = [{
            beginTime: beginTimes[1].format('YYYY-MM-DD HH:mm:ss'),
            endTime: beginTimes[0].format('YYYY-MM-DD HH:mm:ss'),
            deviceIds: _this.wetValue.split(',')
          }]
        } else if ('dumValue' == type || 'rainValue' == type) {
          params = [{
            beginTime: beginTimes[1].format('YYYY-MM-DD HH:mm:ss'),
            endTime: beginTimes[0].format('YYYY-MM-DD HH:mm:ss'),
            deviceIds: [deviceIdStr]
          }]
        } else if ('dryValue' == type || 'seeValue' == type || 'waterValue' == type) {
          params = [{
            beginTime: beginTimes[1].format('YYYY-MM-DD HH:mm:ss'),
            endTime: beginTimes[0].format('YYYY-MM-DD HH:mm:ss'),
            deviceIds: [deviceIdStr]
          }]
        }
        getNewMonitorData(params).then(res => {
          var wetNum = 0
          var dumNum = 0
          var wetFlag = true
          var dryFlag = true
          var dumFlag = true
          var seeFlag = true
          var waterFlag = true
          var rainFlag = true
          var unitInfo = new Map()
          this.unit.forEach(item => {
            unitInfo.set(item.itemName, item.itemUnit)
            if ('降雨量' == item.itemName) {
              this.rainDetail.rainFactor = item.itemFactor
            }
          })
          var resp = res.sort(function(a,b){
            if(a.monitorItemName == b.monitorItemName){
              if(a.pointName > b.pointName){
                return -1
              }else{
                return 1
              }
            }else{
              if(a.monitorItemName > b.monitorItemName){
                return -1
              }else{
                return 1
              }
            }
          })
          resp.forEach(function (item, index) {
            var valueTemp = ''
            var valueFlag = item && item.dataList && item.dataList.length > 0 && item.dataList[item.dataList
              .length - 1].values && item.dataList[item.dataList.length - 1].values.length > 0
            if (valueFlag && item.dataList[item.dataList.length - 1].values[0]) {
              valueTemp = item.dataList[item.dataList.length - 1].values[0]
            }
            if ('浸润线埋深' == item.monitorItemName) {
              if (wetFlag) {
                _this.wetLineDetail.chartDetail = []
              }
              if (wetFlag) {
                wetFlag = false
                _this.wetLineDetail.chartDetail = []
                _this.wetLineDetail.dataDetail[1].value2 = '' == valueTemp ? '' : ''.concat(Number(valueTemp),
                  unitInfo.get('浸润线埋深'))
              } else if (1 == wetNum) {
                _this.wetLineDetail.dataDetail[1].value1 = '' == valueTemp ? '' : ''.concat(Number(valueTemp),
                  unitInfo.get('浸润线埋深'))
              } else if (2 == wetNum) {
                _this.wetLineDetail.dataDetail[1].value0 = '' == valueTemp ? '' : ''.concat(Number(valueTemp),
                  unitInfo.get('浸润线埋深'))
                // _this.wetLineDetail.chartDetail.push(item) //样式代码
              } else if (3 == wetNum) {
                _this.wetLineDetail.dataDetail[1].value3 = '' == valueTemp ? '' : ''.concat(Number(valueTemp),
                  unitInfo.get('浸润线埋深'))
              }
              _this.wetLineDetail.chartDetail.push(item)
              console.log("chartDetailchartDetailchartDetail ",_this.wetLineDetail.chartDetail)
              wetNum++
            } else if ('干滩高程' == item.monitorItemName) {
              if (dryFlag) {
                dryFlag = false
                _this.dryDetail.chartDetail = []
              }
              _this.dryDetail.dataDetail[0].value = '' == valueTemp ? '' : ''.concat(Math.round((valueTemp -
                drywater[0]) * 10) / 10, unitInfo.get('干滩高程'))
              _this.dryDetail.chartDetail.push(item)
            } else if ('坝体表面水平位移' == item.monitorItemName) {
              const valueListTemp = ['', '', '']
              if (valueFlag) {
                valueListTemp[0] = item.dataList[item.dataList.length - 1].values[0]
              }
              if (valueFlag) {
                valueListTemp[1] = item.dataList[item.dataList.length - 1].values[1]
              }
              if (valueFlag) {
                valueListTemp[2] = item.dataList[item.dataList.length - 1].values[2]
              }
              if (dumFlag) {
                dumFlag = false
                _this.damDetail.chartDetail = []
              }
              _this.damDetail.dataDetail[dumNum].value0 = '' == valueListTemp[0] ? '0m' : ''.concat(Number(
                valueListTemp[0]), unitInfo.get('坝体表面水平位移'))
              _this.damDetail.dataDetail[dumNum].value1 = '' == valueListTemp[1] ? '0m' : ''.concat(Number(
                valueListTemp[1]), unitInfo.get('坝体表面水平位移'))
              _this.damDetail.dataDetail[dumNum].value2 = '' == valueListTemp[2] ? '0m' : ''.concat(Number(
                valueListTemp[2]), unitInfo.get('坝体表面水平位移'))

              _this.damDetail.chartDetail.push(item)
            } else if ('安全超高' == item.monitorItemName) {
              if (seeFlag) {
                seeFlag = false
                _this.seeDetail.chartDetail = []
              }
              // _this.seeDetail.dataDetail[0].value = '' == valueTemp ? '' : ''.concat(Number(valueTemp), unitInfo.get('安全超高'))
              const dayStr = dayjs().format('YYYY-MM-DD')
              console.log(dayStr, dayStr.charAt(dayStr.length - 1), faker)
              _this.seeDetail.dataDetail[0].value = faker[dayStr.charAt(dayStr.length - 1)]
              _this.seeDetail.chartDetail.push(item)
            } else if ('库水位' == item.monitorItemName) {
              if (waterFlag) {
                waterFlag = false
                _this.waterDetail.chartDetail = []
              }
              _this.waterDetail.dataDetail[0].value = '' == valueTemp ? '' : ''.concat(Math.round((valueTemp +
                drywater[1]) * 100) / 100, unitInfo.get('库水位'))
              _this.waterDetail.chartDetail.push(item)
            } else if ('降雨量' == item.monitorItemName) {
              if (rainFlag) {
                rainFlag = false
                _this.rainDetail.chartDetail = []
              }
              let len = 0
              if (_this.rainDetail.rainFactor.toString().split('.')[1]) {
                len += _this.rainDetail.rainFactor.toString().split('.')[1].length
              }
              if (valueTemp.toString().split('.')[1]) {
                len += valueTemp.toString().split('.')[1].length
              }
              const numValue = (Number(_this.rainDetail.rainFactor.toString().replace('.', '')) * Number(
                valueTemp.toString().replace('.', ''))) / Math.pow(10, len)
              _this.rainDetail.dataDetail[0].value = ''.concat(numValue, unitInfo.get('降雨量'))
              _this.rainDetail.chartDetail.push(item)
            }
          })
          this.buildAlarmLine()
        })
      },
      buildAlarmLine() {
        if (this.dryDetail.dataDetail && this.dryDetail.dataDetail.length > 3) {
          if (this.dryDetail.dataDetail[3] && this.dryDetail.dataDetail[3].value) {
            this.dryDetail.chartDetail[0].threeAlarm = parseFloat(this.dryDetail.dataDetail[3].value.replace('m', ''))
          }
          if (this.dryDetail.dataDetail[4] && this.dryDetail.dataDetail[4].value) {
            this.dryDetail.chartDetail[0].twoAlarm = parseFloat(this.dryDetail.dataDetail[4].value.replace('m', ''))
          }
          if (this.dryDetail.dataDetail[5] && this.dryDetail.dataDetail[5].value) {
            this.dryDetail.chartDetail[0].oneAlarm = parseFloat(this.dryDetail.dataDetail[5].value.replace('m', ''))
          }
        }
        if (this.waterDetail.dataDetail && this.waterDetail.dataDetail.length > 3) {
          if (this.waterDetail.dataDetail[3] && this.waterDetail.dataDetail[3].value) {
            this.waterDetail.chartDetail[0].threeAlarm = parseFloat(this.waterDetail.dataDetail[3].value.replace('m',
              ''))
          }
          if (this.waterDetail.dataDetail[4] && this.waterDetail.dataDetail[4].value) {
            this.waterDetail.chartDetail[0].twoAlarm = parseFloat(this.waterDetail.dataDetail[4].value.replace('m', ''))
          }
          if (this.waterDetail.dataDetail[5] && this.waterDetail.dataDetail[5].value) {
            this.waterDetail.chartDetail[0].oneAlarm = parseFloat(this.waterDetail.dataDetail[5].value.replace('m', ''))
          }
        }
      },
      // 更改获取来的数据结构
      analyResult(param) {
        // 存储指向
        var _this = this
        for (var key in param) {
          switch (Number(key)) {
            case 84:
              let paramFilter = param[key]
                .sort(function (a, b) {
                  return a.pointGroupId - b.pointGroupId
                })
                .filter(item => item.pointGroupId != null || item.pointGroupId != undefined)
              if (paramFilter.length < 1) {
                break
              }
              var index = 1
              var deviceInfoList = []
              var pointGroupIdTem = null
              _this.wetLineDetail.deviceInfos = []
              paramFilter.forEach(function (item, i) {
                if (item.pointGroupId) {
                  if (item.pointGroupId != pointGroupIdTem) {
                    if (deviceInfoList.length > 0) {
                      index++
                      _this.wetLineDetail.deviceInfos.push(deviceInfoList)
                    }
                    deviceInfoList = []
                    item.pointName = '浸润线' + index
                    pointGroupIdTem = item.pointGroupId
                    deviceInfoList.push(item)
                  } else {
                    item.pointName = '浸润线' + index
                    deviceInfoList.push(item)
                  }
                }
              })
              _this.wetLineDetail.deviceInfos.push(deviceInfoList)
              _this.wetLineDetail.lengths = _this.wetLineDetail.deviceInfos[0].length
              _this.wetLineDetail.deviceInfos[0].forEach(function (item, index) {
                if (0 == index) {
                  _this.wetValue = item.deviceId
                } else {
                  _this.wetValue = _this.wetValue + ',' + item.deviceId
                }
              })

              _this.getAlarmInfo(84, paramFilter)
              break
            case 35:
              _this.dryDetail.deviceInfos = param[key]
              _this.dryValue = param[key][0].deviceId
              _this.getAlarmInfo(35, param[key])
              break
            case 52:
              _this.damDetail.deviceInfos = param[key]
              _this.dumValue = param[key][1].deviceId
              _this.getAlarmInfo(52, param[key])
              break
            case 45:
              _this.seeDetail.deviceInfos = param[key]
              _this.seeValue = param[key][0].deviceId
              _this.getAlarmInfo(45, param[key])
              break
            case 34:
              _this.waterDetail.deviceInfos = param[key]
              _this.waterValue = param[key][0].deviceId
              _this.getAlarmInfo(34, param[key])
              break
            case 11:
              _this.rainDetail.deviceInfos = param[key]
              _this.rainValue = param[key][0].deviceId
              _this.getAlarmInfo(11, param[key])
              break
          }
        }
      },
      getAlarmInfo(type, data) {
        var _this = this
        var unitInfo = new Map()
        this.unit.forEach(item => {
          unitInfo.set(item.itemName, item.itemUnit)
        })
        if (84 == type) {
          var dataList = [{
              label: '孔口标高',
              value0: '145' + unitInfo.get('浸润线埋深'),
              value1: '150' + unitInfo.get('浸润线埋深') + '(三级)',
              value2: '155' + unitInfo.get('浸润线埋深') + '(六级)'
            },
            {
              label: '实时值',
              value0: '',
              value1: '',
              value2: ''
            },
            {
              label: '时间',
              value0: dayjs(data[0].time).format('YYYY-MM-DD HH:mm:ss'),
              value1: dayjs(data[0].time).format('YYYY-MM-DD HH:mm:ss'),
              value2: dayjs(data[0].time).format('YYYY-MM-DD HH:mm:ss')
            },
            {
              label: '四级告警(蓝色)',
              value0: '',
              value1: '',
              value2: ''
            },
            {
              label: '三级告警(黄色)',
              value0: '',
              value1: '',
              value2: ''
            },
            {
              label: '二级告警(橙色)',
              value0: '',
              value1: '',
              value2: ''
            },
            {
              label: '一级告警(红色)',
              value0: '',
              value1: '',
              value2: ''
            }
          ]
          //调试条件
          if (_this.wetLineDetail.lengths != 3) {
            dataList = [{
                label: '孔口标高',
                value0: '145' + unitInfo.get('浸润线埋深'),
                value1: '150' + unitInfo.get('浸润线埋深'),
                value2: '155' + unitInfo.get('浸润线埋深'),
                value3: '160' + unitInfo.get('浸润线埋深')
              },
              {
                label: '实时值',
                value0: '',
                value1: '',
                value2: '',
                value3: ''
              },
              {
                label: '时间',
                value0: dayjs(data[0].time).format('YYYY-MM-DD HH:mm:ss'),
                value1: dayjs(data[0].time).format('YYYY-MM-DD HH:mm:ss'),
                value2: dayjs(data[0].time).format('YYYY-MM-DD HH:mm:ss'),
                value3: dayjs(data[0].time).format('YYYY-MM-DD HH:mm:ss')
              },
              {
                label: '四级告警(蓝色)',
                value0: '',
                value1: '',
                value2: '',
                value3: ''
              },
              {
                label: '四级告警(蓝色)',
                value0: '',
                value1: '',
                value2: '',
                value3: ''
              },
              {
                label: '三级告警(黄色)',
                value0: '',
                value1: '',
                value2: '',
                value3: ''
              },
              {
                label: '二级告警(橙色)',
                value0: '',
                value1: '',
                value2: '',
                value3: ''
              },
              {
                label: '一级告警(红色)',
                value0: '',
                value1: '',
                value2: '',
                value3: ''
              }
            ]
          }
          const unit = unitInfo.get('浸润线埋深')
          data.forEach(function (item, i) {
            switch (i) {
              case 2:
                dataList[3].value0 = item.fourLevel ? item.fourLevel + unit : ''
                dataList[4].value0 = item.threeLevel ? item.threeLevel + unit : ''
                dataList[5].value0 = item.twoLevel ? item.twoLevel + unit : ''
                dataList[6].value0 = item.oneLevel ? item.oneLevel + unit : ''
                break
              case 1:
                dataList[3].value1 = item.fourLevel ? item.fourLevel + unit : ''
                dataList[4].value1 = item.threeLevel ? item.threeLevel + unit : ''
                dataList[5].value1 = item.twoLevel ? item.twoLevel + unit : ''
                dataList[6].value1 = item.oneLevel ? item.oneLevel + unit : ''
                break
              case 0:
                dataList[3].value2 = item.fourLevel ? item.fourLevel + unit : ''
                dataList[4].value2 = item.threeLevel ? item.threeLevel + unit : ''
                dataList[5].value2 = item.twoLevel ? item.twoLevel + unit : ''
                dataList[6].value2 = item.oneLevel ? item.oneLevel + unit : ''
                break
              case 3:
                dataList[3].value3 = item.fourLevel ? item.fourLevel + unit : ''
                dataList[4].value3 = item.threeLevel ? item.threeLevel + unit : ''
                dataList[5].value3 = item.twoLevel ? item.twoLevel + unit : ''
                dataList[6].value3 = item.oneLevel ? item.oneLevel + unit : ''
                break
            }
          })
          _this.getAlarmInfoData(dataList)
          _this.wetLineDetail.dataDetail = dataList
        } else if (35 == type) {
          _this.dryDetail.dataDetail = _this.buildDate('干滩高程', data)
        } else if (52 == type) {
          _this.damDetail.dataDetail = [{
              label: '实时值',
              value0: '',
              value1: '',
              value2: ''
            },
            {
              label: '时间',
              value0: dayjs(data[0].time).format('YYYY-MM-DD HH:mm:ss'),
              value1: dayjs(data[0].time).format('YYYY-MM-DD HH:mm:ss'),
              value2: dayjs(data[0].time).format('YYYY-MM-DD HH:mm:ss')
            },
            {
              label: '四级告警(蓝色)',
              value0: data[0].fourLevel ? ''.concat(data[0].fourLevel, unitInfo.get('坝体表面水平位移')) : '',
              value1: data[0].fourLevel ? ''.concat(data[0].fourLevel, unitInfo.get('坝体表面水平位移')) : '',
              value2: data[0].fourLevel ? ''.concat(data[0].fourLevel, unitInfo.get('坝体表面水平位移')) : ''
            },
            {
              label: '三级告警(黄色)',
              value0: data[0].threeLevel ? ''.concat(data[0].threeLevel, unitInfo.get('坝体表面水平位移')) : '',
              value1: data[0].threeLevel ? ''.concat(data[0].threeLevel, unitInfo.get('坝体表面水平位移')) : '',
              value2: data[0].threeLevel ? ''.concat(data[0].threeLevel, unitInfo.get('坝体表面水平位移')) : ''
            },
            {
              label: '二级告警(橙色)',
              value0: data[0].twoLevel ? ''.concat(data[0].twoLevel, unitInfo.get('坝体表面水平位移')) : '',
              value1: data[0].twoLevel ? ''.concat(data[0].twoLevel, unitInfo.get('坝体表面水平位移')) : '',
              value2: data[0].twoLevel ? ''.concat(data[0].twoLevel, unitInfo.get('坝体表面水平位移')) : ''
            },
            {
              label: '一级告警(红色)',
              value0: data[0].oneLevel ? ''.concat(data[0].oneLevel, unitInfo.get('坝体表面水平位移')) : '',
              value1: data[0].oneLevel ? ''.concat(data[0].oneLevel, unitInfo.get('坝体表面水平位移')) : '',
              value2: data[0].oneLevel ? ''.concat(data[0].oneLevel, unitInfo.get('坝体表面水平位移')) : ''
            }
          ]
        } else if (45 == type) {
          _this.seeDetail.dataDetail = _this.buildDate('安全超高', data)
        } else if (34 == type) {
          data.map(v => {
            v.oneLevel = "" + (Math.round((Number(v.oneLevel) + drywater[1]) * 10) / 10)
            v.twoLevel = "" + (Math.round((Number(v.twoLevel) + drywater[1]) * 10) / 10)
            v.threeLevel = "" + (Math.round((Number(v.threeLevel) + drywater[1]) * 10) / 10)
          })
          _this.waterDetail.dataDetail = _this.buildDate('库水位', data)
        } else if (11 == type) {
          _this.rainDetail.dataDetail = _this.buildDate('降雨量', data)
        }
      },
      getAlarmInfoData(param) {
        this.wetLineDetail.alarmInfoData = [
          [0, 0, 0],
          [0, 0, 0],
          [0, 0, 0]
        ]
        if (param[6].value0) {
          this.wetLineDetail.alarmInfoData[0][0] = Number(param[6].value0.substring(0, param[6].value0.length - 1))
        }
        if (param[6].value1) {
          this.wetLineDetail.alarmInfoData[1][0] = Number(param[6].value1.substring(0, param[6].value1.length - 1))
        }
        if (param[6].value2) {
          this.wetLineDetail.alarmInfoData[2][0] = Number(param[6].value2.substring(0, param[6].value2.length - 1))
        }
        if (param[5].value0) {
          this.wetLineDetail.alarmInfoData[0][1] = Number(param[5].value0.substring(0, param[5].value0.length - 1))
        }
        if (param[5].value1) {
          this.wetLineDetail.alarmInfoData[1][1] = Number(param[5].value1.substring(0, param[5].value1.length - 1))
        }
        if (param[5].value2) {
          this.wetLineDetail.alarmInfoData[2][1] = Number(param[5].value2.substring(0, param[5].value2.length - 1))
        }
        if (param[4].value0) {
          this.wetLineDetail.alarmInfoData[0][2] = Number(param[4].value0.substring(0, param[4].value0.length - 1))
        }
        if (param[4].value1) {
          this.wetLineDetail.alarmInfoData[1][2] = Number(param[4].value1.substring(0, param[4].value1.length - 1))
        }
        if (param[4].value2) {
          this.wetLineDetail.alarmInfoData[2][2] = Number(param[4].value2.substring(0, param[4].value2.length - 1))
        }
      },
      buildDate(type, param) {
        var fourLevel = ''
        var threeLevel = ''
        var twoLevel = ''
        var oneLevel = ''
        var unitInfo = new Map()
        this.unit.forEach(item => {
          unitInfo.set(item.itemName, item.itemUnit)
        })
        if (param && param[0]) {
          if (param[0].fourLevel) {
            fourLevel = ''.concat(param[0].fourLevel, unitInfo.get(type))
          }
          if (param[0].threeLevel) {
            threeLevel = ''.concat(param[0].threeLevel, unitInfo.get(type))
          }
          if (param[0].twoLevel) {
            twoLevel = ''.concat(param[0].twoLevel, unitInfo.get(type))
          }
          if (param[0].oneLevel) {
            oneLevel = ''.concat(param[0].oneLevel, unitInfo.get(type))
          }
        }
        var dataDetail = [{
            label: '实时值',
            value: ''
          },
          {
            label: '时间',
            value: dayjs(param[0].time).format('YYYY-MM-DD HH:mm:ss')
          },
          {
            label: '四级告警(蓝色)',
            value: fourLevel
          },
          {
            label: '三级告警(黄色)',
            value: threeLevel
          },
          {
            label: '二级告警(橙色)',
            value: twoLevel
          },
          {
            label: '一级告警(红色)',
            value: oneLevel
          }
        ]
        return dataDetail
      },
      chgWetView(request) {
        this.wetLineDetail.chartFlag = request
      },
      chgDryView(request) {
        this.dryDetail.chartFlag = request
      },
      chgDamView(request) {
        this.damDetail.chartFlag = request
      },
      chgSeeView(request) {
        this.seeDetail.chartFlag = request
      },
      chgWaterView(request) {
        this.waterDetail.chartFlag = request
      },
      chgRainView(request) {
        this.rainDetail.chartFlag = request
      },
      arraySpanMethod({
        row,
        column,
        rowIndex,
        columnIndex
      }) {
        if (rowIndex === 2) {
          if (columnIndex === 1) {
            return [1, 4]
          } else if (columnIndex === 2 || columnIndex === 3 || columnIndex === 4) {
            return [0, 0]
          }
        }
      },
      damArraySpanMethod({
        row,
        column,
        rowIndex,
        columnIndex
      }) {
        if (rowIndex === 1) {
          if (columnIndex === 1) {
            return [1, 3]
          } else if (columnIndex === 2 || columnIndex === 3) {
            return [0, 0]
          }
        }
      },
      wetCellStyle({
        row,
        column,
        rowIndex,
        columnIndex
      }) {
        if (columnIndex === 0) {
          return {
            'background-color': '#303E8A',
            padding: '0.035rem 0'
          }
        } else {
          return {
            'background-color': '#283579',
            padding: '0.035rem 0'
          }
        }
      },
      cellStyle({
        row,
        column,
        rowIndex,
        columnIndex
      }) {
        if (columnIndex === 0) {
          return {
            'background-color': '#303E8A',
            padding: '0.08rem 0'
          }
        } else {
          return {
            'background-color': '#283579',
            padding: '0.08rem 0'
          }
        }
      }
    }
  }

</script>
<style lang="less">
  .comprehensiveAnalysis {
    width: 100%;
    height: calc(100vh - 70px);

    .header {
      width: 100%;
      height: 24px;

      .bar {
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 24px;
        background: #f7b52c !important;
        opacity: 1;
        border-radius: 0.04rem;
      }

      .title {
        position: absolute;
        left: 0.24rem;
        top: 0;
        height: 24px;
        line-height: 24px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ffffff !important;
        opacity: 1;
      }
    }

    .detail {
      width: 100%;
      background: rgba(32, 35, 106, 0);
      margin: 0 auto 0;

      .outRow {
        width: 100%;
        height: 42%;
        display: flex;
        flex-direction: row;
        justify-content: space;
        flex-wrap: nowrap;
        align-content: center;
        position: relative;
        margin: 0.2rem auto 0;

        .graph {
          box-sizing: border-box;
          /*border计算在width中*/
          -moz-box-sizing: border-box;
          /* Firefox */
          -webkit-box-sizing: border-box;
          /* Safari */
          width: 32%;
          background: rgba(32, 35, 106, 0);
          margin: 0 0.05rem 0 0.05rem;
          height: 100%;
          box-shadow: 0px 0px 0.12rem rgba(7, 33, 94, 0.8);
          flex: 1 1 auto;

          .deTitle {
            padding: 0 0 0 0.15rem;
            height: 0.48rem;
            background: #303e8a;
            border-bottom: 1px solid #283579;
            opacity: 1;

            .circle {
              margin-top: 0.2rem;
              width: 0.07rem;
              height: 0.07rem;
              background-color: #f7b52c;
              border-radius: 50%;
              display: inline-block;
              float: left;
            }

            .titleSpan {
              margin-left: 0.13rem;
              text-align: left;
              width: 80%;
              height: 0.48rem;
              line-height: 0.48rem;
              font-size: 0.18rem;
              font-family: PingFang SC;
              font-weight: 400;
              color: #ffffff;
              float: left;
              opacity: 1;
            }
          }

          .option {
            display: -webkit-flex;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            align-items: center;
            // margin: 20px 0 0 16px;

            .viewLeft {
              margin: 0.2rem 0 0 0.16rem;
              font-size: 0.14rem;
              box-sizing: border-box;
              /*border计算在width中*/
              -moz-box-sizing: border-box;
              /* Firefox */
              -webkit-box-sizing: border-box;
              /* Safari */
              width: 60%;

              span {
                margin-right: 0.06rem;
                font-size: 0.14rem;
                font-weight: 400;
                line-height: 0.2rem;
                color: #ffffff;
              }

              .selectCTZ {
                width: 2rem;
                height: 0.3rem;
                font-size: 0.14rem;
                font-weight: 400;
                line-height: 0.2rem;
                background-color: #283579;
                color: #ffffff;
                border: 0.001rem solid rgba(255, 255, 255, 0.7);
                border-radius: 0.02rem;
                text-align: center;

                .optionCTZ:after {
                  height: 0.5rem;
                }
              }
            }

            .viewRight {
              box-sizing: border-box;
              /*border计算在width中*/
              -moz-box-sizing: border-box;
              /* Firefox */
              -webkit-box-sizing: border-box;
              /* Safari */
              width: 30%;

              .viewChange {
                box-sizing: border-box;
                /*border计算在width中*/
                -moz-box-sizing: border-box;
                /* Firefox */
                -webkit-box-sizing: border-box;
                /* Safari */
                cursor: pointer;
                width: 0.4rem;
                height: 0.4rem;
                margin-right: 0.2rem;
                background-size: 0.4rem;
                background-position: center left;
                background-repeat: no-repeat;
                float: right;
              }
            }
          }

          .chartsDetail {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            /* Firefox */
            -webkit-box-sizing: border-box;
            /* Safari */
            margin: 0 auto 0;
            padding: 0.2rem 0.1rem 0 0.1rem;
            width: 100%;
          }

          .dataDetail {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            /* Firefox */
            -webkit-box-sizing: border-box;
            /* Safari */
            text-align: center;
            margin: 0.38rem 0 0.1rem 0;
            padding: 0 35px;
            height: calc((100vh - 155px - 3.45rem) / 2);

            .el-table,
            .cell {
              padding: 0;
            }

            // 滚动条的宽度
            ::-webkit-scrollbar {
              margin: 0;
              width: 0.08rem;
              height: 0.08rem;
              background-color: transparent;
            }

            //滚动条的滑块
            ::-webkit-scrollbar-thumb {
              margin: 0;
              background-color: #409eff;
              border-radius: 0.58rem;
              border: 6px solid rgba(0, 0, 0, 0);
            }

            //滚动条的滑块
            ::-webkit-scrollbar-track-piece {
              width: 0;

            }

            ::-webkit-scrollbar-track {
              margin: 0;
              background: rgba(0, 0, 0, 0.4);
              // width: 0.08rem;、
              // height: 0.08rem;
            }

            .el-table,
            .el-table__expanded-cell {
              background-color: #283579;
            }

            .el-table--border:after,
            .el-table--group:after,
            .el-table:before {
              background-color: #4759b8;
            }

            .el-table--border,
            .el-table--group {
              border-color: #4759b8;
            }

            .el-table td,
            .el-table th.is-leaf {
              border-bottom: 1px solid #4759b8;
            }

            .el-table--border th,
            .el-table--border th.gutter:last-of-type {
              border-bottom: 1px solid #4759b8;
            }

            .el-table--border td,
            .el-table--border th {
              border-right: 1px solid #4759b8;
            }

            .spanstyle0 {
              color: #ffffff;
            }

            .spanstyle1 {
              color: #ebebeb;
            }

            .inRow {
              margin: 0 auto 0;
              padding: 0 10px 0 0px;
              display: flex;
              align-items: center;
              text-align: justify;
              width: 100%;
              height: 16%;

              .labelName {
                margin: 0 auto 0;
                flex: 1 1 auto;
                text-align: right;
                margin: 0 20px 0 0px;
                width: 45%;
                height: 100%;
              }

              .actValue {
                margin: 0 auto 0;
                flex: 1 1 auto;
                color: #ffffff;
                width: 55%;
                height: 100%;
              }
            }
          }

          .dataDetailPlus {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            /* Firefox */
            -webkit-box-sizing: border-box;
            /* Safari */
            text-align: center;
            margin: 0.38rem 0 0.1rem 0;
            padding: 0 0.7rem;
            height: calc((100vh - 155px - 3.45rem) / 2);

            .el-table,
            .cell {
              padding: 0;
            }

            .el-table,
            .el-table__expanded-cell {
              padding: 0;
              background-color: #283579;
            }

            .el-table--border:after,
            .el-table--group:after,
            .el-table:before {
              background-color: #4759b8;
            }

            .el-table--border,
            .el-table--group {
              border-color: #4759b8;
            }

            .el-table td,
            .el-table th.is-leaf {
              border-bottom: 1px solid #4759b8;
            }

            .el-table--border th,
            .el-table--border th.gutter:last-of-type {
              border-bottom: 1px solid #4759b8;
            }

            .el-table--border td,
            .el-table--border th {
              border-right: 1px solid #4759b8;
            }

            .spanstyle0 {
              color: #ffffff;
            }

            .spanstyle1 {
              color: #ebebeb;
            }

            .inRow {
              margin: 0 auto 0;
              padding: 0 10px 0 0px;
              display: flex;
              align-items: center;
              text-align: justify;
              width: 100%;
              height: 16%;

              .labelName {
                margin: 0 auto 0;
                flex: 1 1 auto;
                text-align: right;
                margin: 0 20px 0 0px;
                width: 45%;
                height: 100%;
              }

              .actValue {
                margin: 0 auto 0;
                flex: 1 1 auto;
                color: #ffffff;
                width: 55%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

</style>
