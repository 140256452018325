<template>
  <div class="chart3d" ref="chart"></div>
</template>
<script>
/* eslint-disable */
import echarts from 'echarts'
import 'echarts-gl'

export default {
  name: 'Chart3D',
  props: {
    data: Array,
  },
  watch: {
    data() {
      this.updateChart()
    },
  },
  mounted() {
    this.initChart()
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.chart)
      this.chart.setOption({
        title: [
          {
            text: '内部位移3D',
            left: '25%',
            top: '5%',
            textAlign: 'center',
          },
          {
            text: '内部位移X方向',
            left: '73%',
            top: '1%',
            textAlign: 'center',
          },
          {
            text: '内部位移Y方向',
            left: '73%',
            top: '57%',
            textAlign: 'center',
          },
        ],
        tooltip: {},
        grid3D: {
          width: '50%',
          viewControl: {
            projection: 'orthographic',
          },
        },
        xAxis3D: {
          type: 'value',
          name: 'X方向偏移值/mm',
          nameTextStyle: {
            padding: 5,
          },
        },
        yAxis3D: {
          type: 'value',
          name: 'Y方向偏移值/mm',
          nameTextStyle: {
            padding: 5,
          },
        },
        zAxis3D: {
          type: 'value',
          name: '高程/m',
          nameTextStyle: {
            padding: 5,
          },
        },
        grid: [
          { left: '55%', width: '40%', bottom: '57%' },
          { left: '55%', width: '40%', top: '67%', bottom: '1%' },
        ],
        xAxis: [
          { type: 'value', gridIndex: 0, name: 'X轴' },
          { type: 'value', gridIndex: 1, name: 'Y轴' },
        ],
        yAxis: [
          { type: 'value', gridIndex: 0, name: '高程/m' },
          { type: 'value', gridIndex: 1, name: '高程/m' },
        ],
        dataset: {
          dimensions: ['X轴', 'Y轴', '高程'],
          source: this.data,
        },
        series: [
          {
            type: 'line3D',
            silent: true,
            lineStyle: {
              width: 2,
            },
          },
          {
            type: 'scatter3D',
            name: '内部位移',
            symbolSize: 6,
            lineStyle: {
              width: 1,
            },
          },
          {
            type: 'line',
            name: 'X轴',
            xAxisIndex: 0,
            yAxisIndex: 0,
            encode: {
              x: 0,
              y: 2,
              tooltip: [0, 1, 2],
            },
          },
          {
            type: 'line',
            name: 'Y轴',
            xAxisIndex: 1,
            yAxisIndex: 1,
            encode: {
              x: 1,
              y: 2,
              tooltip: [0, 1, 2],
            },
          },
        ],
      })
    },
    updateChart() {
      if (!this.chart) this.initChart()
      const data3d = []
      this.data.forEach(({ dataList, depth }) => {
        const lastData = dataList[dataList.length - 1]
        data3d.push([lastData.values[0], lastData.values[1], depth])
      })
      this.chart.setOption({
        dataset: {
          source: data3d,
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
.chart3d {
  height: 600px;
}
</style>
