import axios from 'axios'

const GaodeApi = axios.create({
  baseURL: 'https://restapi.amap.com/v3/weather/weatherInfo'
})

const GeodeApiKey = '2bfd7debe83d1f13adf856fb9820190c'

export async function getWeather(city) {
  return GaodeApi.get('', {
    params: {
      city,
      key: GeodeApiKey
    }
  }).then(({ data }) => {
    const { status } = data
    if (status !== '1') return null
    const { lives } = data
    return lives[0]
  }).catch(e => {
    return null
  })
}
