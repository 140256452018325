<template>
  <div class="videoMonitor">
    <div class="header">
      <div class="bar"></div>
      <div class="title">视频监测</div>
    </div>
    <div class="content">
      <!-- <div class="outRow" v-for="(item, index) in firstVideoInfo" :key="index"> -->
      <div class="outRow">
        <div class="graph" >
          <player :src="firstVideoInfo[0]" />
        </div>
        <div class="graph">
          <player :src="firstVideoInfo[1]" />
        </div>
        <div class="graph">
          <player :src="firstVideoInfo[2]" />
        </div>
      </div>
    <!-- <div v-if="videoRequest.length > 3"> -->
      <!-- <div class="outRow" v-for="(item, index) in secVideoInfo" :key="index"> -->
      <div class="outRow" v-if="videoRequest.length > 3">
        <div class="graph">
          <player :src="secVideoInfo[0]" />
        </div>
        <div class="graph">
          <player :src="secVideoInfo[1]" />
        </div>
        <div class="graph">
          <player :src="secVideoInfo[2]" />
        </div>
      </div>
    <!-- </div> -->
    </div>
    <el-drawer >
    </el-drawer>
  </div>
</template>
<script>
/* eslint-disable */
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import player from "@/components/player";
import { getMonitorPoints, modifyAlarmInfo } from '@/api'
import axios from "axios";
export default {
  components: {
    player,
  },
  data() {
    return {
      flv: '',
      videoUrl:'http://120.52.31.17:18080/api/play/start',
      videoRequest:[{deviceId:'43010120002000000000',channelId:'43010120001320000001'},{deviceId:'43010120002000000000',channelId:'43010120001320000003'},{deviceId:'43010120002000000000',channelId:'43010120001320000004'},{deviceId:'43010120002000000000',channelId:'43010120001320000005'},{deviceId:'43010120002000000000',channelId:'43010120001320000006'},{deviceId:'43010120002000000000',channelId:'43010120001320000007'}],
      firstVideoInfo:[],
      secVideoInfo:[],
    }
  },
  computed: {
    ...mapState({
      projectId: state => state.projectId,
      monitorType: state => state.monitorType
    })
  },
  created(){
    this.getVideoInfo();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    getVideoInfo(){
      this.firstVideoInfo = []
      this.secVideoInfo = []
      // axios.get(`${env.VIDEO_BASEURL}/${deviceId}/${channelId}`).then((res)=>{
      this.videoRequest.forEach((item,index)=>{
        axios.get(`${this.videoUrl}/${item.deviceId}/${item.channelId}`).then((res)=>{
            if (res.data.code === 0) {
              if(this.videoRequest.length < 4){
                this.firstVideoInfo.push(res.data.data.flv)
              }else if(this.videoRequest.length == 4){
                if(index<2){
                    this.firstVideoInfo.push(res.data.data.flv)
                }else{
                    this.secVideoInfo.push(res.data.data.flv)
                }
              }else if(this.videoRequest.length >4 ){
                if(index<3){
                    this.firstVideoInfo.push(res.data.data.flv)
                }else{
                    this.secVideoInfo.push(res.data.data.flv)
                }
              }
            this.$forceUpdate();
            }
        })
      })
    }
  },
  watch: {
  }
}
</script>
<style lang="less" scoped>
.videoMonitor {
  width: 100%;
  height: 100%;

  .header {
    position: relative;
    margin-top: 0.24rem;
    width: 100%;
    height: 28px;

    .bar {
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 28px;
      background: #3f51b5;
      opacity: 1;
      border-radius: 4px;
    }

    .title {
      position: absolute;
      left: 24px;
      top: 0;
      height: 28px;
      line-height: 28px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      opacity: 1;
    }
  }
  .content {
    width: 95%;
    height: calc(100vh - 170px);
    margin: 20px auto 0;
    // border:1px solid red;

    .outRow {
    // border:2px solid black;
      width: 100%;
      height: 47%;
      display: flex;
      flex-direction: row;
      justify-content: space;
      flex-wrap: nowrap;
      align-content: center;
      position: relative;
      margin: 0.2rem auto 0;

      .graph {
    // border:1px solid red;
        box-sizing: border-box;
        /*border计算在width中*/
        -moz-box-sizing: border-box;
        /* Firefox */
        -webkit-box-sizing: border-box;
        /* Safari */
        width: 32%;
        background: rgba(32, 35, 106, 0);
        margin: 0 0.05rem 0 0.05rem;
        height: 100%;
        box-shadow: 0px 0px 0.12rem #edf0f5;
        flex: 1 1 auto;
      }
    }

  }
}
</style>
