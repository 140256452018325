<template>
  <div class="wettingLineBox">
    <div class="chart" ref="chart"></div>
  </div>
</template>
<script>
/* eslint-disable */
import echarts from 'echarts'
const DIFF_DIS = 0
const DIFF_VALUE = v => v
let option = {
  color: ['#A4B1FF', '#0D9AFF', '#FFFF00'],
  legend: {
    data: ['水位', '警戒线'],
    textStyle: {
      color: '#FFFFFF'
    }
  },
  animation: false,
  tooltip: {
    show: true,
    trigger: 'axis',
    confine: true,
    backgroundColor: 'rgba(0,0,0,0.3)',
    formatter: '孔口坐标: ({c0})'
  },
  grid: {
    top: '30',
    bottom: '10',
    left: '10',
    right: '20',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    boundaryGap: false,
    min: 0,
    max: 136,
    // interval: 20,
    axisPointer: {
      show: true,
      lineStyle: {
        type: 'dotted'
      },
      label: {
        show: false
      }
    },
    axisLine: {
      onZero: false,
      lineStyle: {
        color: '#4759B8',
        opacity: 1
      },
      symbol: ['none', 'none']
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#000000',
        opacity: 0.25
      }
    },
    axisLabel: {
      color: '#C9C9C9',
      fontSize: '12',
      formatter: '{value} m'
    },
    splitLine: {
      show: false
    }
  },
  yAxis: {
    type: 'value',
    scale: true,
    nameTextStyle: {
      color: '#999'
    },
    axisPointer: {
      show: true,
      lineStyle: {
        type: 'dotted'
      },
      label: {
        show: false
      },
      triggerTooltip: false
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dotted',
        width: '1.5',
        color: '#4759B8',
        opacity: '1'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: '#C9C9C9',
      fontSize: '12',
      showMaxLabel: true,
      formatter: '{value} m'
    },
    axisLine: {
      onZero: false,
      lineStyle: {
        color: '#4759B8',
        opacity: 1
      },
      symbol: ['none', 'none']
    }
  },
  series: [
    {
      name: '孔口标高',
      type: 'line',
      color: '#C0C4CC',
      symbolSize: 6,
      smooth: false,
      showSymbol: true,
      tooltip: {
        show: true
      },
      lineStyle: {
        width: 2
      },
      areaStyle: {
        color: 'rgba(112, 112, 112, 0.3)',
        opacity: 0.71
      },
      z: 2,
      data: [
        [0,125],
        [1.5,125],
        [31.5,140],
        [35.5,140],
        [55.5,145],
        [75.5,150],
        [95.5,155],
        [115.5,160],
        [135.5,165]
      ].map(item => [item[0], item[1]])
    },
    {
      name: '水位',
      type: 'line',
      color: '#0D9AFF',
      smooth: true,
      showSymbol: true,
      lineStyle: {
        width: 2
      },
      z: 2,
      data: []
    },
    {
      name: '警戒线',
      type: 'line',
      color: '#FFFF00',
      smooth: true,
      showSymbol: true,
      lineStyle: {
        width: 2
      },
      tooltip: {
        show: false
      },
      z: 2
    }
  ]
}
export default {
  props: {
    data: Array,
    alarmInfo: Array
  },
  data() {
    return {
      chart: null
    }
  },
  methods: {
    initChart() {
      const chart = echarts.init(this.$refs.chart)
      chart.setOption(option)
      this.chart = chart
    },
    updateChart() {
      const value3 = this.alarmInfo
      if (!this.chart) this.initChart()
      let value = [0]
      let thresholdData = []
      if (
        !this.data ||
        this.data.length < 1 ||
        !this.data[0].dataList[0] ||
        this.data[0].dataList[0].length < 1
      ) {
        return
      }
      this.chart.hideLoading();
      this.data.forEach((item, index) => {
        value[index + 1] = DIFF_VALUE(item.dataList[item.dataList.length - 1]?.values[0])
        if (item.thresholdData && item.thresholdData.length > 0) {
          thresholdData[index] = item.thresholdData
        }
      })
      console.log('valuevalue',value)
      let option = this.chart.getOption()
      option.series[2] = {
        name: '水位',
        type: 'line',
        color: '#0D9AFF',
        symbolSize: 6,
        smooth: true,
        showSymbol: true,
        lineStyle: {
          width: 2
        },
        z: 2,
        data: [
          [1.5,125],
          [55.5,145 - value[3]],
          [75.5,150 - value[2]],
          [95.5,155 - value[1]],
          [160,155]
        ],
        markLine: {
          silent: true,
          symbol: ['none', 'none'],
          lineStyle: {
            normal: {
              type: 'solid',
              color: '#FFFFFF'
            }
          },
          label: {
            normal: {
              show: true,
              position: 'middle'
            }
          },
          data: [
            [
              {
                name: value[3] + 'm',
                coord: [55.5, 145 - value[3]]
              },
              {
                coord: [55.5, 145]
              }
            ],
            [
              {
                name: value[2] + 'm',
                coord: [75.5, 150 - value[2]]
              },
              {
                coord: [75.5, 150]
              }
            ],
            [
              {
                name: value[1] + 'm',
                coord: [95.5, 155 - value[1]]
              },
              {
                coord: [95.5, 155]
              }
            ]
          ]
        }
      }
      option.series[3] = {
        name: '警戒线',
        type: 'line',
        color: '#FFFF00',
        symbolSize: 0,
        smooth: true,
        showSymbol: true,
        lineStyle: {
          width: 2
        },
        z: 2,
        data: [
          [5,125 + DIFF_DIS + 0.8],
          [55.5,145 + DIFF_DIS - value3[0][2]],
          [75.5,150 + DIFF_DIS - value3[1][2]],
          [95.5,155 + DIFF_DIS - value3[2][2]],
          [160,155 + DIFF_DIS + 2]
        ],
      }
      this.chart.setOption(option)
    }
  },
  mounted() {
    this.initChart()
  },
  watch: {
    data(oldVal,newVal) {
      this.updateChart()
    },
    alarmInfo(oldVal,newVal) {
    }
  }
}
</script>
<style lang="less" scoped>
.wettingLineBox {//height: calc((100vh - 155px - 2.9rem)
  width: 100%;
  height: calc((100vh - 155px - 2.9rem) / 2);
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  align-items:center;
  flex: 1;
  opacity: 1;

  .chart {
    width: 95%;
    height: 100%;
    z-index: 1;
  }
}
</style>
